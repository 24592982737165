import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { ReactAriaCheckbox } from '@jotta/ui/ReactAriaCheckbox'
import { RadixModal } from '@jotta/ui/RadixModal'
import { t, Trans } from '@lingui/macro'
import type { ExtendedBusinessUser } from '../BizApi'
import { bizApi } from '../BizApi'
import { useBizContext } from '../BizLayout'
import { DialogTrigger } from '@radix-ui/react-dialog'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import { useState } from 'react'

export function BizActionButtonDeleteUser({
  user,
}: {
  user: Pick<ExtendedBusinessUser, 'username' | 'name' | 'isCurrentUser'>
}) {
  const { username, isCurrentUser, name } = user
  const { company } = useBizContext()
  const [consent, setConsent] = useState(false)
  const [open, setOpen] = useState(false)
  const companyName = company.name
  const onClose = (open: boolean): void => {
    if (!open) {
      setConsent(false)
    }
    setOpen(open)
  }
  const mutation = bizApi.user.remove.useMutation({
    onSettled: () => {
      onClose(false)
    },
  })
  const title = t({
    id: 'Remove {user} from {company}?',
    values: { user: name, company: companyName },
  })
  return (
    <RadixModal
      open={open}
      onClose={onClose}
      triggerElement={
        <DialogTrigger
          disabled={isCurrentUser}
          title={title}
          className="btn btn-outline px-2"
          name="username"
          value={username}
        >
          <PlainBrandIcon icon="SvgTrash" />
        </DialogTrigger>
      }
    >
      <RadixDialog
        title={title}
        onClose={onClose}
        buttons={[
          {
            action: 'close',
            label: t`Cancel`,
          },
          {
            label: t`Delete`,
            disabled: !consent,
            action: () => mutation.mutate({ username }),
            variant: 'buttons.error',
          },
        ]}
      >
        {mutation.isPending && (
          <div className="loading loading-sm loading-absolute" />
        )}
        <div className="prose prose-small">
          <p>
            {t`This will delete the ${name} user account from ${companyName}.`}
          </p>
          <p>
            <Trans>
              All files associated with this account will be deleted
            </Trans>
          </p>
          <ReactAriaCheckbox isSelected={consent} onChange={setConsent}>
            <Trans>
              I understand this operation is permanent and cannot be undone
            </Trans>
          </ReactAriaCheckbox>
        </div>
      </RadixDialog>
    </RadixModal>
  )
}
