import { type Business } from '@jotta/grpc-connect-openapi/customer'
import type { Customer } from '@jotta/grpc-connect-openapi/esm/openapi/customer/customer.v2_pb'
import type { GetPaymentInfoResponse } from '@jotta/grpc-connect-openapi/payment'
import { useRouteBreadCrumb } from '@jotta/hooks/useRouteBreadCrumb'
import { Outlet, useOutletContext } from 'react-router-dom'
import { bizApi } from './BizApi'
import { BizBoundary } from './components/BizBoundary'
import { BizUserLimitAlert } from './components/BizUserLimitAlert'
import { useCurrentLanguage } from '@jotta/i18n'

type BizContextType = {
  customer: Customer
  company: Business
  subscription: GetPaymentInfoResponse
  username: string
}

export function useBizContext() {
  return useOutletContext<BizContextType>()
}
export function BizLayout() {
  const crumbs = useRouteBreadCrumb()
  const {
    data: { customer, company, username },
  } = bizApi.company.get.useSuspenseQuery()
  const { data: subscription } =
    bizApi.subscription.getPaymentInfo.useSuspenseQuery()

  return (
    <div className="px-6 py-4 pb-28">
      <h1 className="title-sm-b">{company.name}</h1>
      <ul className="breadcrumb">
        {crumbs.map((crumb, index) => (
          <li key={index}>{crumb}</li>
        ))}
      </ul>
      <div className="relative flex flex-col gap-3">
        <BizBoundary>
          <BizUserLimitAlert />
          <Outlet
            context={
              {
                customer,
                company,
                subscription,
                username,
              } satisfies BizContextType
            }
          />
        </BizBoundary>
      </div>
    </div>
  )
}
