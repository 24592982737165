import type { ButtonProps } from '@jotta/ui/Button'
import { Button } from '@jotta/ui/Button'
import clsx from 'clsx'

export function CTAButton({ className, ...props }: ButtonProps) {
  return (
    <Button
      className={clsx('btn btn-lg btn-primary !mt-8 sm:px-20', className)}
      {...props}
    />
  )
}
