import { useAuthenticated } from '@jotta/auth-client/useAuthStatus'
import {
  getCustomerQueryKey,
  useGetCustomer,
} from '@jotta/grpc-connect-client/customer'
import { SubscriptionType } from '@jotta/grpc-connect-openapi/customer'
import { useCallback, useState } from 'react'
import type { SubscriptionInterval } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import { useMutation, useQuery } from '@connectrpc/connect-query'
import { getOffer } from '@jotta/grpc-connect-openapi/offerQuery'
import { Country } from '@jotta/grpc-connect-openapi/country'
import {
  authTransport,
  publicTransport,
} from '@jotta/grpc-connect-client/transport'
import type { UserType } from '../auth/CreateUser'
import { getSupportedPaymentMethods as getSupportedPaymentMethodsDescriptor } from '@jotta/grpc-connect-openapi/subscriptionQuery'
import { Offer_ProductScope } from '@jotta/grpc-connect-openapi/esm/openapi/offer/v1/offer_pb'
import type { Offer } from '@jotta/grpc-connect-openapi/esm/openapi/offer/v1/offer_pb'
import type { GetSupportedPaymentMethodsResponse_PaymentMethod } from '@jotta/grpc-connect-openapi/payment'
import { patchCustomer as patchCustomerDescriptor } from '@jotta/grpc-connect-openapi/customerQuery'
import { useCurrentLanguage } from '@jotta/i18n'
import { queryClient } from '@jotta/query'
import { exhaustiveGuard } from '@jotta/utils/exhaustive'
import { getCurrentCountry } from '@jotta/grpc-connect-openapi/geoQuery'
import { useBrandingInfo } from '@jotta/grpc-connect-client/config'

export type RecurringSubscriptionInterval =
  | SubscriptionInterval.YEARLY
  | SubscriptionInterval.MONTHLY

export type OfferState = {
  productCode: string
  type: UserType | undefined
  free: boolean
  authenticated: boolean
  offer: Offer | undefined
  paymentMethods: GetSupportedPaymentMethodsResponse_PaymentMethod[] | undefined
  interval: RecurringSubscriptionInterval
  setInterval: (interval: RecurringSubscriptionInterval) => void
  country: Country
  countryCode: string
  setCountryCode: (country: string) => void
  extraStorageTB: number
  setExtraStorageTB: (extraStorageTB: number) => void
  subscriptionType: SubscriptionType
}

function toUserType(productScope: Offer_ProductScope): UserType {
  switch (productScope) {
    case Offer_ProductScope.PERSONAL:
    case Offer_ProductScope.HOME:
      return 'private'
    case Offer_ProductScope.BUSINESS:
      return 'business'
    case Offer_ProductScope.UNKNOWN_SCOPE:
      throw new Error()
  }
}

function isValid(offer: Offer) {
  const status = offer.status
  switch (status.case) {
    case 'valid':
    case 'recommended':
    case 'currentSubscription':
      return true
    case undefined:
    case 'invalid':
    case 'storageExceeded':
    case 'familyHasActiveUsers':
    case 'businessHasActiveUsers':
    case 'businessUserNotPermitted':
      return false
    default:
      exhaustiveGuard(status)
  }
}

export function useOfferState(
  productCode: string,
  defaultSubscriptionInterval: RecurringSubscriptionInterval,
  { initialExtraStorageTB = 0 }: { initialExtraStorageTB?: number } = {},
): OfferState {
  const { customerGroupCode, defaultCountry } = useBrandingInfo()
  const authenticated = useAuthenticated() === 'TRUE'
  const customer = useGetCustomer().data?.customer
  const subscriptionType =
    customer?.subscriptionType || SubscriptionType.UNKNOWN_SUBSCRIPTION_TYPE

  const language = useCurrentLanguage()
  const { data: currentCountry } = useQuery(
    getCurrentCountry,
    {},
    {
      throwOnError: false,
      select: data => data.country,
    },
  )

  const countryCode = customer?.countryCode.toLowerCase() || ''
  const [extraStorageTB, setExtraStorageTB] = useState(initialExtraStorageTB)
  const [interval, setInterval] = useState<RecurringSubscriptionInterval>(
    defaultSubscriptionInterval,
  )

  const { mutate: patchCustomer } = useMutation(patchCustomerDescriptor, {
    transport: authTransport,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getCustomerQueryKey,
      })
    },
  })

  const country =
    customer?.country || currentCountry || defaultCountry || Country.NORWAY

  const { data: getOfferResponse, refetch: refetchOffer } = useQuery(
    getOffer,
    {
      language,
      productCode: productCode,
      extraStorageTb: extraStorageTB,
      ...(authenticated
        ? {}
        : {
            unauthenticatedParams: {
              customerGroupCode,
              country,
            },
          }),
    },
    {
      transport: authenticated ? authTransport : publicTransport,
      placeholderData: previousData => previousData,
    },
  )

  const {
    data: getSupportedPaymentMethodsResponse,
    refetch: refetchPaymentMethods,
  } = useQuery(
    getSupportedPaymentMethodsDescriptor,
    {},
    {
      enabled: authenticated,
      transport: authTransport,
    },
  )

  const setCountryCode = useCallback(
    (countryCode: string) => {
      patchCustomer(
        {
          countryCode: {
            value: countryCode,
          },
        },
        {
          onSuccess: () => {
            refetchOffer()
            refetchPaymentMethods()
          },
        },
      )
    },
    [country, patchCustomer, refetchOffer, refetchPaymentMethods],
  )
  const offer = getOfferResponse?.offer
  const productScope = offer?.productScope
  const type = productScope ? toUserType(productScope) : undefined
  const free = !offer?.yearly?.basePrice?.amount

  if (offer && !isValid(offer)) {
    throw new Error('invalid offer')
  }

  return {
    productCode,
    type,
    authenticated,
    free,
    extraStorageTB,
    setExtraStorageTB,
    offer: offer,
    paymentMethods: getSupportedPaymentMethodsResponse?.paymentMethods,
    interval: interval,
    setInterval: setInterval,
    country,
    countryCode,
    setCountryCode,
    subscriptionType,
  }
}
