import { RouterProvider as ReactRouterProvider } from 'react-router-dom'
import type { Router } from './Types'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'

export function RouterProvider({ router }: { router: Router }) {
  return (
    <ReactRouterProvider
      router={router}
      fallbackElement={<LoadingOverlay open />}
    />
  )
}
