import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import type { PhotoMatch } from '@jotta/grpc-connect-openapi/esm/openapi/search/search.v2_pb'
import { CheckmarkIcon } from '@jotta/uinext/CheckmarkIcon'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { expr } from 'mobx-utils'
import { mergeProps, useLongPress, usePress } from 'react-aria'
import { ToggleButton } from 'react-aria-components'
import { useSearchParams } from 'react-router-dom'
import type { SelectionStore } from '../../store/SelectionStore'
const debug = Debug('jotta:photos:PhotoSearchResultListItem')

export const PhotoSearchResultListItem = observer<{
  match: PhotoMatch
  onNavigate: (id: string) => void
  showSimilarSearch?: boolean
  store: SelectionStore
}>(function PhotoSearchResultListItem({
  match,
  onNavigate,
  showSimilarSearch,
  store,
}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const { photoId, thumbnailUrl } = match
  const { selection, hasSelection, onPressToggle } = store
  const isSelected = expr(() => selection.has(photoId))
  const { pressProps } = usePress({
    onPress: e => (hasSelection ? onPressToggle(e) : onNavigate(photoId)),
  })
  const { longPressProps } = useLongPress({
    onLongPress: onPressToggle,
  })

  return (
    <div
      data-selected={isSelected}
      className="group relative flex-auto last:flex-grow-0 md:h-48"
      data-photoid={photoId}
    >
      <button
        data-selected={isSelected}
        className="relative z-10 aspect-square size-full transition-opacity duration-150 data-selected:opacity-50 md:aspect-auto"
        {...mergeProps(pressProps, longPressProps)}
      >
        <img
          src={`${thumbnailUrl}.s`}
          className="relative z-10 size-full object-cover object-center sm:h-full"
          alt=""
        />
      </button>
      <ToggleButton
        className={
          'absolute left-0 top-0 z-30 hidden size-10 p-2 opacity-0 group-data-selected:opacity-100 hover:opacity-100 mediahover:block'
        }
        onPress={onPressToggle}
      >
        <CheckmarkIcon data-selected={isSelected} />
      </ToggleButton>
      <button
        disabled={hasSelection}
        onClick={() => {
          searchParams.set('id', photoId)
          setSearchParams(searchParams)
        }}
        className="absolute bottom-0 left-0 z-20 rounded-sm ring-sx-primary transition-opacity duration-300 group-hover:opacity-100 focus:ring-0 disabled:z-0 disabled:opacity-0 group-hover:disabled:opacity-0 mediahover:opacity-0 md:rounded-full md:p-1"
      >
        <div className="rounded-sm bg-white p-1 md:rounded-full md:p-2">
          <PlainBrandIcon
            className="size-4 opacity-75 hover:opacity-100 md:size-6"
            icon="SvgPhotoSearch"
          />
        </div>
      </button>
    </div>
  )
})
