import type { GetPaymentInfoResponse } from '@jotta/grpc-connect-openapi/esm/openapi/subscription/payment.v2_pb'
import { t, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import Debug from 'debug'
import filesize from 'filesize'
import moize from 'moize'
import { BizPieChart } from '../components/BizPieChart'
import type { BizPieChartData } from '../BizTypes'
import { Link } from 'react-router-dom'
import CartIcon from './cart.svg'
import { BizWidget } from './BizWidget'
import type { ExtendedBusinessUser } from '../BizApi'
import { bizApi, useBizUsers } from '../BizApi'
const debug = Debug('jotta:biz:widget:accountstats')

const colors = ['#0088FE', '#FFBB28', '#00C49F', '#FF8042', '#AF19FF']
const selectUserData = moize.deep((users: ExtendedBusinessUser[]) => {
  const data: BizPieChartData = users
    .toSorted((a, b) => Number(b.storageBytes - a.storageBytes))
    .slice(0, 5)
    .map((user, i) => ({
      name: user.name || user.email,
      value: Number(user.storageBytes),
      fill: colors[i % colors.length],
    }))

  debug(data)
  return data
})

const selectPaymentData = moize.deep(
  (paymentInfo: GetPaymentInfoResponse, locale: string) => {
    debug('selectData', paymentInfo)
    const quotaBytes = Number(paymentInfo.subscriptionQuotaBytes)
    const usageBytes = Number(paymentInfo.subscriptionUsageBytes)
    const availableBytes = quotaBytes - usageBytes
    const quota = filesize(quotaBytes, {
      locale,
    })
    const usage = filesize(usageBytes, {
      locale,
    })
    const available = filesize(availableBytes, {
      locale,
    })
    return {
      quotaBytes,
      usageBytes,
      availableBytes,
      quota,
      usage,
      available,
      chartData: [
        {
          name: `${t`Available`} ${available}`,
          value: availableBytes,
          fill: '#0088FE',
        },
        {
          name: `${t`Used`} ${usage}`,
          value: usageBytes,
          fill: '#FFBB28',
        },
      ] satisfies BizPieChartData,
    }
  },
)

export function BizWidgetAccountStats() {
  const { data } = bizApi.subscription.getPaymentInfo.useSuspenseQuery()
  const { users } = useBizUsers()
  const locale = useLingui().i18n.locale
  const { quota, usage, chartData } = selectPaymentData(data, locale)
  return (
    <>
      <h2 className="title-md col-span-full flex items-baseline gap-3">
        <span>
          <Trans>Storage</Trans>{' '}
          <small className="body-lg-sh text-grey4">
            {`${quota} ${t`Storage`} (${usage} ${t`used`})`}
          </small>
        </span>
        <Link
          to="/web/upgrade"
          className="btn btn-link body-sm-sh ml-auto py-0"
        >
          <CartIcon />
          <Trans>Get more storage</Trans>
        </Link>
      </h2>
      <BizWidget title={t`Account stats`}>
        <div className="grid aspect-video">
          <BizPieChart data={chartData} />
        </div>
      </BizWidget>
      <BizWidget title={t`Storage by user`}>
        <div className="grid aspect-video">
          <BizPieChart data={selectUserData(users)} />
        </div>
      </BizWidget>
    </>
  )
}
