import { t, Trans } from '@lingui/macro'
import dayjs from 'dayjs'
import { bizApi } from '../BizApi'
import { BizWidget } from '../widgets/BizWidget'
import { formatPriceConnect } from '@jotta/settings/formatPrice'
import { useLocale } from '@jotta/i18n'
import { getOrRefreshAccessToken } from '@jotta/auth-client/useAuthStatus'
import { downloadBlob } from '@jotta/utils/download'
import Debug from 'debug'
const debug = Debug('jotta:biz:billing:history')

async function downloadReceipt(orderNumber: string, url: string) {
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await getOrRefreshAccessToken()}`,
    },
  })
    .then(res => {
      if (res.status === 200) {
        return res.arrayBuffer()
      } else {
        throw new Error(
          `error downloading url=${url}: ${res.status} ${res.statusText}`,
        )
      }
    })
    .then(blob => {
      return downloadBlob(new Blob([blob]), `${orderNumber}.pdf`)
    })
}

export function BizBillingHistoryRoute() {
  const { data: orders } =
    bizApi.subscription.getBillingHistory.useSuspenseQuery()
  const locale = useLocale()
  return (
    <BizWidget title={t`Billing history`}>
      <table className="table table-zebra">
        <thead>
          <tr>
            <th>
              <Trans>Order Number</Trans>
            </th>
            <th>
              <Trans>Date</Trans>
            </th>
            <th>
              <Trans>Type</Trans>
            </th>
            <th>
              <Trans>Status</Trans>
            </th>
            <th className="text-right">
              <Trans>Amount</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {orders.length ? (
            orders.map(order => (
              <tr key={order.orderNumber}>
                <td>
                  <button
                    className="btn btn-link p-0"
                    onClick={() => {
                      downloadReceipt(
                        order.orderNumber,
                        order.receiptLink,
                      ).catch(err => {
                        // Sentry.captureException(err)
                        debug('Error', err)
                      })
                    }}
                  >
                    {order.orderNumber}
                  </button>
                </td>
                <td>{dayjs(order.timestampMillis).format('lll')}</td>
                <td>{order.typeLocalized}</td>
                <td>{order.statusLocalized}</td>
                <td className="text-right">
                  {formatPriceConnect(
                    {
                      amount: order.priceCents,
                      currency: order.currency,
                    },
                    locale,
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>
                <Trans>No payments or invoices</Trans>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </BizWidget>
  )
}
