import { useQuery } from '@connectrpc/connect-query'
import type {
  GetOffersResponse_Category,
  Offer,
  Offer_Recommended,
} from '@jotta/grpc-connect-openapi/esm/openapi/offer/v1/offer_pb'
import { Offer_ProductScope } from '@jotta/grpc-connect-openapi/esm/openapi/offer/v1/offer_pb'
import { getOffers } from '@jotta/grpc-connect-openapi/offerQuery'
import { tt, useLocale } from '@jotta/i18n'
import { showNewIntercomMessage } from '@jotta/intercom'
import { formatPriceConnect } from '@jotta/settings/formatPrice'
import { BrandIcon } from '@jotta/ui/BrandIcon'
import { Divider } from '@jotta/ui/Divider'
import { LoadingOverlaySpinner } from '@jotta/ui/LoadingOverlay'
import { exhaustiveGuard } from '@jotta/utils/exhaustive'
import { filesize } from '@jotta/utils/filesize'
import { Trans } from '@lingui/macro'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { usePaymentInfo } from '../payment/usePaymentInformation'
import { TabContent, UpgradeTabSwitch } from './UpgradeTab'
import { cn } from '@jotta/utils/css'

function OfferAlert({ offer }: { offer: Offer }) {
  if (!offer.status.case) {
    return null
  }

  const settingsLink =
    offer.status.case === 'familyHasActiveUsers'
      ? '/web/family'
      : '/web/account'

  switch (offer.status.case) {
    case 'storageExceeded':
      return (
        <div className="body-md-ln bg-upgrade-background p-4 text-grey4">{tt`You store too much for this plan`}</div>
      )

    case 'businessHasActiveUsers':
    case 'familyHasActiveUsers':
      return (
        <div className="body-md-ln bg-upgrade-background p-4 text-grey4">
          <p>{`This plan only allows ${offer.status.value.allowedUsers} user${offer.status.value.allowedUsers !== 1 ? 's' : ''}.`}</p>
          <p>
            Users can be removed via{' '}
            <Link to={settingsLink} className="link">
              settings
            </Link>
          </p>
        </div>
      )

    case 'businessUserNotPermitted':
      return (
        <div className="body-md-ln bg-informative p-4 text-informative">
          Your subscription is administered by{' '}
          {offer.status.value.businessAdminEmails[0]}
          {offer.status.value.businessAdminEmails.length > 1
            ? ` + ${offer.status.value.businessAdminEmails.length - 1} others`
            : ''}
        </div>
      )

    case 'currentSubscription':
    case 'recommended':
    case 'invalid':
    case 'valid':
      return null

    default:
      return exhaustiveGuard(offer.status)
  }
}

function RecommendedTag({ title }: { title: string }) {
  return (
    <div className="body-sm-ln absolute left-0 top-0 flex h-8 w-full flex-nowrap items-center gap-2 rounded-t-sm bg-upgrade-recommended px-4 text-white-to-black">
      <BrandIcon icon="SvgBoldStar" className="h-full w-4" />
      {title}
    </div>
  )
}

function CurrentPlanTag() {
  return (
    <div className="body-sm-ln absolute left-0 top-0 flex h-8 w-full flex-nowrap items-center gap-2 rounded-t-sm bg-upgrade-background px-4 text-primary">
      Your current plan
    </div>
  )
}

function ContactSupport({ offer }: { offer: Offer }) {
  if (
    offer.status.case !== 'currentSubscription' ||
    !offer.status.value.contactSupport
  ) {
    return null
  }

  return (
    <div className="body-md-ln mt-8 flex w-[295px] flex-col justify-between self-start overflow-hidden bg-primary p-10 shadow-[0px_0px_10px_0px_rgba(205,201,223,0.80)]">
      <h3 className="h3">{offer.status.value.contactSupport.titleLocalized}</h3>
      <button
        onClick={showNewIntercomMessage}
        className="btn btn-xl btn-primary mt-10 w-full"
      >{tt`Start a chat`}</button>
      <div className="flex items-center gap-4 text-gray-500">
        <Divider className="w-full" />
        <p className="py-6">
          <Trans>or</Trans>
        </p>
        <Divider className="w-full" />
      </div>
      <p>{tt`Call our support team`}</p>
      <p>+47 123 45 678</p>
    </div>
  )
}

function UsageBar({
  current,
  max,
  danger = false,
}: {
  current: number
  max: number
  danger?: boolean
}) {
  return (
    <div className="relative flex h-[10px] w-full flex-nowrap rounded-xl bg-white-to-black shadow-[0px_1px_2px_0px_rgba(205,201,223,0.80)]">
      {!danger && (
        <div className="absolute left-0 top-0 h-full w-full rounded-xl border border-light" />
      )}
      <div
        className={cn('relative h-[10px] w-full rounded-xl', {
          'bg-danger-fg': danger,
          'bg-primary-fg': !danger,
        })}
        style={{ width: `${(current / max) * 100}%` }}
      />
    </div>
  )
}

function UserIndicator({ current, max }: { current: number; max: number }) {
  if (!max || max < current) {
    return null
  }

  return (
    <div className="flex w-full flex-nowrap gap-1 overflow-hidden">
      {[...Array(current)].map((v, i) => (
        <BrandIcon icon="SvgFillUser" width={16} height={16} key={i} />
      ))}
      {[...Array(max - current)].map((v, i) => (
        <BrandIcon icon="SvgUser" width={16} height={16} key={i + current} />
      ))}
    </div>
  )
}

function CurrentPlanInfo({ offer }: { offer: Offer }) {
  if (offer.status.case !== 'currentSubscription') {
    throw new Error('Invalid offer')
  }

  let infoType: 'usage' | 'bandwidth' | 'business' | 'family' = 'usage'

  if (offer.status.value.bandwidthNotice) {
    infoType = 'bandwidth'
  } else if (offer.productScope === Offer_ProductScope.BUSINESS) {
    infoType = 'business'
  } else if (offer.status.value.allowedUsers > 1) {
    infoType = 'family'
  }

  const allowedStorage =
    offer.status.value.currentAllowedStorage?.type.case === 'bytes'
      ? Number(offer.status.value.currentAllowedStorage.type.value)
      : undefined
  const currentStorage =
    offer.status.value.currentStorage?.type.case === 'bytes'
      ? Number(offer.status.value.currentStorage.type.value)
      : 0

  let storageRemaining = 1

  if (typeof allowedStorage === 'number' && allowedStorage > 0) {
    storageRemaining = 1 - currentStorage / allowedStorage
  }

  switch (infoType) {
    case 'usage': {
      if (typeof allowedStorage !== 'number') {
        return null
      }

      return (
        <div className="body-sm-sh px-4 pb-4">
          <div
            className={`rounded px-4 py-3 ${storageRemaining <= 0.1 ? 'bg-danger' : ''}`}
          >
            {!storageRemaining ? (
              <h4 className="h4 mb-3">{tt`All out of space!`}</h4>
            ) : storageRemaining <= 0.1 ? (
              <h4 className="h4 mb-3">Storage almost full</h4>
            ) : null}
            <UsageBar
              max={allowedStorage}
              current={currentStorage}
              danger={storageRemaining <= 0.1}
            />
            <div className="mt-2">
              {filesize(Number(currentStorage))} /{' '}
              {filesize(Number(allowedStorage))} storage used
            </div>
          </div>
        </div>
      )
    }
    case 'bandwidth':
      return (
        <div className="body-sm-ln px-4 pb-4">
          <div
            className={`rounded px-4 py-3 ${currentStorage >= 6_000_000_000_000 ? 'bg-danger' : 'bg-upgrade-background'}`}
          >
            <h4 className="h4">
              {filesize(Number(currentStorage))} storage used
            </h4>
            <p className="mt-2">
              {offer.status.value.bandwidthNotice!.localizedText}
            </p>
          </div>
        </div>
      )

    case 'family': {
      return (
        <div className="body-sm-sh px-4 pb-4">
          <div
            className={`rounded px-4 py-3 ${storageRemaining <= 0.1 ? 'bg-danger' : ''}`}
          >
            <UserIndicator
              current={offer.status.value.currentUsers}
              max={offer.status.value.allowedUsers}
            />
            <p className="my-3">{`${offer.status.value.currentUsers} family members on your plan`}</p>
            {typeof allowedStorage === 'number' && (
              <UsageBar
                max={allowedStorage}
                current={currentStorage}
                danger={storageRemaining <= 0.1}
              />
            )}
            <div className="mt-2">
              {filesize(Number(currentStorage))} /{' '}
              {filesize(Number(allowedStorage))} storage used
            </div>
          </div>
        </div>
      )
    }
  }
}

export function OfferView({
  offer,
  standout = false,
}: {
  offer: Offer
  standout?: boolean
}) {
  const locale = useLocale()
  const monthlyPrice = offer.monthly?.basePrice
  const users = offer.productFeaturesLocalized[0]
  const features = offer.productFeaturesLocalized
    .slice(1)
    .filter(feature => !feature.startsWith('*'))
  const notice = offer.productFeaturesLocalized
    .slice(1)
    .filter(feature => feature.startsWith('*'))
  const extraStorageTB = Math.round(
    Number(
      (usePaymentInfo().data?.extraStorageBytes || 0n) / 1_000_000_000_000n,
    ),
  )

  const isCurrent = offer.status.case === 'currentSubscription'
  const isValid =
    offer.status.case === 'valid' ||
    offer.status.case === 'recommended' ||
    (isCurrent && offer.extraStorageAvailable)
  const faded = !isCurrent && !isValid
  const highlighted = Boolean((monthlyPrice?.amount || 0) > 0 || isCurrent)
  const link = `/web/upgrade/${offer.productCode}`
  const isFirstRecommendation =
    offer.status.case === 'recommended' && !offer.status.value.priorityIdx
  const recommendedTitle =
    offer.status.case === 'recommended' ? offer.status.value.titleLocalized : ''

  if (offer.status.case === 'invalid') {
    return null
  }

  const showProductHighlight = offer.status.case !== 'recommended' && !isCurrent

  return (
    <div
      className={cn(
        'relative mt-8 flex w-[295px] flex-col justify-between rounded-lg',
        {
          'border-2 border-upgrade-recommended': isFirstRecommendation,
          'min-h-[620px] shadow-[0px_4px_20px_0px_rgba(205,201,223,0.80)]':
            standout,
          'min-h-[580px] shadow-[0px_0px_10px_0px_rgba(205,201,223,0.80)]':
            !standout,
          'bg-grey1': faded,
          'bg-primary': !faded,
        },
      )}
    >
      {isCurrent ? (
        <CurrentPlanTag />
      ) : isFirstRecommendation ? (
        <RecommendedTag title={recommendedTitle} />
      ) : null}

      <div
        className={cn('flex-auto px-6 pt-8 sm:px-8', { 'text-grey4': faded })}
      >
        <p
          className={`body-md-ln ${highlighted ? 'text-upgrade-text-highlight' : ''}`}
        >
          {(showProductHighlight && offer.productHighlightLocalized) ||
            '\u00A0'}
        </p>
        <h3 className="body-3x-ln mt-3">{`${offer.productNameLocalized}${notice.length ? '*' : ''}`}</h3>
        <p className="title-sm mt-4">
          {monthlyPrice && formatPriceConnect(monthlyPrice, locale)}/
          <Trans id="month" />
        </p>
        <Divider className="my-8" />
        <p className="body-sm-ln mb-3">{users}</p>
        {features.map((feature, i) => (
          <div className="flex flex-nowrap items-baseline gap-2" key={i}>
            <p className="relative top-[2px]">
              <BrandIcon icon="SvgBoldCheck" />
            </p>
            <p className="body-sm-ln" key={i}>
              {feature}
            </p>
          </div>
        ))}
        {notice.length > 0 && (
          <div className="body-sm-ln mt-3">
            {notice.map((note, i) => (
              <p key={i}>{note}</p>
            ))}
          </div>
        )}
      </div>
      {isValid && (
        <div className="body-sm-ln mt-2 px-8 pb-8">
          {offer.extraStorageAvailable && isCurrent ? (
            <>
              <p>{`${extraStorageTB}TB extra storage added`}</p>
              <Link to={link} className="btn btn-xl btn-outline mt-2 w-full">
                <BrandIcon icon="SvgBoldPlus" />
                {tt`Add more storage`}
              </Link>
            </>
          ) : (
            <Link
              to={link}
              className="btn btn-xl btn-primary w-full"
            >{tt`Buy now`}</Link>
          )}
        </div>
      )}
      {isCurrent && <CurrentPlanInfo offer={offer} />}
      <OfferAlert offer={offer} />
    </div>
  )
}

function OfferCategoryView({
  category,
}: {
  category: GetOffersResponse_Category
}) {
  return (
    <div className="flex flex-wrap justify-center gap-4 pb-6 pt-12">
      {category.offers.map(value => (
        <OfferView key={value.productCode} offer={value}></OfferView>
      ))}
    </div>
  )
}

function RecommendedPlans({
  offers,
  current,
  onNavigate,
}: {
  offers: Offer[]
  current?: Offer
  onNavigate: () => void
}) {
  return (
    <>
      <h1 className="title-lg self-center">Need a change?</h1>
      <h2 className="body-lg-ln mt-2 self-center text-grey4">
        We made an educated guess
      </h2>
      <button
        className="label-sm flex h-12 flex-nowrap items-center gap-2 self-center text-nowrap px-4 pt-6"
        onClick={() => onNavigate()}
      >
        See all plans <BrandIcon icon="SvgArrowRight" width={16} height={16} />
      </button>
      <div className="mx-auto w-full max-w-screen-xl">
        <div
          className={cn(
            'flex flex-wrap items-center justify-center pb-6 pt-4',
            {
              'gap-14': offers.length <= 2,
              'gap-6': offers.length > 2,
            },
          )}
        >
          {current && <OfferView offer={current} />}
          {offers.map(offer => (
            <OfferView
              offer={offer}
              key={offer.productCode}
              standout={
                offer.status.case === 'recommended' &&
                !offer.status.value.priorityIdx
              }
            />
          ))}
          {current && <ContactSupport offer={current} />}
        </div>
      </div>
    </>
  )
}

function AllPlans({
  initialTab,
  categories,
  onNavigate,
  hasRecommended,
}: {
  initialTab?: string
  categories: GetOffersResponse_Category[]
  onNavigate: () => void
  hasRecommended: boolean
}) {
  return (
    <>
      <h1 className="title-lg self-center">Subscription plans</h1>
      <h2 className="body-lg-ln mt-2 self-center text-grey4">
        Something for everyone
      </h2>
      {hasRecommended && (
        <button
          className="label-sm flex h-12 flex-nowrap items-center gap-2 self-center text-nowrap px-4 pt-6"
          onClick={() => onNavigate()}
        >
          See recommended plans{' '}
          <BrandIcon icon="SvgArrowRight" width={16} height={16} />
        </button>
      )}
      <div className="mt-20 w-full">
        <UpgradeTabSwitch>
          {categories.map(category => (
            <TabContent
              name={category.nameLocalized}
              key={category.nameLocalized}
              initial={category.nameLocalized === initialTab}
            >
              <OfferCategoryView category={category}></OfferCategoryView>
            </TabContent>
          ))}
        </UpgradeTabSwitch>
      </div>
    </>
  )
}

export const Upgrade: React.FC = () => {
  const { data: getOffersResponse, isLoading } = useQuery(getOffers)
  const categories = useMemo(
    () => getOffersResponse?.categories || [],
    [getOffersResponse],
  )
  const offers = useMemo(
    () => categories.map(category => category.offers).flat(),
    [categories],
  )
  const currentCategory = useMemo(() => {
    for (const category of categories) {
      if (
        category.offers.find(
          offer => offer.status.case === 'currentSubscription',
        )
      ) {
        return category.nameLocalized
      }
    }

    return undefined
  }, [categories])
  const current = useMemo(
    () => offers.find(offer => offer.status.case === 'currentSubscription'),
    [offers],
  )
  const recommended = useMemo(
    () =>
      offers
        .filter(offer => offer.status.case === 'recommended')
        .sort(
          (a, b) =>
            (a.status.value as Offer_Recommended).priorityIdx -
            (b.status.value as Offer_Recommended).priorityIdx,
        ),
    [offers],
  )
  const [showRecommended, setShowRecommended] = useState(true)

  useEffect(() => {
    if (!isLoading && !recommended.length) {
      setShowRecommended(false)
    }
  }, [isLoading, recommended])

  if (isLoading) {
    return (
      <div className="flex min-h-full w-full items-center justify-center">
        <LoadingOverlaySpinner />
      </div>
    )
  }

  return (
    <div className="w-[calc(100vw-var(--content-left))] flex-auto bg-upgrade-background pr-[var(--scrollbar-width,0px)]">
      <div className="mx-auto flex flex-col p-6 pb-0 pt-12 sm:px-12">
        {showRecommended ? (
          <RecommendedPlans
            offers={recommended}
            current={current}
            onNavigate={() => setShowRecommended(false)}
          />
        ) : (
          <AllPlans
            categories={categories}
            initialTab={currentCategory}
            hasRecommended={recommended.length > 0}
            onNavigate={() => setShowRecommended(true)}
          />
        )}
      </div>
    </div>
  )
}
