import type { PlainMessage } from '@bufbuild/protobuf'
import type { Business } from '@jotta/grpc-connect-openapi/customer'
import { Country } from '@jotta/grpc-connect-openapi/esm/openapi/country_pb'
import { ReactAriaSelectCountry } from '@jotta/ui/ReactAriaSelectCountry'
import { ReactAriaTextField } from '@jotta/ui/ReactAriaTextField'
import { t, Trans } from '@lingui/macro'
import { useForm } from '@tanstack/react-form'
import { zodValidator } from '@tanstack/zod-form-adapter'
import Debug from 'debug'
import { z } from 'zod'
import { bizApi } from '../BizApi'
import { useBizContext } from '../BizLayout'
import { BizWidget } from '../widgets/BizWidget'

const debug = Debug('jotta:biz:BizCompanyInformationForm')

export function BizCompanyInformationForm() {
  const { company } = useBizContext()
  const mutation = bizApi.company.update.useMutation()
  const { Field, handleSubmit, Subscribe } = useForm<PlainMessage<Business>>({
    defaultValues: {
      ...company,
    },
    onSubmit: async ({ value }) => {
      debug('submit', value)
      await mutation.mutateAsync(value)
    },
  })
  return (
    <BizWidget title={t`Company information`}>
      <form
        className="form relative grid grid-cols-1 sm:grid-cols-2"
        onSubmit={e => {
          e.preventDefault()
          handleSubmit()
        }}
      >
        <Subscribe selector={state => state.isSubmitting}>
          {isSubmitting =>
            isSubmitting && <div className="loading loading-absolute"></div>
          }
        </Subscribe>
        <Field
          name="name"
          validatorAdapter={zodValidator()}
          validators={{
            onChange: z.string().min(1),
          }}
        >
          {({ name, state, handleChange, handleBlur }) => (
            <ReactAriaTextField
              id={name}
              label={t`Company name`}
              description={t`Your business name as you want it to be displayed.`}
              name={name}
              value={state.value}
              onChange={value => handleChange(value)}
              onBlur={handleBlur}
              isRequired
              isInvalid={!!state.meta.errors.length}
              errorMessage={state.meta.errors}
            />
          )}
        </Field>
        <Field
          name="orgNo"
          validatorAdapter={zodValidator()}
          validators={{
            onChange: z.string().min(1),
          }}
        >
          {({ name, state, handleChange, handleBlur }) => (
            <ReactAriaTextField
              id={name}
              label={t`Organization number`}
              description={t`The company's official VAT number`}
              name={name}
              value={state.value}
              onChange={value => handleChange(value)}
              onBlur={handleBlur}
              isRequired
              isInvalid={!!state.meta.errors.length}
              errorMessage={state.meta.errors}
            />
          )}
        </Field>
        <Field
          name="country"
          validatorAdapter={zodValidator()}
          validators={{
            onChange: z.nativeEnum(Country),
          }}
        >
          {({ name, state, handleChange, handleBlur }) => (
            <ReactAriaSelectCountry
              name={name}
              selectedKey={state.value}
              onSelectionChange={e => handleChange(e as any)}
              onBlur={handleBlur}
              isRequired
              isInvalid={!!state.meta.errors.length}
              errorMessage={state.meta.errors}
              label={t`Country`}
              description={t`The chosen country and its language will be the default for new users. All users can change their preferred language and country later in his/her account settings page.`}
            />
          )}
        </Field>
        <div className="form-control">
          <div className="label">
            <Trans>Data processing agreement</Trans>
          </div>
          <div className="form-control-description">
            <a
              className="link"
              target="_blank"
              rel="noreferrer"
              href="https://jottacloud.com/resources/dpa/DPA-Jotta-AS-v20221220.pdf"
            >
              <Trans>View the signed data processing agreement</Trans>
            </a>
          </div>
        </div>
        <div className="form-buttons">
          <Subscribe
            selector={state => [state.isSubmitting, state.isDirty] as const}
          >
            {([isSubmitting, isDirty]) => (
              <button
                disabled={isSubmitting || !isDirty}
                type="submit"
                className="btn btn-primary"
              >
                <Trans>Update</Trans>
              </button>
            )}
          </Subscribe>
        </div>
      </form>
    </BizWidget>
  )
}
