import { TextInput } from '@jotta/ui/TextInput'
import { CTAButton } from './CTAButton'
import { t, Trans } from '@lingui/macro'
import type { SignupRequestCallback } from './CreateUser'
import { useForm } from '@jotta/hooks'
import { TermsAndConditionsCheckbox } from './TermsAndConditionsCheckbox'
import {
  newsletterField,
  useAcceptedTermsField,
  useNameField,
  usePasswordField,
} from './signupForm'
import { FormField } from '@jotta/ui/FormField'
import { Form } from '@jotta/ui/Form'
import { PasswordField } from './PasswordField'

export function CreatePreSignupUser({
  phoneNumber,
  className,
  isPending,
  signup,
}: {
  phoneNumber: string
  customerGroupCode: string
  className: string
  isPending: boolean
  signup: SignupRequestCallback
  prefill?: { name?: string; email?: string; voucherCode?: string }
}) {
  const form = useForm({
    fields: {
      name: useNameField(),
      password: usePasswordField(),
      acceptedTerms: useAcceptedTermsField(),
      newsletter: newsletterField,
    },
    submit: ({ name, password, newsletter }) =>
      signup({
        request: {
          case: 'phoneNumberSignup',
          value: {
            phoneNumber,
            fullname: name,
            password: password,
          },
        },
        wantsNewsletter: Boolean(newsletter),
      }),
  })

  const { name, password, acceptedTerms, newsletter, isSubmitting } = form

  return (
    <Form form={form} className={className}>
      <FormField label={t`Name`} field={name}>
        <TextInput {...name.inputProps} />
      </FormField>

      <PasswordField field={password} />

      <TermsAndConditionsCheckbox
        acceptedTerms={acceptedTerms}
        newsletter={newsletter}
      />

      <CTAButton loading={isPending || isSubmitting} type="submit">
        <Trans>Create account</Trans>
      </CTAButton>
    </Form>
  )
}
