import { I18n, i18n } from '@lingui/core'
import { useLingui } from '@lingui/react'
import { useSuspenseQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import 'dayjs/locale/ca'
import 'dayjs/locale/da'
import 'dayjs/locale/de'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/es'
import 'dayjs/locale/fi'
import 'dayjs/locale/fr'
import 'dayjs/locale/nb'
import 'dayjs/locale/nl'
import 'dayjs/locale/pl'
import 'dayjs/locale/pt'
import 'dayjs/locale/sv'
import dayjsLocaleData from 'dayjs/plugin/localeData'
import bigIntSupport from 'dayjs/plugin/bigIntSupport'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import Debug from 'debug'
import {
  ca,
  da,
  de,
  en,
  es,
  fi,
  fr,
  nb,
  nl,
  pl,
  pt,
  sv,
} from 'make-plural/plurals'
import { action, makeAutoObservable } from 'mobx'
import { useEffect, useState } from 'react'
import { setLanguageCookie } from './languageCookie'
import type { Locale } from './locale'
import { localeToLanguage } from './locale'
import { localeInfoByCode } from './locale'
import type { Language } from '@jotta/grpc-connect-openapi/language'
import { getDetectedLocale } from './locale'
import { ensureAuthStatus } from '@jotta/auth-client/useAuthStatus'
import { patchCustomer } from '@jotta/grpc-connect-client/customer/patchCustomer'

const debug = Debug('jotta:i18n:useLocale')

dayjs.extend(utc)
dayjs.extend(dayjsLocaleData)
dayjs.extend(localizedFormat)
dayjs.extend(bigIntSupport)
dayjs.extend(relativeTime)

i18n.loadLocaleData({
  en: { plurals: en },
  da: { plurals: da },
  de: { plurals: de },
  fi: { plurals: fi },
  fr: { plurals: fr },
  nb: { plurals: nb },
  nl: { plurals: nl },
  sv: { plurals: sv },
  es: { plurals: es },
  ca: { plurals: ca },
  pl: { plurals: pl },
  pt: { plurals: pt },
})

export function useActivateLocale(options: {
  /** Override locale detection and only use this locale */
  overrideLocale?: Locale
  /** Skip auth status check and patching of customer language (it breaks in storybook) */
  skipAuth?: boolean
}) {
  const locale = options?.overrideLocale || getDetectedLocale()

  const { isSuccess } = useSuspenseQuery({
    queryKey: ['i18n', locale, options.skipAuth] as const,
    queryFn: ({ queryKey: [key, locale, skipAuth] }) => {
      debug('Detected locale %s', locale)
      return dynamicActivate(locale, skipAuth)
    },
    refetchOnWindowFocus: false,
  })

  return {
    isSuccess,
    i18n,
  }
}

export const observableLocale = makeAutoObservable(
  {
    // This is the observable version of the locale. Mobx stores will react to changes to this
    locale: getDetectedLocale(),
    // This is an untracked version of the locale that is not observable, use for reading
    // locale outside of a mobx reactive context
    localeUntracked: getDetectedLocale(),
    get languageCode() {
      return localeInfoByCode[this.locale as Locale].languageCode
    },
    setLocale(locale: Locale) {
      this.locale = locale
      this.localeUntracked = locale
      i18n.activate(locale)
      setLanguageCookie(locale)
      dayjs.locale(this.languageCode)
      debug('setLocale %s %s', locale, this.languageCode)
    },
    get dateFormatterMedium() {
      return new Intl.DateTimeFormat(this.languageCode, {
        dateStyle: 'medium',
      })
    },
    get dateFormatterShort() {
      return new Intl.DateTimeFormat(this.languageCode, {
        dateStyle: 'short',
      })
    },
    get dateTimeFormatterShort() {
      return new Intl.DateTimeFormat(this.languageCode, {
        dateStyle: 'short',
        timeStyle: 'short',
      })
    },
    get dateTimeFormatterMedium() {
      return new Intl.DateTimeFormat(this.languageCode, {
        dateStyle: 'medium',
        timeStyle: 'short',
      })
    },
  },
  {
    setLocale: action,
    // Disable observability for this property
    localeUntracked: false,
  },
)

async function switchLocale(locale: Locale, i18nObj: I18n = i18n) {
  switch (locale) {
    case 'da':
      {
        const { messages } = await import(
          /* webpackChunkName: "locale-da" */ './locales/da.po'
        )
        i18nObj.load(locale, messages)
      }
      break
    case 'de':
      {
        const { messages } = await import(
          /* webpackChunkName: "locale-de" */ './locales/de.po'
        )
        i18nObj.load(locale, messages)
      }
      break
    case 'en':
      {
        const { messages } = await import(
          /* webpackChunkName: "locale-en" */ './locales/en.po'
        )
        i18nObj.load(locale, messages)
      }
      break
    case 'fi':
      {
        const { messages } = await import(
          /* webpackChunkName: "locale-fi" */ './locales/fi.po'
        )
        i18nObj.load(locale, messages)
      }
      break
    case 'fr':
      {
        const { messages } = await import(
          /* webpackChunkName: "locale-fr" */ './locales/fr.po'
        )
        i18nObj.load(locale, messages)
      }
      break
    case 'nb':
      {
        const { messages } = await import(
          /* webpackChunkName: "locale-nb" */ './locales/nb.po'
        )
        i18nObj.load(locale, messages)
      }
      break
    case 'nl':
      {
        const { messages } = await import(
          /* webpackChunkName: "locale-nl" */ './locales/nl.po'
        )
        i18nObj.load(locale, messages)
      }
      break
    case 'sv':
      {
        const { messages } = await import(
          /* webpackChunkName: "locale-sv" */ './locales/sv.po'
        )
        i18nObj.load(locale, messages)
      }
      break
    case 'es':
      {
        const { messages } = await import(
          /* webpackChunkName: "locale-es" */ './locales/es.po'
        )
        i18nObj.load(locale, messages)
      }
      break
    case 'ca':
      {
        const { messages } = await import(
          /* webpackChunkName: "locale-ca" */ './locales/ca.po'
        )
        i18nObj.load(locale, messages)
      }
      break
    case 'pt':
      {
        const { messages } = await import(
          /* webpackChunkName: "locale-pt" */ './locales/pt.po'
        )
        i18nObj.load(locale, messages)
      }
      break
    case 'pl':
      {
        const { messages } = await import(
          /* webpackChunkName: "locale-pl" */ './locales/pl.po'
        )
        i18nObj.load(locale, messages)
      }
      break
  }
}

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 * @param skipAuth Skip auth status check and patching of customer language (it breaks in storybook)
 */
export async function dynamicActivate(locale: Locale, skipAuth = false) {
  await switchLocale(locale)
  const update = observableLocale.localeUntracked !== locale
  observableLocale.setLocale(locale)
  const language = localeToLanguage[locale]
  if (!skipAuth && update && language) {
    const { authenticated, username } = await ensureAuthStatus()
    if (authenticated && username) {
      await patchCustomer({
        language: {
          language: language,
        },
      })
    }
  }
  // debug('Activated locale: %s', locale)
  return true
}

export function useSubLocale(locale: Locale) {
  const [_i18n] = useState(() => {
    return new I18n({
      locale: i18n.locale,
      locales: i18n.locales,
      messages: { [i18n.locale]: i18n.messages },
      localeData: {
        en: { plurals: en },
        da: { plurals: da },
        de: { plurals: de },
        fi: { plurals: fi },
        fr: { plurals: fr },
        nb: { plurals: nb },
        nl: { plurals: nl },
        sv: { plurals: sv },
        es: { plurals: es },
        ca: { plurals: ca },
        pl: { plurals: pl },
        pt: { plurals: pt },
      },
    })
  })

  // const makeSafe = useSafePromise()

  const [isLoading, setIsLoading] = useState(locale !== i18n.locale)

  useEffect(() => {
    if (_i18n.locale === locale) {
      return
    }

    setIsLoading(true)

    switchLocale(locale, _i18n).then(() => {
      _i18n.activate(locale)
      setIsLoading(false)
    })
  }, [locale, _i18n])

  return { i18n: _i18n, isLoading }
}

export function getCurrentLanguage(): Language {
  return localeToLanguage[observableLocale.localeUntracked]
}

export function useLocale(): Locale {
  const lingui = useLingui()
  const locale = lingui.i18n.locale as Locale
  return locale
}

export function useCurrentLanguage(): Language {
  const locale = useLocale()
  const language = localeToLanguage[locale]
  return language
}
