import type { CountryInfo } from '@jotta/i18n'
import { useLocalizedCountryList } from '@jotta/i18n'
import Debug from 'debug'
import {
  ReactAriaSelect,
  ReactAriaSelectOption,
  type ReactAriaSelectProps,
} from '../ReactAriaSelect/ReactAriaSelect'
const debug = Debug('jotta:ui:ReactAriaSelectCountry')

type ReactAriaSelectCountryProps = Omit<
  ReactAriaSelectProps<CountryInfo>,
  'items' | 'children'
>

export function ReactAriaSelectCountry({
  ...props
}: ReactAriaSelectCountryProps) {
  const { countries } = useLocalizedCountryList()

  return (
    <ReactAriaSelect {...props} items={countries}>
      {item => (
        <ReactAriaSelectOption id={item.country}>
          {item.name}
        </ReactAriaSelectOption>
      )}
    </ReactAriaSelect>
  )
}
