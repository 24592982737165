import type { BrandingInfo } from '@jotta/grpc-web/no/jotta/openapi/config/v2/config.v2_pb'
import { LinkServicePromiseClient } from '@jotta/grpc-web/no/jotta/openapi/link/v1/externallinkservice_grpc_web_pb'
import { ValidateExternalLinkRequest } from '@jotta/grpc-web/no/jotta/openapi/link/v1/externallinkservice_pb'
import { OpenServicePromiseClient } from '@jotta/grpc-web/no/jotta/openapi/open/v1/open.v1_grpc_web_pb'
import {
  TranslateOldShareLinkRequest,
  TranslateOldShareLinkResponse,
} from '@jotta/grpc-web/no/jotta/openapi/open/v1/open.v1_pb'
import type { ExternalLinkRef } from '@jotta/grpc-web/no/jotta/openapi/ref_pb'
import { withGrpcClientMiddleware } from './grpcutils'
import { env } from '@jotta/utils/env'
import { Language } from '@jotta/grpc-web/no/jotta/openapi/language_pb'
import { Country } from '@jotta/grpc-web/no/jotta/openapi/country_pb'
export { TranslateOldShareLinkRequest, TranslateOldShareLinkResponse }

let linksClient: LinkServicePromiseClient
let openClient: OpenServicePromiseClient

const GRPC_WEB_API = env.grpcApi

export async function lookupLink(ref: ExternalLinkRef) {
  const req = new ValidateExternalLinkRequest()
  req.setLink(ref)

  return await getLinkServiceClient().validateExternalLink(req)
}

export function getLinkServiceClient() {
  if (!linksClient) {
    linksClient = withGrpcClientMiddleware(
      'linkservice',
      new LinkServicePromiseClient(GRPC_WEB_API),
    )
  }
  return linksClient
}

export async function translateOldShareLink(username: string, linkId: string) {
  const req = new TranslateOldShareLinkRequest()
    .setUsername(username)
    .setLinkId(linkId)
  return await getOpenServiceClient().translateOldShareLink(req)
}

export function getOpenServiceClient() {
  if (!openClient) {
    openClient = withGrpcClientMiddleware(
      'openservice',
      new OpenServicePromiseClient(GRPC_WEB_API),
    )
  }
  return openClient
}

export const emptyBrandingInfo: BrandingInfo.AsObject = {
  aboutUsLink: '',
  accountTerminationLink: '',
  activeDomainList: [],
  activeDomainPostfixList: [],
  blogLink: '',
  brandCode: '',
  brandName: '',
  cookiePolicyLink: '',
  customerGroupCode: '',
  customerGroupName: '',
  downloadAndroidLink: '',
  downloadCliLink: '',
  downloadIosLink: '',
  downloadMacLink: '',
  downloadPageLink: '',
  downloadWinLink: '',
  facebookLink: '',
  faqLink: '',
  homepageLink: '',
  cliInstallationInstructionsLink: '',
  partnerInfoLink: '',
  pricingLink: '',
  privacyLink: '',
  signupBusinessLink: '',
  signupFreeLink: '',
  stripePublicKey: '',
  supportLink: '',
  termsLink: '',
  twitterLink: '',
  upgradeLink: '',
  defaultLanguage: Language['UNKNOWN_LANGUAGE'],
  defaultCountry: Country['UNKNOWN_COUNTRY'],
  oidcIssuerUrl: '',
  supportChannelsList: [],
}
