import { isBrandKey } from '@jotta/types/Brand'
import moize from 'moize'
import { z } from 'zod'
import Debug from 'debug'
import { readEnvironment } from './frontendbackend'
const debug = Debug('jotta:utils:env')

export const citynodes = [
  'alta',
  'fauske',
  'namsos',
  'otta',
  'raufoss',
] as const
export type CityNode = (typeof citynodes)[number]
export const environments = [
  'alpha',
  'beta',
  'charlie',
  'localhost',
  'next',
  'production',
  ...citynodes,
] as const

export type Environment = (typeof environments)[number]

const jxwebEnvironments: readonly Environment[] = ['beta', 'production']
export function isCityNode(value: any): value is CityNode {
  return citynodes.includes(value)
}
export function isEnv(value: any): value is Environment {
  return environments.includes(value)
}
declare global {
  // eslint-disable-next-line no-var
  var frontendbackend: unknown
}
const frontendBackendSchema = z.object({
  grpc_web_api_url: z.string().url(),
  sentry_dsn: z.string().url(),
  release_name: z.string(),
})
export const getEnv = moize(
  function getEnv(
    href: string = globalThis.location
      ? globalThis.location.origin
      : 'https://www.jottacloud.com',
    processEnv: Record<
      'ACTIVE_BRANDS' | 'NODE_ENV' | 'REACT_APP_GRPC_PROXY_WEB_URL',
      string
    > = {
      ACTIVE_BRANDS: process.env.ACTIVE_BRANDS || 'JOTTACLOUD',
      NODE_ENV: process.env.NODE_ENV || 'production',
      REACT_APP_GRPC_PROXY_WEB_URL:
        process.env.REACT_APP_GRPC_PROXY_WEB_URL || 'https://api.jotta.cloud',
    } as const,
  ) {
    const url = new URL(href)
    const activeBrands = processEnv.ACTIVE_BRANDS.split(',').filter(isBrandKey)
    const nodeEnv = processEnv.NODE_ENV
    const isLocal =
      nodeEnv === 'development' ||
      url.port === '3300' ||
      url.origin.includes('local') ||
      /^(\d+\.){3}\d+$/.test(url.hostname)
    const isQA = !isLocal && /\.jotta\.(cloud|us)+$/.test(url.origin)
    const isProduction = !isLocal && !isQA
    const isHTTPS = url.protocol === 'https:'
    const fb = frontendBackendSchema
      .catch({
        grpc_web_api_url: String(
          processEnv.REACT_APP_GRPC_PROXY_WEB_URL || 'https://api.jotta.cloud',
        ),
        sentry_dsn: 'https://6b7861b074be4432b07abf1556a435d1@sn.jotta.cloud/7',
        release_name: 'webapp-local',
      })
      .parse(readEnvironment())

    const releaseName = fb.release_name
    const grpcApi = fb.grpc_web_api_url
    const result = {
      activeBrands,
      isLocal,
      isHTTPS,
      isQA,
      isProduction,
      isDevelopment: !isProduction,
      env: isLocal ? 'local' : isQA ? 'qa' : 'production',
      nodeEnv,
      releaseName,
      grpcApi,
      photosApi: String(process.env.PHOTOS_API),
      sentryDsn: fb.sentry_dsn,
      zipApi: String(process.env.ZIP_API),
      get sentryEnv(): Environment {
        if (
          ['https://jottacloud.com', 'https://www.jottacloud.com'].includes(
            url.origin,
          )
        ) {
          return 'production'
        }
        if (this.releaseName === 'webapp') {
          return 'production'
        }
        if (this.isLocal) {
          return 'localhost'
        }
        if (this.releaseName.startsWith('webapp-')) {
          const unprefixed = this.releaseName.replace(/^webapp-(.+)/, '$1')
          if (isEnv(unprefixed)) {
            return unprefixed
          }
        }

        const [, citynodeMatch] =
          url.hostname.match(/^(\w+)(-\w+)?\.webapp\.jotta\.us/) || []
        if (citynodeMatch && isCityNode(citynodeMatch)) {
          return citynodeMatch
        }
        if (url.hostname.endsWith('.jotta.cloud')) {
          const subdomain = url.hostname.replace(
            /^(\w+)\.jotta\.cloud.*$/,
            '$1',
          )
          if (isCityNode(subdomain)) {
            return subdomain
          }
          if (isBrandKey(subdomain)) {
            return 'beta'
          }
        }
        return 'localhost'
      },
    } as const
    // debug(result)
    return { ...result }
  },
  {
    maxSize: 1,
  },
)

export const env = getEnv()
debug(env)
