import { t } from '@lingui/macro'
import { useBizUsers } from '../BizApi'
import { BizUserTable } from '../components/BizUserTable'
import { BizWidget } from './BizWidget'

export function BizWidgetUsersStorageLimit() {
  const { users } = useBizUsers()
  return (
    <BizWidget title={t`Users approaching storage limit`}>
      <BizUserTable
        sortBy="storageBytes"
        sortDescending
        compact
        users={users}
        view="storage"
      />
    </BizWidget>
  )
}
