import { LoadingOverlaySpinner } from '@jotta/ui/LoadingOverlay'
import { PaymentInformation } from '../payment/PaymentInformation'
import type { RecurringSubscriptionInterval } from '../payment/useOfferState'
import { useOfferState } from '../payment/useOfferState'
import type { Offer } from '@jotta/grpc-connect-openapi/offer'
import {
  Currency,
  SubscriptionInterval,
} from '@jotta/grpc-connect-openapi/payment'
import { getEnumKey } from '@jotta/utils/enum'
import { PaymentLayout } from '../payment/PaymentLayout'
import { usePaymentInfo } from '../payment/usePaymentInformation'
import { t } from '@lingui/macro'
import { useSearchParams } from 'react-router-dom'
import { useLayoutEffect } from 'react'
import { BrandIcon } from '@jotta/ui/BrandIcon'

function successUrl(offer: Offer, interval: SubscriptionInterval) {
  const currency = offer?.yearly?.basePrice?.currency
  const intervalKey = getEnumKey(SubscriptionInterval, interval)
  const currencyKey = currency && getEnumKey(Currency, currency)
  return `${location.origin}/web/upgradeAccepted/${offer.productCode}/${intervalKey}?currency=${currencyKey}&product=${offer.productCode}`
}

export const ConfirmUpgrade: React.FC<{
  productCode: string
  subscriptionInterval: RecurringSubscriptionInterval
}> = ({ productCode, subscriptionInterval }) => {
  const [search, setSearch] = useSearchParams()
  const extraStorageTB = Math.round(
    Number(
      (usePaymentInfo().data?.extraStorageBytes || 0n) / 1_000_000_000_000n,
    ),
  )
  const offerState = useOfferState(productCode, subscriptionInterval, {
    initialExtraStorageTB: extraStorageTB,
  })
  const { offer, interval } = offerState

  useLayoutEffect(() => {
    if (search.has('extra')) {
      setSearch(new URLSearchParams(), { replace: true })
    }
  }, [search, setSearch])

  if (!offer) {
    return (
      <div className="my-auto flex h-full w-full items-center justify-center">
        <LoadingOverlaySpinner />
      </div>
    )
  }

  return (
    <PaymentLayout
      backPath="/web/upgrade"
      backElement={
        <div className="label-sm flex h-12 min-w-12 items-center gap-2">
          <BrandIcon icon="SvgBoldCaretLeft" width={16} height={16} />
          <p>Back</p>
        </div>
      }
      offer={offer}
      interval={interval}
      title={t`Enter payment details`}
    >
      {summary => (
        <PaymentInformation
          offerState={offerState}
          successUrl={successUrl(offer, interval)}
          summary={summary}
        />
      )}
    </PaymentLayout>
  )
}
