import { t } from '@lingui/macro'
import { useBizUsers } from '../BizApi'
import { BizUserTable } from '../components/BizUserTable'
import { BizWidget } from './BizWidget'

export function BizWidgetLeastActiveUsers() {
  const { users } = useBizUsers()
  return (
    <BizWidget title={t`Least active users`}>
      <BizUserTable
        sortBy="lastWriteMillis"
        compact
        users={users}
        view="upload"
      />
    </BizWidget>
  )
}
