import type { Order } from '@jotta/grpc-connect-openapi/esm/openapi/subscription/payment.v2_pb'
import { useLocale } from '@jotta/i18n'
import { formatPriceConnect } from '@jotta/settings/formatPrice'
import { DefinitionListFromObject } from '@jotta/ui/DefinitionList'

export function BizOrderReceipt({ order }: { order: Order }) {
  const locale = useLocale()
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Description</th>
            <th>Amount</th>
            <th>Type</th>
            <th>Status</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {order.orderItems.map(item => (
            <tr key={item.productCode}>
              <td>{item.localizedName}</td>
              <td>{item.quantity}</td>
              <td>
                {formatPriceConnect(
                  {
                    amount: item.priceCents,
                    currency: order.currency,
                  },
                  locale,
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <DefinitionListFromObject value={order.toJson()} />
    </>
  )
}
