import Debug from 'debug'
import type { ReactNode } from 'react'
import type { UIMatch } from 'react-router-dom'
import { useMatches } from 'react-router-dom'
import { z } from 'zod'
const debug = Debug('jotta:hooks:useRouteBreadCrumb')
debug.enabled = false

type CrumbItemRenderFunction<Data = unknown> = (
  data: UIMatch<
    Data,
    {
      crumb: RouteBreadCrumbHandle
    }
  >,
) => ReactNode
export class RouteBreadCrumbHandle<Data = unknown> {
  crumbItemRenderFunctions: CrumbItemRenderFunction<Data>[]
  constructor(...items: CrumbItemRenderFunction<Data>[]) {
    this.crumbItemRenderFunctions = items
  }
}

const RouteBreadCrumbMatch = z.object({
  handle: z.object({
    crumb: z.instanceof(RouteBreadCrumbHandle),
  }),
})
type RouteBreadCrumbMatch = z.infer<typeof RouteBreadCrumbMatch>
function isRouteBreadCrumbMatch(match: unknown): match is RouteBreadCrumbMatch {
  return RouteBreadCrumbMatch.safeParse(match).success
}
export function useRouteBreadCrumb() {
  const crumbs = useMatches()
    .filter(match => isRouteBreadCrumbMatch(match))
    .flatMap(item =>
      item.handle.crumb.crumbItemRenderFunctions.map(crumb => crumb(item)),
    )
  return crumbs
}
