import type { PartialMessage } from '@bufbuild/protobuf'
import type {
  TerminateBusinessRequest,
  TerminateBusinessResponse,
} from '@jotta/grpc-connect-openapi/esm/openapi/business/v1/business_pb'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import { ReactAriaCheckbox } from '@jotta/ui/ReactAriaCheckbox'
import { ReactAriaTextField } from '@jotta/ui/ReactAriaTextField'
import { t, Trans } from '@lingui/macro'
import { type Dispatch, type SetStateAction, useState } from 'react'

export function BizTerminateAccountDialog({
  title,
  terminate,
  companyName,
  setOpen,
  isPending,
}: {
  title: string
  companyName: string
  terminate: (
    request: PartialMessage<TerminateBusinessRequest>,
  ) => void | Promise<void>
  terminateResponse?: TerminateBusinessResponse
  isPending?: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}) {
  const [consent, setConsent] = useState(false)

  const [password, setPassword] = useState('')
  const [smsCode, setSmsCode] = useState('')
  const [totpCode, setTotpCode] = useState('')
  const onClose = (open: boolean): void => {
    if (!open) {
      setConsent(false)
    }
    setOpen(open)
  }

  return (
    <RadixDialog
      title={title}
      onClose={onClose}
      buttons={[
        {
          action: 'close',
          label: t`Cancel`,
        },
        {
          label: t`Delete`,
          disabled: !consent || !password,
          action: () =>
            terminate({
              credentials: {
                password,
                smsCode,
                totpCode,
              },
            }),
          variant: 'buttons.error',
        },
      ]}
    >
      {isPending && <div className="loading loading-sm loading-absolute" />}
      <div className="prose prose-small pb-4">
        <p>
          {t`This will permanently delete all users accounts and the business account itself`}{' '}
          {companyName}.
        </p>
        <p>
          <Trans>
            All files and users associated with this accounts will be deleted
            permanently
          </Trans>
        </p>
      </div>
      <div className="form">
        <ReactAriaTextField
          type="password"
          label={t`Password confirm`}
          value={password}
          onChange={setPassword}
        />
        <ReactAriaCheckbox isSelected={consent} onChange={setConsent}>
          <Trans>
            I understand this operation is permanent and cannot be undone
          </Trans>
        </ReactAriaCheckbox>
      </div>
    </RadixDialog>
  )
}
