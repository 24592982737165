import type { ReactNode } from 'react'
import { BizBoundary } from '../components/BizBoundary'
import { cn } from '@jotta/utils/css'

export function BizWidget({
  title,
  children,
  bodyClassName,
}: {
  title: ReactNode
  children: React.ReactNode
  bodyClassName?: string
}) {
  return (
    <BizBoundary>
      <div className="card card-outline p-0">
        <h2 className="p-6 pb-3 text-xl font-medium">{title}</h2>
        <div className={cn('card-body px-6 pb-6', bodyClassName)}>
          {children}
        </div>
      </div>
    </BizBoundary>
  )
}
