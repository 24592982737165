// #region Imports
import Debug from 'debug'
import type { ReactNode } from 'react'
import { Suspense, lazy } from 'react'

import { WithAuth } from '@jotta/auth-client/WithAuth'
import { LegacyPublicShareRoute, RecentFilesRoute } from '@jotta/files-public'
import { FileSearchRoute } from '@jotta/files/FileSearchRoute'
import {
  acceptFolderInviteRoute,
  autoAcceptFolderInviteRoute,
} from '@jotta/files/FolderInviteRoute'
import {
  PrivatePhotoRoute,
  SharedPhotosRoute,
  SmartPhotoSearchBetaLandingPageRoute,
} from '@jotta/photos/routes'
import { createRouter } from '@jotta/router'
import { excludesFalse } from '@jotta/types/TypeUtils'
import { AppLayoutSignup } from '@jotta/ui/AppLayoutSignup'
import { ErrorElement } from '@jotta/ui/ErrorElement'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { redirectToLogout } from '@jotta/utils/auth'

import { useBrandCode } from '@jotta/ui/useBrandTheme'
import { AuthTransportProvider } from '@jotta/grpc-connect-client/provider'
import { ErrorPage } from '@jotta/ui/ErrorPage'
import { sentryCreateBrowserRouter } from '@jotta/utils/initializeSentry'
import { BusinessSignup } from '../auth/BusinessSignup'
import { DeviceLoginRoute } from '../auth/DeviceLoginRoute'
import { SetPasswordRoute } from './password/SetPasswordRoute'
import { LoginRoute } from '../auth/LoginRoute'
import { SignupAccepted } from '../auth/SignupAccepted'
import { SignupRoute } from '../auth/SignupRoute'
import { VoucherSignup } from '../auth/VoucherSignup'
import { bizRoutes } from '../biz/BizRoutes'
import { WebappLayout } from '../components/Layout/WebappLayout'
import { WebappLayoutPublic } from '../components/Layout/WebappLayoutPublic'
import { UpgradeRoute } from '../upgrade/UpgradeRoute'
import { DownloadPageRoute } from './DownloadPageRoute'
import { Root } from './Root'
import { FamilyInviteRoute } from './familyinvite/FamilyInviteRoute'
import { FolderInviteRoute } from './folderinvite/FolderInviteRoute'
import { PaymentStatusRoute } from './payment/PaymentStatusRoute'
import { PublicPhotoRouteContainer } from './photo/PublicPhotoRouteContainer'
import { AppsRoute } from './public/AppsRoute'
import { contentRefRoute } from './public/ContentRefRoute'
import { HomeRoute } from './public/HomeRoute'
import { appsRouteLoader } from './public/appsRouteLoader'
import { rootLoader } from './rootLoader'
import { webFileGotoRoute } from './web/WebFileGotoRoute'
import { UpgradeAccepted } from '../upgrade/UpgradeAccepted'
import { AfterTerminate } from '../auth/AfterTerminate'
import { ConfirmUpgradeRoute } from '../upgrade/ConfirmUpgradeRoute'

// #endregion Imports
const debug = Debug('jotta:webapp:RootRoutes')

const PublicShareRoute = lazy(
  () => import(/* webpackChunkName: "PublicFiles" */ '@jotta/files-public'),
)
const DevRoutes = lazy(
  () => import(/* webpackChunkName: "DevRoutes" */ './dev/DevRoutes'),
)
const SettingsPage = lazy(
  () => import(/* webpackChunkName: "Settings" */ '@jotta/settings'),
)

function JottaOnly({ children }: { children: ReactNode }) {
  const brandCode = useBrandCode()
  if (!brandCode) {
    return <LoadingOverlay />
  } else if (brandCode === 'JOTTACLOUD') {
    return children
  } else {
    return <ErrorPage notFound />
  }
}

export const router = createRouter(sentryCreateBrowserRouter, [
  // #region Contentref redirects
  contentRefRoute,
  // #endregion Contentref redirects
  {
    path: '/',
    id: 'root',
    loader: rootLoader,
    element: <Root />,
    errorElement: (
      <ErrorElement
        captureContext={scope => scope.setTag('section', 'rootRoute')}
      />
    ),
    children: [
      // #region Misc routes
      {
        index: true,
        id: 'home',
        element: <HomeRoute />,
      },
      {
        path: 'download',
        id: 'download',
        element: <DownloadPageRoute />,
      },
      {
        path: 'signup/:productCode?',
        id: 'signup',
        element: <SignupRoute />,
      },
      {
        path: 'login',
        id: 'login',
        element: <LoginRoute />,
      },
      {
        path: '2gs',
        id: '2gs',
        element: (
          <JottaOnly>
            <VoucherSignup customerGroupOverride="2GS" />
          </JottaOnly>
        ),
      },
      {
        path: 'elkjop-business',
        id: 'elkjop-business',
        element: (
          <JottaOnly>
            <BusinessSignup customerGroupOverride="ELKJOP_B2B" />
          </JottaOnly>
        ),
      },
      {
        path: 'elgiganten-se-business',
        id: 'elgiganten-se-business',
        element: (
          <JottaOnly>
            <BusinessSignup customerGroupOverride="ELGIGANTEN_S_B2B" />
          </JottaOnly>
        ),
      },
      {
        path: 'elgiganten-dk-business',
        id: 'elgiganten-dk-business',
        element: (
          <JottaOnly>
            <BusinessSignup customerGroupOverride="ELGIGANTEN_D_B2B" />
          </JottaOnly>
        ),
      },
      {
        path: 'gigantti-business',
        id: 'gigantti-business',
        element: (
          <JottaOnly>
            <BusinessSignup customerGroupOverride="GIGANTTI_B2B" />
          </JottaOnly>
        ),
      },
      {
        path: 'appletv',
        id: 'appletv',
        element: <DeviceLoginRoute />,
      },
      {
        path: '/web/appletv',
        id: '/web/appletv',
        element: <DeviceLoginRoute />,
      },
      {
        path: '/ai-powered-photo-search',
        element: (
          <WithAuth>
            <AuthTransportProvider>
              <SmartPhotoSearchBetaLandingPageRoute />
            </AuthTransportProvider>
          </WithAuth>
        ),
      },
      {
        path: '/account/upgrade',
        id: '/account/upgrade',
        element: <UpgradeRoute />,
      },
      {
        id: 'apps',
        path: '/apps',
        loader: appsRouteLoader,
        element: <AppsRoute />,
      },
      {
        path: '/p/:username/:shareId/*',
        element: <LegacyPublicShareRoute />,
      },
      {
        path: '/p/:username/:shareId/:action',
        element: <LegacyPublicShareRoute />,
      },
      {
        path: '/web/payment',
        element: <AppLayoutSignup />,
        errorElement: (
          <ErrorElement
            captureContext={scope => scope.setTag('key', 'payment')}
          />
        ),
        children: [
          {
            path: 'status/:flowId',
            element: <PaymentStatusRoute />,
          },
        ],
      },
      {
        path: '/f/:inviteCode',
        element: <FamilyInviteRoute />,
      },
      {
        path: '/familyinvite/:inviteCode',
        element: <FamilyInviteRoute />,
      },
      {
        path: '/web/signupAccepted/:productCode?/:interval?',
        element: <SignupAccepted />,
      },
      {
        path: '/web/upgradeAccepted/:productCode?/:interval?',
        element: <UpgradeAccepted />,
      },
      {
        path: '/folderInvite/:inviteCode',
        element: <FolderInviteRoute />,
      },
      {
        path: '/web/logout',
        loader: async () => {
          redirectToLogout(`${window.location.origin}/web/sync`)
          return null
        },
        element: <LoadingOverlay open />,
      },
      {
        path: '/web/reset/:resetKey',
        element: <SetPasswordRoute type="reset" />,
      },
      {
        path: '/start/:resetKey',
        element: <SetPasswordRoute />,
      },
      {
        path: '/web/afterTerminate',
        element: <AfterTerminate />,
      },
      // #endregion Misc routes
      // #region Biz
      bizRoutes,
      // #endregion Biz
      // #region Settings
      {
        path: 'web',
        element: (
          <WithAuth>
            <AuthTransportProvider>
              <WebappLayout section="settings" />
            </AuthTransportProvider>
          </WithAuth>
        ),
        children: [
          {
            path: 'account',
            element: (
              <Suspense fallback={<LoadingOverlay open position="absolute" />}>
                <SettingsPage routeContext="account" />
              </Suspense>
            ),
          },
          {
            path: 'secure',
            element: (
              <Suspense fallback={<LoadingOverlay open position="absolute" />}>
                <SettingsPage routeContext="secure" />
              </Suspense>
            ),
          },
          {
            path: 'billing',
            element: (
              <Suspense fallback={<LoadingOverlay open position="absolute" />}>
                <SettingsPage routeContext="billing" />
              </Suspense>
            ),
          },
          {
            path: 'connected',
            element: (
              <Suspense fallback={<LoadingOverlay open position="absolute" />}>
                <SettingsPage routeContext="connected" />
              </Suspense>
            ),
          },
          {
            path: 'family',
            element: (
              <Suspense fallback={<LoadingOverlay open position="absolute" />}>
                <SettingsPage routeContext="family" />
              </Suspense>
            ),
          },
        ],
      },
      // #endregion Settings
      // #region Authenticated web routes
      {
        path: 'web/upgrade/:productCode',
        element: (
          <WithAuth>
            <AuthTransportProvider>
              <ConfirmUpgradeRoute />
            </AuthTransportProvider>
          </WithAuth>
        ),
      },
      {
        path: 'web',
        element: (
          <WithAuth>
            <AuthTransportProvider>
              <WebappLayout section="web" />
            </AuthTransportProvider>
          </WithAuth>
        ),
        children: [
          {
            id: 'webUpgrade',
            path: 'upgrade',
            element: <UpgradeRoute />,
          },
          {
            id: 'webConvertTerm',
            path: 'convertTerm',
            element: <UpgradeRoute />,
          },
          {
            id: 'webExpire',
            path: 'expire',
            element: <UpgradeRoute />,
          },
          {
            id: 'webRenew',
            path: 'renew',
            element: <UpgradeRoute />,
          },
          {
            id: 'webRecentFiles',
            path: 'recentFiles',
            element: <RecentFilesRoute />,
          },
          webFileGotoRoute,
        ],
      },

      // #endregion Authenticated web routes
      // #region File routes
      // Definition lazy loaded from libraries/files/src/FileRoutes.tsx
      {
        path: 'web/*',
        id: 'files',
        element: (
          <WithAuth>
            <AuthTransportProvider>
              <WebappLayout section="web" />
            </AuthTransportProvider>
          </WithAuth>
        ),
        errorElement: (
          <ErrorElement
            captureContext={scope => scope.setTag('key', 'fileroot')}
          />
        ),
        children: [
          autoAcceptFolderInviteRoute,
          acceptFolderInviteRoute,
          {
            id: 'filelayout',
            lazy: () =>
              import(
                /* webpackChunkName: "filelayout" */ '@jotta/files/FileLayout'
              ),
            children: [
              {
                id: 'search',
                path: 'search/:viewMode/:sortBy/:query/*',
                element: <FileSearchRoute />,
              },
              {
                id: 'fileroute',
                path: ':context?/:viewMode?/:sortBy?/*?',
                errorElement: (
                  <ErrorElement
                    captureContext={scope => scope.setTag('key', 'fileroute')}
                  />
                ),
                lazy: () =>
                  import(
                    /* webpackChunkName: "files" */ '@jotta/files/FileRoute'
                  ),
              },
            ],
          },
        ],
      },
      // #endregion File routes
      // #region Public shared files
      {
        id: 'sharedfiles',
        path: 's/*',
        element: (
          <WebappLayoutPublic section="sharedfiles">
            <PublicShareRoute />
          </WebappLayoutPublic>
        ),
      },
      // #endregion Public shared files
      // #region All photo routes
      {
        element: <PublicPhotoRouteContainer />,
        children: [SharedPhotosRoute],
      },
      {
        element: <WebappLayout section="photo" />,
        children: [PrivatePhotoRoute],
      },
      // #endregion All photo routes
      // #region Dev and test routes
      process.env.NODE_ENV === 'development' && {
        path: 'dev/*',
        element: (
          <Suspense fallback={<LoadingOverlay open />}>
            <DevRoutes />
          </Suspense>
        ),
      },
      // #endregion Dev and test routes
    ].filter(excludesFalse),
  },
])
// ;(window as any).router = router
