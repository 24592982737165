import { cn } from '@jotta/utils/css'
import type { ReactNode } from 'react'
import type { TextFieldProps } from 'react-aria-components'
import { Input, Text, TextField } from 'react-aria-components'
import { ReactAriaFieldError } from '../ReactAriaFieldError/ReactAriaFieldError'
import type { ReactAriaValidationError } from '../ReactAriaTypes'
import { ReactAriaLabel } from '../ReactAriaLabel/ReactAriaLabel'

interface ReactAriaTextFieldProps
  extends Omit<TextFieldProps, 'children' | 'className'> {
  className?: string
  label?: string
  placeholder?: string
  description?: ReactNode
  errorMessage?: ReactAriaValidationError
}

export function ReactAriaTextField({
  className,
  label,
  description,
  errorMessage,
  placeholder,
  ...props
}: ReactAriaTextFieldProps) {
  return (
    <TextField className={cn('form-control', className)} {...props}>
      <ReactAriaLabel isRequired={props.isRequired}>{label}</ReactAriaLabel>
      <Input className="input" placeholder={placeholder} />
      {description && <Text slot="description">{description}</Text>}
      <ReactAriaFieldError error={errorMessage} />
    </TextField>
  )
}
