import { useBrandName } from '@jotta/grpc-connect-client/config'
import {
  useAcceptFamilyInvite,
  useGetCustomer,
} from '@jotta/grpc-connect-client/customer'
import { useFamilyInvite } from '@jotta/grpc-connect-client/invite'
import type { Customer } from '@jotta/grpc-connect-openapi/esm/openapi/customer/customer.v2_pb'
import type {
  GetFamilyInviteResponse,
  GetFamilyInviteResponse_Invite,
} from '@jotta/grpc-connect-openapi/esm/openapi/invite/v1/invite.v1_pb'
import { AppLayoutSignup } from '@jotta/ui/AppLayoutSignup'
import { BaseErrorPage } from '@jotta/ui/BaseErrorPage'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { exhaustiveGuard } from '@jotta/utils/exhaustive'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CTAButton } from '../../auth/CTAButton'
import { CreateUser } from '../../auth/CreateUser'
import { t, Trans } from '@lingui/macro'

function FamilyInviteExistingAccount({
  invite,
  customer,
  inviteCode,
}: {
  invite: GetFamilyInviteResponse_Invite
  customer: Customer
  inviteCode: string
}) {
  const familyName = invite.familyName
  const email = customer.email
  const loginUrl = invite.redirectUrl || '/api/login'
  const brandName = useBrandName()
  const acceptInviteMutation = useAcceptFamilyInvite()
  const navigate = useNavigate()
  const acceptInvite = useCallback(() => {
    acceptInviteMutation.mutate(
      { inviteCode },
      {
        onSuccess: () => {
          navigate('/web')
        },
      },
    )
  }, [acceptInviteMutation, inviteCode, navigate])

  return (
    <>
      <h1 className="text-2xl">
        <Trans>
          Join the <strong>{familyName} family</strong> on {brandName} with{' '}
          <strong>{email}</strong>
        </Trans>
      </h1>

      <div className="mt-6 flex flex-col gap-4 rounded bg-white p-6">
        <CTAButton
          onClick={acceptInvite}
          loading={
            acceptInviteMutation.isPending || acceptInviteMutation.isSuccess
          }
        >
          <Trans>Continue</Trans>
        </CTAButton>
        <p>
          <Trans>Join with a different account?</Trans>&nbsp;
          <a href={loginUrl} className="!text-signup-link underline">
            <Trans>Log in</Trans>
          </a>
        </p>
      </div>
    </>
  )
}

function FamilyInviteNewAccount({
  invite,
  brandName,
}: {
  invite: GetFamilyInviteResponse_Invite
  brandName: string
}) {
  const {
    familyName,
    inviteeEmail: email,
    inviteeName: name,
    redirectUrl,
  } = invite

  if (redirectUrl) {
    return (
      <div className="card flex flex-col gap-4">
        <h1 className="text-2xl">
          <Trans>
            Are you ready to join <strong>the {familyName} family</strong> on{' '}
            {brandName}?
          </Trans>
        </h1>
        <a className="btn btn-primary" href={redirectUrl}>
          <Trans>Continue</Trans>
        </a>
      </div>
    )
  }

  return (
    <>
      <h1 className="text-2xl">
        <Trans>
          Create a new account to join <strong>the {familyName} family</strong>{' '}
          on {brandName}
        </Trans>
      </h1>
      <div className="mt-6 rounded bg-white p-6">
        <CreateUser
          type="private"
          className="block"
          prefill={{ name, email }}
          federationIntent={{ case: 'signup', value: {} }}
          keepRouteOnSuccess
        />
      </div>
    </>
  )
}

export function FamilyInviteSwitch({
  response,
  customer,
  inviteCode,
  brandName,
}: {
  response: GetFamilyInviteResponse
  customer?: Customer
  inviteCode: string
  brandName: string
}) {
  const { result } = response

  switch (result.case) {
    case 'accepted':
      return (
        <BaseErrorPage title={t`The invitation has already been used`}>
          <Trans>
            If you already accepted this invitation with a different user,{' '}
            <a href="/web/logout">log in</a> to switch accounts. Else contact
            the person who sent you this invite and ask if he/she can send a new
            one.
          </Trans>
        </BaseErrorPage>
      )

    case 'cancelled':
      return (
        <BaseErrorPage title={t`The invitation has been cancelled`}>
          <Trans>This invitation has been canceled by the family owner.</Trans>
        </BaseErrorPage>
      )

    case 'notFound':
      return (
        <BaseErrorPage title={t`Invitation not found`}>
          <p>
            <Trans>
              Please check the email with the invitation and try to click on the
              link again.
            </Trans>
          </p>
          <p>
            <a href="/">
              <Trans>Click here to go back to the homepage.</Trans>
            </a>
          </p>
        </BaseErrorPage>
      )

    case 'invite':
      return (
        <div className="mx-auto w-[455px]">
          {customer ? (
            <FamilyInviteExistingAccount
              invite={result.value}
              customer={customer}
              inviteCode={inviteCode}
            />
          ) : (
            <FamilyInviteNewAccount
              invite={result.value}
              brandName={brandName}
            />
          )}
        </div>
      )

    case undefined:
      throw new Error('Invalid response')

    default:
      return exhaustiveGuard(result)
  }
}

export const FamilyInviteRoute = observer(function FamilyInviteRoute() {
  const { inviteCode = '' } = useParams()
  const { data: inviteData } = useFamilyInvite(inviteCode)
  const { data: customerData, isLoading: isCustomerLoading } = useGetCustomer()
  const brandName = useBrandName()

  if (!inviteData || isCustomerLoading || !brandName) {
    return <LoadingOverlay open />
  }

  return (
    <AppLayoutSignup>
      <FamilyInviteSwitch
        inviteCode={inviteCode}
        response={inviteData}
        customer={customerData?.customer}
        brandName={brandName}
      />
    </AppLayoutSignup>
  )
})
