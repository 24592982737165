import { cn } from '@jotta/utils/css'
import { observer } from 'mobx-react-lite'
import type { ReactNode } from 'react'
import { Fragment } from 'react'
import { crush, isArray, isObject, listify } from 'radash'
import { fromEntries, mergeAll } from 'remeda'

type DefinitionListProps = {
  className?: string
  children?: ReactNode
  plain?: boolean
  data?: Record<string, string>
}

export function DefinitionList({
  children,
  className,
  plain,
  data,
  ...props
}: DefinitionListProps) {
  return (
    <dl
      className={cn('dl', className, {
        'dl-plain': plain,
      })}
      {...props}
    >
      {data
        ? Object.entries(data).map(([term, description]) => (
            <Fragment key={term}>
              <dt>{term}</dt>
              <dd>{description}</dd>
            </Fragment>
          ))
        : children}
    </dl>
  )
}

type DefinitionListFromObjectProps = Omit<
  DefinitionListProps,
  'data' | 'plain'
> & {
  value?: unknown
}

export const DefinitionListFromObject = observer<DefinitionListFromObjectProps>(
  function DefinitionListFromObject({ value, ...props }) {
    if (!value) {
      return null
    }
    const data = toFlatObject(value)
    return <DefinitionList plain {...props} data={data} />
  },
)

function toFlatObject(value: unknown, sortKeys = false) {
  let arr: [string, string][] = []
  if (isArray(value)) {
    arr = listify(crush(mergeAll(value)), (k, v) => [k, String(v)])
  } else if (isObject(value)) {
    arr = listify(crush(value), (k, v) => [k, String(v)])
  } else {
    arr = [['Value', String(value)]]
  }
  if (sortKeys) {
    arr.sort(([a], [b]) => a.localeCompare(b))
  }
  return fromEntries(arr)
}
