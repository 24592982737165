import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { downloadItem } from '@jotta/grpc-js-client/fileService'
import {
  isWopiDocument,
  isWopiPresentation,
  isWopiSpreadsheet,
} from '@jotta/grpc-js-client/wopiService'
import type { BrandThemeIcon } from '@jotta/types/Brand'
import { Trans, t } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { FileContextMenu } from '../FileContextMenu/FileContextMenu'
import type { PathItemStore } from '../PathItem/PathItemStore'

export interface FileViewerMenuProps {
  file: PathItemStore
}
const wopiConfig = {
  Excel: {
    icon: 'SvgThumbXlsx',
    app: 'Microsoft Excel',
    type: 'excel',
  },
  Word: {
    icon: 'SvgThumbDocx',
    app: 'Microsoft Word',
    type: 'word',
  },
  Powerpoint: {
    icon: 'SvgThumbPptx',
    app: 'Microsoft PowerPoint',
    type: 'powerpoint',
  },
} as const satisfies Record<
  string,
  {
    icon: BrandThemeIcon
    app: string
    type: string
  }
>

export const FileViewerMenu = observer<FileViewerMenuProps>(
  function FileViewerMenu({ file }) {
    const { contextMenuActions, onContextMenuSelect, path } = file.listItemData

    const handleDownloadItem = useCallback(() => {
      downloadItem({ path: file.path })
    }, [file])

    const handleOpenDocument = useCallback(
      (type: string) => {
        if (file && type) {
          const url = new URL(window.location.href)
          url.searchParams.set('action', 'edit')
          window.location.href = url.toString()
        }
      },
      [file],
    )
    const wopiApp = isWopiDocument(file.name)
      ? wopiConfig.Word
      : isWopiPresentation(file.name)
        ? wopiConfig.Powerpoint
        : isWopiSpreadsheet(file.name)
          ? wopiConfig.Excel
          : undefined

    const app = wopiApp ? wopiApp.app : 'unknown'

    const handleContextMenuSelect = useCallback(
      (e: Event) => {
        onContextMenuSelect(e)
      },
      [onContextMenuSelect],
    )

    return (
      <>
        {contextMenuActions.length ? (
          <div className="grid text-sx-primary-text lg:grid-cols-fr1-max lg:divide-x lg:divide-sx-primary-text lg:rounded-sm lg:bg-[#6053BD]">
            {wopiApp ? (
              <button
                className="hidden items-center gap-2 pl-2 pr-3 lg:flex"
                aria-label={t`Edit options`}
                onClick={() => handleOpenDocument(wopiApp.type)}
              >
                <PlainBrandIcon icon={wopiApp.icon} className="size-6" />
                <Trans>Open in {app}</Trans>
              </button>
            ) : (
              <button
                className="hidden px-4 lg:block"
                aria-label={t`Edit options`}
                onClick={handleDownloadItem}
              >
                <Trans>Download</Trans>
              </button>
            )}

            <div className="size-full">
              <FileContextMenu
                path={path}
                context="FOLDER_LIST"
                onSelect={handleContextMenuSelect}
                actions={contextMenuActions}
                type="DROPDOWN"
              >
                <PlainBrandIcon
                  icon="SvgGearWhite"
                  className="scale-150 fill-white opacity-100 lg:!hidden"
                />
                <PlainBrandIcon
                  icon="SvgSmallArrowDown"
                  className="!hidden fill-white opacity-100 lg:!inline-block"
                />
              </FileContextMenu>
            </div>
          </div>
        ) : null}
      </>
    )
  },
)
