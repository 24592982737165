import { t, Trans } from '@lingui/macro'
import { BizWidget } from '../widgets/BizWidget'
import { Link } from 'react-router-dom'
import CartIcon from '../widgets/cart.svg'
import fileSize from 'filesize'
import type { GetPaymentInfoResponse } from '@jotta/grpc-connect-openapi/esm/openapi/subscription/payment.v2_pb'

export function BizSubscriptionForm({
  subscription,
  maxUserCount,
  userCount,
}: {
  subscription: GetPaymentInfoResponse
  maxUserCount: number
  userCount: number
}) {
  return (
    <BizWidget title={t`Subscription`}>
      <dl className="dl">
        <dt>
          <Trans>Account Type</Trans>
        </dt>
        <dd>{subscription.subscriptionNameLocalized}</dd>
        <dt>
          <Trans>Total storage</Trans>
        </dt>
        <dd>
          {fileSize(Number(subscription.subscriptionQuotaBytes))} (
          {fileSize(Number(subscription.subscriptionUsageBytes))} {t`used`})
        </dd>
        <dt>
          <Trans>Users</Trans>
        </dt>
        <dd>
          {maxUserCount < 0 ? (
            <Trans>Unlimited user seats ({userCount} in use)</Trans>
          ) : (
            <Trans>
              {userCount} of {maxUserCount} user seats occupied
            </Trans>
          )}
        </dd>
      </dl>
      <Link to="/web/upgrade" className="btn btn-outline mt-6">
        <CartIcon />
        <Trans>Change subscription</Trans>
      </Link>
    </BizWidget>
  )
}
