import { Headline1 } from '@jotta/ui/Headline'
import { useEffect } from 'react'
import { useMutation } from '@connectrpc/connect-query'
import { federatedLogin as federatedLoginDescriptor } from '@jotta/grpc-connect-openapi/esm/openapi/login/v1/login.v1-LoginService_connectquery'
import { Federation } from './Federation'
import { LoadingOverlaySpinner } from '@jotta/ui/LoadingOverlay'
import { Card } from '@jotta/ui/Card'
import { useForm } from '@jotta/hooks'
import { Form } from '@jotta/ui/Form'
import { FormField } from '@jotta/ui/FormField'
import { PasswordInput } from '@jotta/ui/PasswordInput'
import { TextInput } from '@jotta/ui/TextInput'
import { CTAButton } from './CTAButton'
import { ErrorMessage } from '@jotta/ui/ErrorMessage'
import { usePasswordField, useEmailField } from './signupForm'
import { useGetConfig } from '@jotta/grpc-connect-client/config'
import { t, Trans } from '@lingui/macro'

export function LoginPage({ token }: { token?: string; loginId: string }) {
  const {
    data: { brandingInfo, viewConfig },
  } = useGetConfig()

  const { mutate: federatedLogin, error } = useMutation(
    federatedLoginDescriptor,
    { throwOnError: false },
  )

  const customerGroupCode = brandingInfo?.customerGroupCode
  const form = useForm({
    fields: {
      email: useEmailField(customerGroupCode || ''),
      password: usePasswordField(),
    },
    submit: () => {
      // TODO login here
      federatedLogin({})
    },
  })

  const { email, password } = form

  useEffect(() => {
    if (token && !error) {
      federatedLogin({
        token,
      })
    }
  }, [token])

  if (!customerGroupCode || (token && !error)) {
    return <LoadingOverlaySpinner />
  }

  return (
    <Card className="bg-white">
      <Form form={form} className="flex flex-col gap-4">
        <Headline1>
          <Trans>Log In</Trans>
        </Headline1>

        {viewConfig?.showLinkedAccounts && (
          <Federation
            intent={{
              case: 'login',
              value: {},
            }}
          />
        )}

        <ErrorMessage error={error} />

        <FormField field={email} label={t`Email`}>
          <TextInput {...email.inputProps} />
        </FormField>

        <FormField field={password} label={t`Password`}>
          <PasswordInput {...password.inputProps} />
        </FormField>

        <CTAButton type="submit">
          <Trans>Login</Trans>
        </CTAButton>

        <p>
          <Trans>Don't have an account?</Trans>
          &nbsp;
          <a href="/signup" className="!text-signup-link underline">
            <Trans>Sign up now</Trans>
          </a>
        </p>
      </Form>
    </Card>
  )
}
