import { toPlainMessage, type PlainMessage } from '@bufbuild/protobuf'
import { Code, ConnectError, createRouterTransport } from '@connectrpc/connect'
import { callUnaryMethod, TransportProvider } from '@connectrpc/connect-query'
import {
  authInterceptor,
  authTransport,
  devToolsInterceptor,
  slowInterceptor,
} from '@jotta/grpc-connect-client/transport'
import {
  BusinessUser,
  ListUsersResponse,
  PatchBusinessResponse,
  PatchBusinessUserResponse,
  RemoveBusinessUserResponse,
  TerminateBusinessResponse,
} from '@jotta/grpc-connect-openapi/business'
import { BusinessService } from '@jotta/grpc-connect-openapi/businessService'
import { getConfig } from '@jotta/grpc-connect-openapi/configQuery'
import { ConfigService } from '@jotta/grpc-connect-openapi/configService'
import { Country } from '@jotta/grpc-connect-openapi/country'
import {
  AccountType,
  Business,
  Customer,
  GetCustomerResponse,
} from '@jotta/grpc-connect-openapi/customer'
import { CustomerService } from '@jotta/grpc-connect-openapi/customerService'
import { getPaymentInfo } from '@jotta/grpc-connect-openapi/esm/openapi/subscription/payment.v2-SubscriptionService_connectquery'
import { SubscriptionService } from '@jotta/grpc-connect-openapi/paymentService'
import { env } from '@jotta/utils/env'
import { shake } from 'radash'
import type { ReactNode } from 'react'
import { BizMockUsers } from './BizMockUsers'
import { bizTransport } from '../BizApi'
import { Challenge } from '@jotta/grpc-connect-openapi/esm/openapi/credentials_pb'
import {
  Currency,
  ListOrdersResponse,
  Order_OrderStatus,
  Order_OrderType,
} from '@jotta/grpc-connect-openapi/esm/openapi/subscription/payment.v2_pb'
import dayjs from 'dayjs'
export const BizMockTransport = createRouterTransport(
  ({ service }) => {
    let business: PlainMessage<Business> = {
      name: 'Jotta AS',
      orgNo: '123456789',
      country: Country.NORWAY,
      maxUserCount: 10n,
    }

    const customer = new Customer({
      fullName: 'Murvyn Plose',
      email: 'mplose0@ucoz.ru',
      username: 'x3ec5qoqvehyeo64digob9zu',
      accountType: AccountType.BUSINESS_ADMIN,
      avatar: {
        initials: 'MP',
        backgroundColor: {
          r: 255,
          g: 0,
          b: 230,
        },
      },
    })

    const users = new Map(
      BizMockUsers.map(user => [user.username, user] as const),
    )
    const state = {
      get business() {
        return new Business(business)
      },
      get customer() {
        return new Customer({
          ...customer,
          business: {
            ...business,
          },
        })
      },
    }

    service(SubscriptionService, {
      getPaymentInfo(req) {
        const response = callUnaryMethod(getPaymentInfo, req, {
          transport: authTransport,
        })
        return response
      },
      listOrders(req) {
        const response = new ListOrdersResponse({
          orders: [
            {
              orderNumber: '123456',
              type: Order_OrderType.BUSINESS_BASE,
              timestampMillis: BigInt(dayjs().subtract(1, 'day').valueOf()),
              status: Order_OrderStatus.PROCESSING,
              chargedCents: 10000n,
              currency: Currency.NOK,
            },
            {
              orderNumber: '223456',
              type: Order_OrderType.RENEWAL,
              timestampMillis: BigInt(dayjs().subtract(1, 'day').valueOf()),
              status: Order_OrderStatus.COMPLETED,
              chargedCents: 20000n,
              currency: Currency.USD,
            },
          ],
        })
        return response
      },
    })
    service(ConfigService, {
      getConfig(req) {
        const response = callUnaryMethod(getConfig, req, {
          transport: authTransport,
        })
        return response
      },
    })
    service(CustomerService, {
      getCustomer(req) {
        const response = new GetCustomerResponse({
          customer: { ...state.customer },
        })
        return response
      },
    })
    service(BusinessService, {
      listUsers(req) {
        const response = new ListUsersResponse({
          users: [...structuredClone(users).values()],
        })
        return response
      },
      patchBusinessUser(req) {
        const user = users.get(req.username)
        if (!user) {
          throw new ConnectError(
            `User ${req.username} not found`,
            Code.NotFound,
          )
        }
        users.set(
          req.username,
          new BusinessUser({
            ...user,
            ...shake(toPlainMessage(req)),
          }),
        )
        return new PatchBusinessUserResponse({})
      },
      terminateBusiness(req) {
        return new TerminateBusinessResponse({
          challenges: {
            acceptedChallenges: [],
            challenges: [Challenge.SMS_OTP],
          },
        })
      },
      removeBusinessUser(req) {
        if (users.has(req.username)) {
          users.delete(req.username)
          return new RemoveBusinessUserResponse({})
        }
        throw new ConnectError(`User ${req.username} not found`, Code.NotFound)
      },
      patchBusiness(req) {
        business = {
          ...business,
          ...shake(toPlainMessage(req)),
        }
        return new PatchBusinessResponse({
          business,
        })
      },
    })
  },
  {
    router: {
      grpcWeb: true,
    },
    transport: {
      baseUrl: env.grpcApi,
      defaultTimeoutMs: 10000,
      interceptors: [devToolsInterceptor, authInterceptor, slowInterceptor],
    },
  },
)
export function BizTransportProvider({ children }: { children: ReactNode }) {
  return (
    <TransportProvider transport={bizTransport}>{children}</TransportProvider>
  )
}
