import { WithAuth } from '@jotta/auth-client/WithAuth'
import { RouteBreadCrumbHandle } from '@jotta/hooks/useRouteBreadCrumb'
import { Trans } from '@lingui/macro'
import type { RouteObject } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { WebappLayout } from '../components/Layout/WebappLayout'
import { BizLayout } from './BizLayout'
import { BizDashboardRoute } from './routes/BizDashboardRoute'
import { BizSettingsRoute } from './routes/BizSettingsRoute'
import { BizUsersRoute } from './routes/BizUsersRoute'
import Debug from 'debug'
import { bizApi } from './BizApi'
import { queryClient } from '@jotta/query'
import { get } from 'radash'
import { BizTransportProvider } from './__mock__/BizMockTransport'
import { removeTrailingSlash } from '@jotta/utils/pathUtils'
import type { Business } from '@jotta/grpc-connect-openapi/esm/openapi/customer/customer.v2_pb'
import { BizUserCreateRoute } from './routes/BizUserCreateRoute'
import { BizUserEditRoute } from './routes/BizUserEditRoute'
import { BizBillingHistoryRoute } from './routes/BizBillingHistoryRoute'
import { BizOrderReceiptRoute } from './routes/BizOrderReceiptRoute'
const debug = Debug('jotta:biz:routes')

export const bizRoutes: RouteObject = {
  path: '/biz',
  element: (
    <WithAuth>
      <BizTransportProvider>
        <WebappLayout section="biz" />
      </BizTransportProvider>
    </WithAuth>
  ),
  children: [
    {
      element: <BizLayout />,
      handle: {
        crumb: new RouteBreadCrumbHandle<Business>(({ pathname }) => {
          return (
            <NavLink end to={removeTrailingSlash(pathname)}>
              <Trans>Dashboard</Trans>
            </NavLink>
          )
        }),
      },
      children: [
        {
          index: true,
          element: <BizDashboardRoute />,
        },
        {
          path: 'users',
          handle: {
            crumb: new RouteBreadCrumbHandle(() => (
              <NavLink end to="users">
                <Trans>Users</Trans>
              </NavLink>
            )),
          },
          children: [
            {
              index: true,
              element: <BizUsersRoute />,
            },
            {
              path: 'create',
              handle: {
                crumb: new RouteBreadCrumbHandle(({ pathname }) => {
                  return (
                    <NavLink end to={pathname}>
                      <Trans>Create new user</Trans>
                    </NavLink>
                  )
                }),
              },
              element: <BizUserCreateRoute />,
            },
            {
              path: ':username',
              loader: async ({ params }) => {
                const user = await queryClient.fetchQuery(
                  bizApi.user.byId.getFetchOptions({
                    username: params.username || '',
                  }),
                )
                return user
              },
              handle: {
                crumb: new RouteBreadCrumbHandle(
                  ({ pathname, params, data }) => {
                    const name = get(data, 'name', '')
                    return (
                      <NavLink end to={removeTrailingSlash(pathname)}>
                        {name}
                      </NavLink>
                    )
                  },
                ),
              },
              element: <BizUserEditRoute />,
            },
          ],
        },

        {
          path: 'settings',
          handle: {
            crumb: new RouteBreadCrumbHandle(({ pathname }) => {
              return (
                <NavLink end to={pathname}>
                  <Trans>Settings</Trans>
                </NavLink>
              )
            }),
          },
          children: [
            {
              index: true,
              element: <BizSettingsRoute />,
            },
            {
              path: 'billingHistory',
              handle: {
                crumb: new RouteBreadCrumbHandle(({ pathname }) => {
                  return (
                    <NavLink end to={pathname}>
                      <Trans>Billing history</Trans>
                    </NavLink>
                  )
                }),
              },
              children: [
                {
                  index: true,
                  element: <BizBillingHistoryRoute />,
                },
                {
                  path: ':orderNumber',
                  element: <BizOrderReceiptRoute />,
                  handle: {
                    crumb: new RouteBreadCrumbHandle(({ pathname, params }) => {
                      return (
                        <NavLink end to={pathname}>
                          {params.orderNumber}
                        </NavLink>
                      )
                    }),
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}
