import { BizWidgetAccountStats } from '../widgets/BizWidgetAccountStats'
import { BizWidgetLeastActiveUsers } from '../widgets/BizWidgetLeastActiveUsers'
import { BizWidgetUsersStorageLimit } from '../widgets/BizWidgetUsersStorageLimit'

export function BizDashboardRoute() {
  return (
    <div className="grid grid-cols-1 gap-3 md:grid-cols-2 3xl:grid-cols-4">
      <BizWidgetAccountStats />
      <BizWidgetUsersStorageLimit />
      <BizWidgetLeastActiveUsers />
    </div>
  )
}
