import { cn } from '@jotta/utils/css'
import type { ReactNode } from 'react'
import type { SliderProps } from 'react-aria-components'
import {
  Slider,
  SliderOutput,
  SliderThumb,
  SliderTrack,
  Text,
} from 'react-aria-components'
import { ReactAriaLabel } from '../ReactAriaLabel/ReactAriaLabel'

interface ReactAriaRangeSliderProps
  extends Omit<SliderProps, 'className' | 'children'> {
  label?: string
  description?: string
  className?: string
  isRequired?: boolean
  hideSliderOutput?: boolean
  children?: ReactNode

  errorMessage?: ReactNode
}

export function ReactAriaRangeSlider({
  label,
  description,
  children,
  className,
  isRequired = false,
  hideSliderOutput = false,
  errorMessage,
  ...props
}: ReactAriaRangeSliderProps) {
  return (
    <Slider className={cn('form-control', className)} {...props}>
      {label && <ReactAriaLabel>{label} </ReactAriaLabel>}
      {description && <Text slot="description">{description}</Text>}
      {children}
      {!hideSliderOutput && <SliderOutput className="text-body-sm-sh" />}
      <SliderTrack className="relative h-7 w-full">
        {({ state }) => (
          <>
            {/* track */}
            <div className="absolute top-[50%] h-2 w-full translate-y-[-50%] rounded-full bg-sx-primary/40" />
            {/* fill */}
            <div
              className="absolute top-[50%] h-2 translate-y-[-50%] rounded-full bg-sx-primary"
              style={{ width: `${state.getThumbPercent(0) * 100}%` }}
            />
            <SliderThumb className="top-[50%] h-7 w-7 rounded-full border border-solid border-purple-800/75 bg-white outline-none ring-black transition focus-visible:ring-2 dragging:bg-purple-100" />
          </>
        )}
      </SliderTrack>

      {errorMessage}
    </Slider>
  )
}
