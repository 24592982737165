import { Trans } from '@lingui/macro'
import { Link } from 'react-router-dom'
import { useBizUsers } from '../BizApi'

export function BizUserLimitAlert() {
  const { canCreateUser } = useBizUsers()
  if (canCreateUser) {
    return null
  }
  return (
    <div className="card card-warning card-outline">
      <div className="card-body">
        <Trans>User seat limit reached!</Trans>{' '}
        <Link className="link" to="/web/upgrade">
          <Trans>Change subscription</Trans>
        </Link>
      </div>
    </div>
  )
}
