import {
  AccountType,
  Customer,
  Family,
  GetFamilyResponse,
  InviteState,
  PhotoState,
  QuotaState,
  SubscriptionType,
} from '@jotta/grpc-web/no/jotta/openapi/customer/customer.v2_pb'
import { Language } from '@jotta/grpc-web/no/jotta/openapi/language_pb'
import { WopiConsent } from '@jotta/grpc-web/no/jotta/openapi/wopi_pb'
import { Country } from '@jotta/grpc-web/no/jotta/openapi/country_pb'

export const emptyCustomer: Readonly<Customer.AsObject> = {
  username: '',
  email: '',
  fullName: '',
  phoneNumber: '',
  phoneNumberVerified: false,
  countryCode: '',
  country: Country.UNKNOWN_COUNTRY,
  languageCode: '',
  language: Language.UNKNOWN_LANGUAGE,
  usageBytes: 0,
  quotaBytes: 0,
  photoState: PhotoState.UNKNOWN_PHOTO_STATE,
  accountType: AccountType.UNKNOWN_ACCOUNT_TYPE,
  subscriptionType: SubscriptionType.UNKNOWN_SUBSCRIPTION_TYPE,
  brandingInfo: {
    brandCode: '',
    brandName: '',
    customerGroupCode: '',
    customerGroupName: '',
  },
  wopiConsent: WopiConsent.WOPI_CONSENT_UNKNOWN,
  tfaType: Customer.TFAType.NONE,
  messagesList: [],
  promoteUpgrade: false,
  canTerminateAccount: false,
  quota: QuotaState.VALID,
}
export const mockCustomer: Readonly<Customer.AsObject> = {
  username: 'test',
  email: 'test@test.com',
  fullName: 'Test User',
  phoneNumber: '123456',
  phoneNumberVerified: true,
  countryCode: 'no',
  country: Country.NORWAY,
  languageCode: 'en',
  language: Language.ENGLISH,
  usageBytes: 0,
  quotaBytes: 0,
  photoState: PhotoState.NOT_ACTIVE,
  accountType: AccountType.CONSUMER,
  subscriptionType: SubscriptionType.FREE,
  brandingInfo: {
    brandCode: '',
    brandName: '',
    customerGroupCode: '',
    customerGroupName: '',
  },
  wopiConsent: WopiConsent.WOPI_CONSENT_ACCEPTED,
  tfaType: Customer.TFAType.NONE,
  messagesList: [],
  promoteUpgrade: false,
  canTerminateAccount: false,
  quota: QuotaState.VALID,
}
