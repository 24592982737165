import { SignupContainer } from './SignupContainer'
import { useForm } from '@jotta/hooks'
import { PhoneInput, validatePhoneNumber } from '@jotta/ui/PhoneInput'
import { useMutation as useTanstackMutation } from '@tanstack/react-query'
import { CheckPhoneNumberResponse } from '@jotta/grpc-connect-openapi/signup'
import { FormField } from '@jotta/ui/FormField'
import { exhaustiveGuard } from '@jotta/utils/exhaustive'
import { CreateUser } from './CreateUser'
import { Form } from '@jotta/ui/Form'
import { t, Trans } from '@lingui/macro'
import { PasswordInput } from '@jotta/ui/PasswordInput'
import { CTAButton } from './CTAButton'
import { phoneNumberLogin as phoneNumberLoginDescriptor } from '@jotta/grpc-connect-openapi/loginQuery'
import { useMutation } from '@connectrpc/connect-query'

function PhoneNumberLogin({ phoneNumber }: { phoneNumber: string }) {
  const { mutateAsync: phoneNumberLogin } = useMutation(
    phoneNumberLoginDescriptor,
    {
      throwOnError: false,
    },
  )

  const phoneNumberLoginForm = useForm({
    fields: {
      password: { type: 'text' },
    },
    submit: ({ password }) =>
      phoneNumberLogin({
        phoneNumber,
        credentials: {
          password,
        },
      }),
  })

  const {
    fields: { password },
  } = phoneNumberLoginForm

  return (
    <Form form={phoneNumberLoginForm}>
      <FormField field={password} label={t`Password`}>
        <PasswordInput {...password.inputProps} />
      </FormField>

      <CTAButton type="submit">
        <Trans>Login</Trans>
      </CTAButton>

      <p>
        <Trans>Don't have an account?</Trans>
        &nbsp;
        <a href="/signup" className="!text-signup-link underline">
          <Trans>Sign up now</Trans>
        </a>
      </p>
    </Form>
  )
}

function Switch({
  phoneNumber,
  checkPhoneNumberResponse,
}: {
  phoneNumber: string
  checkPhoneNumberResponse: CheckPhoneNumberResponse
}) {
  const result = checkPhoneNumberResponse.result
  switch (result.case) {
    case 'phoneNumberActive':
      return <PhoneNumberLogin phoneNumber={phoneNumber} />
    case 'phoneNumberInPreSignup':
      return (
        <CreateUser
          type={{
            case: 'presignup',
            phoneNumber: phoneNumber,
          }}
        />
      )
    case undefined:
    case 'phoneNumberInvalid':
      throw new Error('presignup failed')
    default:
      return exhaustiveGuard(result)
  }
}

export function PreSignup() {
  const { data: checkPhoneNumberResponse, mutateAsync: checkPhoneNumber } =
    useTanstackMutation<
      CheckPhoneNumberResponse,
      any,
      { phoneNumber?: string },
      any
    >({
      mutationFn: async variables => {
        console.log(variables)
        if (Math.random() < 0.5) {
          return new CheckPhoneNumberResponse({
            result: {
              case: 'phoneNumberInPreSignup',
              value: {},
            },
          })
        } else {
          return new CheckPhoneNumberResponse({
            result: {
              case: 'phoneNumberActive',
              value: {},
            },
          })
        }
      },
    })

  const phoneNumberForm = useForm({
    fields: {
      phoneNumber: {
        type: 'text',
        validate: validatePhoneNumber,
      },
    },
    submit: ({ phoneNumber }) =>
      checkPhoneNumber({
        phoneNumber,
      }),
  })

  const {
    onSubmit,
    fields: { phoneNumber },
  } = phoneNumberForm
  return (
    <SignupContainer title="PRE SIGNUP">
      <form className="flex" onSubmit={onSubmit}>
        <FormField field={phoneNumber}>
          <PhoneInput {...phoneNumber.inputProps} />
        </FormField>
        <button className="btn btn-primary">GO</button>
      </form>
      {checkPhoneNumberResponse && (
        <Switch
          phoneNumber={phoneNumber.value}
          checkPhoneNumberResponse={checkPhoneNumberResponse}
        />
      )}
    </SignupContainer>
  )
}
