import { useEffect, useState } from 'react'

/**
 * Returns a value that changes from `fter delayMs milliseconds has passed
 */
export function useDelay<T>({
  value,
  delayMs,
  initialValue = value,
}: {
  value: T
  delayMs: number
  initialValue?: T
}) {
  const [delayedValue, setValue] = useState(initialValue)
  useEffect(() => {
    function handler() {
      setValue(value)
    }
    const timeout = setTimeout(handler, delayMs)
    return () => clearTimeout(timeout)
  }, [value])
  return delayedValue
}
