import type { NumberFormat } from 'libphonenumber-js'
import { format, type ParsedNumber, parseNumber } from 'libphonenumber-js'

export function parsePhoneNumber(phoneNumber?: string) {
  if (phoneNumber && phoneNumber.startsWith('00')) {
    const parsedNumber = parseNumber(`+${phoneNumber.substring(2)}`, {
      extended: true,
    }) as ParsedNumber
    return parsedNumber?.valid ? parsedNumber : undefined
  }
}

export function formatPhoneNumber(
  phoneNumber?: string,
  numberFormat?: NumberFormat,
) {
  const parsedPhoneNumber = phoneNumber
    ? parsePhoneNumber(phoneNumber.trim())
    : undefined
  return parsedPhoneNumber
    ? format(parsedPhoneNumber, numberFormat || 'INTERNATIONAL')
    : phoneNumber
}
