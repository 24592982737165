import { t, Trans } from '@lingui/macro'
import { BizWidget } from '../widgets/BizWidget'
import { BizActionButtonTerminateAccount } from './BizActionButtonTerminateAccount'
import { useBizContext } from '../BizLayout'

export function BizTerminateAccountForm() {
  const { company } = useBizContext()
  const companyName = company.name

  return (
    <BizWidget title={t`Permanently delete account`}>
      <div className="prose prose-small pb-4">
        <p>
          {t`This will permanently delete all users accounts and the business account itself`}{' '}
          {companyName}.
        </p>
        <p>
          <Trans>
            All files and users associated with this accounts will be deleted
            permanently
          </Trans>
        </p>
        <p>
          <Trans>
            You will need to confirm your password to complete this operation.
          </Trans>
        </p>
      </div>
      <BizActionButtonTerminateAccount />
    </BizWidget>
  )
}
