import { emptyBrandingInfo } from '@jotta/grpc-js-client/configService'
import type { GetFamilyResponse } from '@jotta/grpc-js-client/customerService'
import { emptyCustomer } from '@jotta/grpc-js-client/mockCustomer'
import { InviteState } from '@jotta/grpc-web/no/jotta/openapi/customer/customer.v2_pb'
import { queryClient } from '@jotta/query'
import type { Settings } from '@jotta/types/Settings'
import { avatarFromProtobuf } from '@jotta/utils/avatar'
import { range } from '@jotta/utils/range'
import Debug from 'debug'
import { action, makeAutoObservable, observable } from 'mobx'
import type { SettingsStore } from './SettingsStore'

export class FamilySettingsStore {
  editInvite: Settings.Family.EditInviteType = {
    kind: 'closed',
    completed: false,
    inviteData: null,
    showTerms: false,
    termsAccepted: false,
  }
  log = Debug('jotta:settings:family:store')
  private _family = observable.box<GetFamilyResponse.AsObject | undefined>()

  constructor(public settings: SettingsStore) {
    makeAutoObservable(this)
  }

  get api() {
    return this.settings.api
  }

  get customer() {
    return this.settings.customer || emptyCustomer
  }
  get brandingInfo() {
    return this.settings.brandingInfo || emptyBrandingInfo
  }

  get familyResponse() {
    return (
      this._family.get() || {
        familyQuotaBytes: 0,
        invitesList: [],
        maxInvitesCount: 0,
        ownerUsageBytes: 0,
        totalUsageBytes: 0,
        family: {
          name: '',
        },
      }
    )
  }

  set familyResponse(res) {
    this._family.set(res)
  }

  get info(): Settings.Family.Info {
    return {
      brandName: this.brandingInfo.brandName,
      termsLink: this.brandingInfo.termsLink,
      familyName: String(this.familyResponse.family?.name),
      familyQuotaBytes: this.familyResponse.familyQuotaBytes,
      familyUsageBytes: this.familyResponse.totalUsageBytes,
      ownerFullName: this.customer.fullName,
      ownerAvatar: avatarFromProtobuf(this.customer.avatar),
      ownerUsageBytes: this.familyResponse.ownerUsageBytes,
      ownerCountry: this.customer.country,
    }
  }

  get isEditOpen() {
    return this.editInvite.kind !== 'closed'
  }

  get termsDialogIsOpen() {
    return this.isEditOpen && !this.editInvite.termsAccepted
  }

  get usedInvites() {
    return this.familyResponse.invitesList.map<Settings.Family.InviteData>(
      inv => {
        const state =
          inv.state === InviteState.INVITE_ACCEPTED ? 'accepted' : 'sent'

        const toAddress = inv.email || inv.sms

        const data: Settings.Family.InviteData = {
          state,
          name: String(inv.toUserFullname || toAddress).trim(),
          inviteCode: inv.inviteCode,
          inviteLink: inv.inviteLink,
          toAddress,
          toAvatar: avatarFromProtobuf(inv.toAvatar),
          fsUsageBytes: inv.fsUsageBytes,
          fsTotalBytes: this.info.familyQuotaBytes,
          userFullName: inv.toUserFullname,
          acceptedByUsername: inv.toUsername,
        }
        return data
      },
    )
  }

  get maxInvitesCount() {
    return this.familyResponse?.maxInvitesCount || 0
  }
  get usedInvitesCount() {
    return this.usedInvites.length
  }
  get availableInvitesCount() {
    return this.maxInvitesCount - this.usedInvitesCount
  }
  get availableInvites(): Settings.Family.InviteData[] {
    if (this.availableInvitesCount < 1) {
      return []
    }
    return range(0, this.availableInvitesCount).map<Settings.Family.InviteData>(
      (val, idx) => {
        const data: Settings.Family.InviteData = {
          name: '',
          state: 'available',
          inviteCode: `available-${idx}`,
          acceptedByUsername: '',
          inviteLink: '',
          toAddress: '',
          toAvatar: {
            initials: '',
            backgroundColor: '',
          },
          fsUsageBytes: 0,
          fsTotalBytes: this.info.familyQuotaBytes,
          userFullName: '',
        }
        return data
      },
    )
  }

  get invites() {
    return [...this.usedInvites, ...this.availableInvites]
  }
  actions: Settings.Family.Actions = {
    onInviteSuccess: action('onInviteSuccess', () => {
      if (this.isEditOpen) {
        this.editInvite.completed = true
      }
    }),
    closeInviteDialog: action('cancelEdit', () => {
      this.log('cancelEdit')
      this.editInvite = {
        kind: 'closed',
        showTerms: false,
        termsAccepted: false,
        completed: false,
        inviteData: null,
      }
    }),
    acceptInviteTerms: action('acceptInviteTerms', () => {
      if (this.isEditOpen) {
        this.log('Accept terms')
        this.editInvite.termsAccepted = true
      }
    }),
    openInviteEmailDialog: action('openInviteEmailDialog', inviteData => {
      if (!this.isEditOpen) {
        this.editInvite = {
          kind: 'email',
          showTerms: true,
          termsAccepted: false,
          completed: false,
          handlers: {
            onInviteSuccess: this.actions.onInviteSuccess,
            closeInviteDialog: this.actions.closeInviteDialog,
            onSubmitInviteEmail: this.actions.onSubmitInviteEmail,
            acceptInviteTerms: this.actions.acceptInviteTerms,
          },
          inviteData,
        }
      }
    }),
    openInviteSMSDialog: action('openInviteSMSDialog', inviteData => {
      if (!this.isEditOpen) {
        this.editInvite = {
          kind: 'sms',
          showTerms: true,
          termsAccepted: false,
          completed: false,
          country: this.info.ownerCountry,
          handlers: {
            onInviteSuccess: this.actions.onInviteSuccess,
            closeInviteDialog: this.actions.closeInviteDialog,
            onSubmitInviteSMS: this.actions.onSubmitInviteSMS,
            acceptInviteTerms: this.actions.acceptInviteTerms,
          },
          inviteData,
        }
      }
    }),
    openRevokeInviteDialog: action('openRevokeInviteDialog', inviteData => {
      if (!this.isEditOpen) {
        this.editInvite = {
          kind: 'revoke',
          showTerms: false,
          termsAccepted: false,
          completed: false,
          handlers: {
            closeInviteDialog: this.actions.closeInviteDialog,
            onSubmitRevokeInvite: this.actions.onSubmitRevokeInvite,
          },
          inviteData,
        }
      }
    }),
    onSubmitInviteEmail: action('onSubmitInviteEmail', async data => {
      await this.settings.api.inviteMemberEmail(data)
      queryClient.invalidateQueries({ queryKey: ['family'] })
    }),

    onSubmitInviteSMS: action('onSubmitInviteSMS', async data => {
      await this.settings.api.inviteMemberSMS(data)
      queryClient.invalidateQueries({ queryKey: ['family'] })
    }),
    onSubmitRevokeInvite: action('onSubmitRevokeInvite', async data => {
      await this.settings.api.revokeInvite(data)
      queryClient.invalidateQueries({ queryKey: ['family'] })
    }),
  }
}
