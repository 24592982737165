import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { t } from '@lingui/macro'
import { Link } from 'react-router-dom'
import type { ExtendedBusinessUser } from '../BizApi'

export function BizActionButtonEditUser({
  user,
}: {
  user: Pick<ExtendedBusinessUser, 'username' | 'name'>
}) {
  return (
    <Link
      to={`${user.username}`}
      className="btn btn-outline px-2"
      title={`${t`Modify user`} ${user.name}`}
    >
      <PlainBrandIcon icon="SvgEdit" />
    </Link>
  )
}
