import type { FieldMeta } from '@tanstack/react-form'

export function DisplayFieldErrors({ meta }: { meta: FieldMeta }) {
  if (!meta.errors.length) {
    return null
  }
  return (
    <div className="form-control-error">
      {meta.errors.map(err => (
        <div key={err?.toString()}>{err}</div>
      ))}
    </div>
  )
}
