import type { PropsWithChildren } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

interface TabContext {
  insertTab: (tab: string, initial?: boolean) => () => void
  selected: string
}

const TabContext = createContext<TabContext>({
  insertTab: () => () => {},
  selected: '',
})

export function TabContent({
  name,
  initial = false,
  children,
}: PropsWithChildren<{ name: string; initial?: boolean }>) {
  const { selected, insertTab } = useContext(TabContext)

  useEffect(() => {
    const remove = insertTab(name, initial)

    return remove
  }, [name, insertTab])

  if (selected === name) {
    return <>{children}</>
  }

  return null
}

export function UpgradeTabSwitch({
  value = '',
  children,
}: PropsWithChildren<{ onChange?: (tab: string) => void; value?: string }>) {
  const [tabs, setTabs] = useState<string[]>([])
  const [selected, setSelected] = useState(value)

  const insertTab = useCallback((tab: string, initial = false) => {
    setTabs(tabs => {
      if (tabs.includes(tab)) {
        console.warn(`Duplicate tab "${tab}"`)
        return [...tabs]
      }
      return [...tabs, tab]
    })

    if (initial) {
      setSelected(tab)
    }

    return () => {
      setTabs(tabs => {
        const i = tabs.indexOf(tab)
        if (i < 0) {
          return tabs
        }

        return tabs.slice().splice(i, 1)
      })
    }
  }, [])

  useEffect(() => {
    if (tabs.length && !selected) {
      setSelected(tabs[0])
    }
  }, [tabs, selected])

  const ctx = useMemo(() => ({ insertTab, selected }), [insertTab, selected])

  return (
    <TabContext.Provider value={ctx}>
      <div className="w-full">
        {tabs.length > 1 && (
          <div className="relative mx-auto w-full max-w-screen-lg">
            <div className="grid w-full auto-cols-fr grid-flow-col border-b-2 border-light text-grey3">
              {tabs.map(tab => (
                <button
                  className={`label-lg row-start-1 flex h-12 items-start justify-center transition-all duration-100 ${selected === tab ? 'text-primary' : 'text-grey4'}`}
                  onClick={() => setSelected(tab)}
                  key={tab}
                >
                  {tab}
                </button>
              ))}
              <div
                className="absolute bottom-[-1px] row-start-2 h-[3px] bg-[#744FF6] transition-all duration-100"
                style={{
                  left: `${(100 / tabs.length) * tabs.indexOf(selected)}%`,
                  width: `${100 / tabs.length}%`,
                }}
              />
            </div>
          </div>
        )}
        {children}
      </div>
    </TabContext.Provider>
  )
}
