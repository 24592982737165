/**
 * THIS FILE IS AUTOGENERATED
 * Do not edit directly as it will be overwritten by the task that generated it
 */

import { Country } from '@jotta/grpc-connect-openapi/esm/openapi/country_pb'
import { t } from '@lingui/macro'

export type CountryCode =
  | 'ad'
  | 'ae'
  | 'af'
  | 'ag'
  | 'ai'
  | 'al'
  | 'am'
  | 'ao'
  | 'aq'
  | 'ar'
  | 'as'
  | 'at'
  | 'au'
  | 'aw'
  | 'az'
  | 'ba'
  | 'bb'
  | 'bd'
  | 'be'
  | 'bf'
  | 'bg'
  | 'bh'
  | 'bi'
  | 'bj'
  | 'bl'
  | 'bm'
  | 'bn'
  | 'bo'
  | 'br'
  | 'bs'
  | 'bt'
  | 'bv'
  | 'bw'
  | 'by'
  | 'bz'
  | 'ca'
  | 'cc'
  | 'cd'
  | 'cf'
  | 'cg'
  | 'ch'
  | 'ci'
  | 'ck'
  | 'cl'
  | 'cm'
  | 'cn'
  | 'co'
  | 'cr'
  | 'cu'
  | 'cv'
  | 'cx'
  | 'cy'
  | 'cz'
  | 'de'
  | 'dj'
  | 'dk'
  | 'dm'
  | 'do'
  | 'dz'
  | 'ec'
  | 'ee'
  | 'eg'
  | 'eh'
  | 'er'
  | 'es'
  | 'et'
  | 'fi'
  | 'fj'
  | 'fk'
  | 'fm'
  | 'fo'
  | 'fr'
  | 'ga'
  | 'gb'
  | 'gd'
  | 'ge'
  | 'gf'
  | 'gg'
  | 'gh'
  | 'gi'
  | 'gl'
  | 'gm'
  | 'gn'
  | 'gp'
  | 'gq'
  | 'gr'
  | 'gs'
  | 'gt'
  | 'gu'
  | 'gw'
  | 'gy'
  | 'hk'
  | 'hm'
  | 'hn'
  | 'hr'
  | 'ht'
  | 'hu'
  | 'id'
  | 'ie'
  | 'il'
  | 'im'
  | 'in'
  | 'io'
  | 'iq'
  | 'ir'
  | 'is'
  | 'it'
  | 'je'
  | 'jm'
  | 'jo'
  | 'jp'
  | 'ke'
  | 'kg'
  | 'kh'
  | 'ki'
  | 'km'
  | 'kn'
  | 'kp'
  | 'kr'
  | 'kw'
  | 'ky'
  | 'kz'
  | 'la'
  | 'lb'
  | 'lc'
  | 'li'
  | 'lk'
  | 'lr'
  | 'ls'
  | 'lt'
  | 'lu'
  | 'lv'
  | 'ly'
  | 'ma'
  | 'mc'
  | 'md'
  | 'me'
  | 'mf'
  | 'mg'
  | 'mh'
  | 'mk'
  | 'ml'
  | 'mm'
  | 'mn'
  | 'mo'
  | 'mp'
  | 'mq'
  | 'mr'
  | 'ms'
  | 'mt'
  | 'mu'
  | 'mv'
  | 'mw'
  | 'mx'
  | 'my'
  | 'mz'
  | 'na'
  | 'nc'
  | 'ne'
  | 'nf'
  | 'ng'
  | 'ni'
  | 'nl'
  | 'no'
  | 'np'
  | 'nr'
  | 'nu'
  | 'nz'
  | 'om'
  | 'pa'
  | 'pe'
  | 'pf'
  | 'pg'
  | 'ph'
  | 'pk'
  | 'pl'
  | 'pm'
  | 'pn'
  | 'pr'
  | 'ps'
  | 'pt'
  | 'pw'
  | 'py'
  | 'qa'
  | 're'
  | 'ro'
  | 'rs'
  | 'ru'
  | 'rw'
  | 'sa'
  | 'sb'
  | 'sc'
  | 'sd'
  | 'se'
  | 'sg'
  | 'sh'
  | 'si'
  | 'sj'
  | 'sk'
  | 'sl'
  | 'sm'
  | 'sn'
  | 'so'
  | 'sr'
  | 'st'
  | 'sv'
  | 'sy'
  | 'sz'
  | 'tc'
  | 'td'
  | 'tf'
  | 'tg'
  | 'th'
  | 'tj'
  | 'tk'
  | 'tl'
  | 'tm'
  | 'tn'
  | 'to'
  | 'tr'
  | 'tt'
  | 'tv'
  | 'tw'
  | 'tz'
  | 'ua'
  | 'ug'
  | 'um'
  | 'us'
  | 'uy'
  | 'uz'
  | 'va'
  | 'vc'
  | 've'
  | 'vg'
  | 'vi'
  | 'vn'
  | 'vu'
  | 'wf'
  | 'ws'
  | 'ye'
  | 'yt'
  | 'za'
  | 'zm'
  | 'zw'
export const countryCodes: readonly CountryCode[] = [
  'ad',
  'ae',
  'af',
  'ag',
  'ai',
  'al',
  'am',
  'ao',
  'aq',
  'ar',
  'as',
  'at',
  'au',
  'aw',
  'az',
  'ba',
  'bb',
  'bd',
  'be',
  'bf',
  'bg',
  'bh',
  'bi',
  'bj',
  'bl',
  'bm',
  'bn',
  'bo',
  'br',
  'bs',
  'bt',
  'bv',
  'bw',
  'by',
  'bz',
  'ca',
  'cc',
  'cd',
  'cf',
  'cg',
  'ch',
  'ci',
  'ck',
  'cl',
  'cm',
  'cn',
  'co',
  'cr',
  'cu',
  'cv',
  'cx',
  'cy',
  'cz',
  'de',
  'dj',
  'dk',
  'dm',
  'do',
  'dz',
  'ec',
  'ee',
  'eg',
  'eh',
  'er',
  'es',
  'et',
  'fi',
  'fj',
  'fk',
  'fm',
  'fo',
  'fr',
  'ga',
  'gb',
  'gd',
  'ge',
  'gf',
  'gg',
  'gh',
  'gi',
  'gl',
  'gm',
  'gn',
  'gp',
  'gq',
  'gr',
  'gs',
  'gt',
  'gu',
  'gw',
  'gy',
  'hk',
  'hm',
  'hn',
  'hr',
  'ht',
  'hu',
  'id',
  'ie',
  'il',
  'im',
  'in',
  'io',
  'iq',
  'ir',
  'is',
  'it',
  'je',
  'jm',
  'jo',
  'jp',
  'ke',
  'kg',
  'kh',
  'ki',
  'km',
  'kn',
  'kp',
  'kr',
  'kw',
  'ky',
  'kz',
  'la',
  'lb',
  'lc',
  'li',
  'lk',
  'lr',
  'ls',
  'lt',
  'lu',
  'lv',
  'ly',
  'ma',
  'mc',
  'md',
  'me',
  'mf',
  'mg',
  'mh',
  'mk',
  'ml',
  'mm',
  'mn',
  'mo',
  'mp',
  'mq',
  'mr',
  'ms',
  'mt',
  'mu',
  'mv',
  'mw',
  'mx',
  'my',
  'mz',
  'na',
  'nc',
  'ne',
  'nf',
  'ng',
  'ni',
  'nl',
  'no',
  'np',
  'nr',
  'nu',
  'nz',
  'om',
  'pa',
  'pe',
  'pf',
  'pg',
  'ph',
  'pk',
  'pl',
  'pm',
  'pn',
  'pr',
  'ps',
  'pt',
  'pw',
  'py',
  'qa',
  're',
  'ro',
  'rs',
  'ru',
  'rw',
  'sa',
  'sb',
  'sc',
  'sd',
  'se',
  'sg',
  'sh',
  'si',
  'sj',
  'sk',
  'sl',
  'sm',
  'sn',
  'so',
  'sr',
  'st',
  'sv',
  'sy',
  'sz',
  'tc',
  'td',
  'tf',
  'tg',
  'th',
  'tj',
  'tk',
  'tl',
  'tm',
  'tn',
  'to',
  'tr',
  'tt',
  'tv',
  'tw',
  'tz',
  'ua',
  'ug',
  'um',
  'us',
  'uy',
  'uz',
  'va',
  'vc',
  've',
  'vg',
  'vi',
  'vn',
  'vu',
  'wf',
  'ws',
  'ye',
  'yt',
  'za',
  'zm',
  'zw',
] as const

export interface CountryInfo {
  key: string
  code: CountryCode
  country: Country
  flag: string
  callingCode?: string
  value: string
}

export const countries: Record<CountryCode, CountryInfo> = {
  ad: {
    key: 'COUNTRY__AD',
    code: 'ad',
    country: Country.ANDORRA,
    flag: '🇦🇩',
    callingCode: '376',
    value: t({ id: 'COUNTRY__AD' }),
  },
  ae: {
    key: 'COUNTRY__AE',
    code: 'ae',
    country: Country.UNITED_ARAB_EMIRATES,
    flag: '🇦🇪',
    callingCode: '971',
    value: t({ id: 'COUNTRY__AE' }),
  },
  af: {
    key: 'COUNTRY__AF',
    code: 'af',
    country: Country.AFGHANISTAN,
    flag: '🇦🇫',
    callingCode: '93',
    value: t({ id: 'COUNTRY__AF' }),
  },
  ag: {
    key: 'COUNTRY__AG',
    code: 'ag',
    country: Country.ANTIGUA_AND_BARBUDA,
    flag: '🇦🇬',
    callingCode: '1',
    value: t({ id: 'COUNTRY__AG' }),
  },
  ai: {
    key: 'COUNTRY__AI',
    code: 'ai',
    country: Country.ANGUILLA,
    flag: '🇦🇮',
    callingCode: '1',
    value: t({ id: 'COUNTRY__AI' }),
  },
  al: {
    key: 'COUNTRY__AL',
    code: 'al',
    country: Country.ALBANIA,
    flag: '🇦🇱',
    callingCode: '355',
    value: t({ id: 'COUNTRY__AL' }),
  },
  am: {
    key: 'COUNTRY__AM',
    code: 'am',
    country: Country.ARMENIA,
    flag: '🇦🇲',
    callingCode: '374',
    value: t({ id: 'COUNTRY__AM' }),
  },
  ao: {
    key: 'COUNTRY__AO',
    code: 'ao',
    country: Country.ANGOLA,
    flag: '🇦🇴',
    callingCode: '244',
    value: t({ id: 'COUNTRY__AO' }),
  },
  aq: {
    key: 'COUNTRY__AQ',
    code: 'aq',
    country: Country.ANTARCTICA,
    flag: '🇦🇶',
    value: t({ id: 'COUNTRY__AQ' }),
  },
  ar: {
    key: 'COUNTRY__AR',
    code: 'ar',
    country: Country.ARGENTINA,
    flag: '🇦🇷',
    callingCode: '54',
    value: t({ id: 'COUNTRY__AR' }),
  },
  as: {
    key: 'COUNTRY__AS',
    code: 'as',
    country: Country.AMERICAN_SAMOA,
    flag: '🇦🇸',
    callingCode: '1',
    value: t({ id: 'COUNTRY__AS' }),
  },
  at: {
    key: 'COUNTRY__AT',
    code: 'at',
    country: Country.AUSTRIA,
    flag: '🇦🇹',
    callingCode: '43',
    value: t({ id: 'COUNTRY__AT' }),
  },
  au: {
    key: 'COUNTRY__AU',
    code: 'au',
    country: Country.AUSTRALIA,
    flag: '🇦🇺',
    callingCode: '61',
    value: t({ id: 'COUNTRY__AU' }),
  },
  aw: {
    key: 'COUNTRY__AW',
    code: 'aw',
    country: Country.ARUBA,
    flag: '🇦🇼',
    callingCode: '297',
    value: t({ id: 'COUNTRY__AW' }),
  },
  az: {
    key: 'COUNTRY__AZ',
    code: 'az',
    country: Country.AZERBAIJAN,
    flag: '🇦🇿',
    callingCode: '994',
    value: t({ id: 'COUNTRY__AZ' }),
  },
  ba: {
    key: 'COUNTRY__BA',
    code: 'ba',
    country: Country.BOSNIA_AND_HERZEGOVINA,
    flag: '🇧🇦',
    callingCode: '387',
    value: t({ id: 'COUNTRY__BA' }),
  },
  bb: {
    key: 'COUNTRY__BB',
    code: 'bb',
    country: Country.BARBADOS,
    flag: '🇧🇧',
    callingCode: '1',
    value: t({ id: 'COUNTRY__BB' }),
  },
  bd: {
    key: 'COUNTRY__BD',
    code: 'bd',
    country: Country.BANGLADESH,
    flag: '🇧🇩',
    callingCode: '880',
    value: t({ id: 'COUNTRY__BD' }),
  },
  be: {
    key: 'COUNTRY__BE',
    code: 'be',
    country: Country.BELGIUM,
    flag: '🇧🇪',
    callingCode: '32',
    value: t({ id: 'COUNTRY__BE' }),
  },
  bf: {
    key: 'COUNTRY__BF',
    code: 'bf',
    country: Country.BURKINA_FASO,
    flag: '🇧🇫',
    callingCode: '226',
    value: t({ id: 'COUNTRY__BF' }),
  },
  bg: {
    key: 'COUNTRY__BG',
    code: 'bg',
    country: Country.BULGARIA,
    flag: '🇧🇬',
    callingCode: '359',
    value: t({ id: 'COUNTRY__BG' }),
  },
  bh: {
    key: 'COUNTRY__BH',
    code: 'bh',
    country: Country.BAHRAIN,
    flag: '🇧🇭',
    callingCode: '973',
    value: t({ id: 'COUNTRY__BH' }),
  },
  bi: {
    key: 'COUNTRY__BI',
    code: 'bi',
    country: Country.BURUNDI,
    flag: '🇧🇮',
    callingCode: '257',
    value: t({ id: 'COUNTRY__BI' }),
  },
  bj: {
    key: 'COUNTRY__BJ',
    code: 'bj',
    country: Country.BENIN,
    flag: '🇧🇯',
    callingCode: '229',
    value: t({ id: 'COUNTRY__BJ' }),
  },
  bl: {
    key: 'COUNTRY__BL',
    code: 'bl',
    country: Country.SAINT_BARTHELEMY,
    flag: '🇧🇱',
    callingCode: '590',
    value: t({ id: 'COUNTRY__BL' }),
  },
  bm: {
    key: 'COUNTRY__BM',
    code: 'bm',
    country: Country.BERMUDA,
    flag: '🇧🇲',
    callingCode: '1',
    value: t({ id: 'COUNTRY__BM' }),
  },
  bn: {
    key: 'COUNTRY__BN',
    code: 'bn',
    country: Country.BRUNEI_DARUSSALAM,
    flag: '🇧🇳',
    callingCode: '673',
    value: t({ id: 'COUNTRY__BN' }),
  },
  bo: {
    key: 'COUNTRY__BO',
    code: 'bo',
    country: Country.BOLIVIA,
    flag: '🇧🇴',
    callingCode: '591',
    value: t({ id: 'COUNTRY__BO' }),
  },
  br: {
    key: 'COUNTRY__BR',
    code: 'br',
    country: Country.BRAZIL,
    flag: '🇧🇷',
    callingCode: '55',
    value: t({ id: 'COUNTRY__BR' }),
  },
  bs: {
    key: 'COUNTRY__BS',
    code: 'bs',
    country: Country.BAHAMAS,
    flag: '🇧🇸',
    callingCode: '1',
    value: t({ id: 'COUNTRY__BS' }),
  },
  bt: {
    key: 'COUNTRY__BT',
    code: 'bt',
    country: Country.BHUTAN,
    flag: '🇧🇹',
    callingCode: '975',
    value: t({ id: 'COUNTRY__BT' }),
  },
  bv: {
    key: 'COUNTRY__BV',
    code: 'bv',
    country: Country.BOUVET_ISLAND,
    flag: '🇧🇻',
    value: t({ id: 'COUNTRY__BV' }),
  },
  bw: {
    key: 'COUNTRY__BW',
    code: 'bw',
    country: Country.BOTSWANA,
    flag: '🇧🇼',
    callingCode: '267',
    value: t({ id: 'COUNTRY__BW' }),
  },
  by: {
    key: 'COUNTRY__BY',
    code: 'by',
    country: Country.BELARUS,
    flag: '🇧🇾',
    callingCode: '375',
    value: t({ id: 'COUNTRY__BY' }),
  },
  bz: {
    key: 'COUNTRY__BZ',
    code: 'bz',
    country: Country.BELIZE,
    flag: '🇧🇿',
    callingCode: '501',
    value: t({ id: 'COUNTRY__BZ' }),
  },
  ca: {
    key: 'COUNTRY__CA',
    code: 'ca',
    country: Country.CANADA,
    flag: '🇨🇦',
    callingCode: '1',
    value: t({ id: 'COUNTRY__CA' }),
  },
  cc: {
    key: 'COUNTRY__CC',
    code: 'cc',
    country: Country.COCOS_KEELING_ISLANDS,
    flag: '🇨🇨',
    callingCode: '61',
    value: t({ id: 'COUNTRY__CC' }),
  },
  cd: {
    key: 'COUNTRY__CD',
    code: 'cd',
    country: Country.CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE,
    flag: '🇨🇩',
    callingCode: '243',
    value: t({ id: 'COUNTRY__CD' }),
  },
  cf: {
    key: 'COUNTRY__CF',
    code: 'cf',
    country: Country.CENTRAL_AFRICAN_REPUBLIC,
    flag: '🇨🇫',
    callingCode: '236',
    value: t({ id: 'COUNTRY__CF' }),
  },
  cg: {
    key: 'COUNTRY__CG',
    code: 'cg',
    country: Country.CONGO,
    flag: '🇨🇬',
    callingCode: '242',
    value: t({ id: 'COUNTRY__CG' }),
  },
  ch: {
    key: 'COUNTRY__CH',
    code: 'ch',
    country: Country.SWITZERLAND,
    flag: '🇨🇭',
    callingCode: '41',
    value: t({ id: 'COUNTRY__CH' }),
  },
  ci: {
    key: 'COUNTRY__CI',
    code: 'ci',
    country: Country.COTE_DIVOIRE,
    flag: '🇨🇮',
    callingCode: '225',
    value: t({ id: 'COUNTRY__CI' }),
  },
  ck: {
    key: 'COUNTRY__CK',
    code: 'ck',
    country: Country.COOK_ISLANDS,
    flag: '🇨🇰',
    callingCode: '682',
    value: t({ id: 'COUNTRY__CK' }),
  },
  cl: {
    key: 'COUNTRY__CL',
    code: 'cl',
    country: Country.CHILE,
    flag: '🇨🇱',
    callingCode: '56',
    value: t({ id: 'COUNTRY__CL' }),
  },
  cm: {
    key: 'COUNTRY__CM',
    code: 'cm',
    country: Country.CAMEROON,
    flag: '🇨🇲',
    callingCode: '237',
    value: t({ id: 'COUNTRY__CM' }),
  },
  cn: {
    key: 'COUNTRY__CN',
    code: 'cn',
    country: Country.CHINA,
    flag: '🇨🇳',
    callingCode: '86',
    value: t({ id: 'COUNTRY__CN' }),
  },
  co: {
    key: 'COUNTRY__CO',
    code: 'co',
    country: Country.COLOMBIA,
    flag: '🇨🇴',
    callingCode: '57',
    value: t({ id: 'COUNTRY__CO' }),
  },
  cr: {
    key: 'COUNTRY__CR',
    code: 'cr',
    country: Country.COSTA_RICA,
    flag: '🇨🇷',
    callingCode: '506',
    value: t({ id: 'COUNTRY__CR' }),
  },
  cu: {
    key: 'COUNTRY__CU',
    code: 'cu',
    country: Country.CUBA,
    flag: '🇨🇺',
    callingCode: '53',
    value: t({ id: 'COUNTRY__CU' }),
  },
  cv: {
    key: 'COUNTRY__CV',
    code: 'cv',
    country: Country.CAPE_VERDE,
    flag: '🇨🇻',
    callingCode: '238',
    value: t({ id: 'COUNTRY__CV' }),
  },
  cx: {
    key: 'COUNTRY__CX',
    code: 'cx',
    country: Country.CHRISTMAS_ISLAND,
    flag: '🇨🇽',
    callingCode: '61',
    value: t({ id: 'COUNTRY__CX' }),
  },
  cy: {
    key: 'COUNTRY__CY',
    code: 'cy',
    country: Country.CYPRUS,
    flag: '🇨🇾',
    callingCode: '357',
    value: t({ id: 'COUNTRY__CY' }),
  },
  cz: {
    key: 'COUNTRY__CZ',
    code: 'cz',
    country: Country.CZECH_REPUBLIC,
    flag: '🇨🇿',
    callingCode: '420',
    value: t({ id: 'COUNTRY__CZ' }),
  },
  de: {
    key: 'COUNTRY__DE',
    code: 'de',
    country: Country.GERMANY,
    flag: '🇩🇪',
    callingCode: '49',
    value: t({ id: 'COUNTRY__DE' }),
  },
  dj: {
    key: 'COUNTRY__DJ',
    code: 'dj',
    country: Country.DJIBOUTI,
    flag: '🇩🇯',
    callingCode: '253',
    value: t({ id: 'COUNTRY__DJ' }),
  },
  dk: {
    key: 'COUNTRY__DK',
    code: 'dk',
    country: Country.DENMARK,
    flag: '🇩🇰',
    callingCode: '45',
    value: t({ id: 'COUNTRY__DK' }),
  },
  dm: {
    key: 'COUNTRY__DM',
    code: 'dm',
    country: Country.DOMINICA,
    flag: '🇩🇲',
    callingCode: '1',
    value: t({ id: 'COUNTRY__DM' }),
  },
  do: {
    key: 'COUNTRY__DO',
    code: 'do',
    country: Country.DOMINICAN_REPUBLIC,
    flag: '🇩🇴',
    callingCode: '1',
    value: t({ id: 'COUNTRY__DO' }),
  },
  dz: {
    key: 'COUNTRY__DZ',
    code: 'dz',
    country: Country.ALGERIA,
    flag: '🇩🇿',
    callingCode: '213',
    value: t({ id: 'COUNTRY__DZ' }),
  },
  ec: {
    key: 'COUNTRY__EC',
    code: 'ec',
    country: Country.ECUADOR,
    flag: '🇪🇨',
    callingCode: '593',
    value: t({ id: 'COUNTRY__EC' }),
  },
  ee: {
    key: 'COUNTRY__EE',
    code: 'ee',
    country: Country.ESTONIA,
    flag: '🇪🇪',
    callingCode: '372',
    value: t({ id: 'COUNTRY__EE' }),
  },
  eg: {
    key: 'COUNTRY__EG',
    code: 'eg',
    country: Country.EGYPT,
    flag: '🇪🇬',
    callingCode: '20',
    value: t({ id: 'COUNTRY__EG' }),
  },
  eh: {
    key: 'COUNTRY__EH',
    code: 'eh',
    country: Country.WESTERN_SAHARA,
    flag: '🇪🇭',
    callingCode: '212',
    value: t({ id: 'COUNTRY__EH' }),
  },
  er: {
    key: 'COUNTRY__ER',
    code: 'er',
    country: Country.ERITREA,
    flag: '🇪🇷',
    callingCode: '291',
    value: t({ id: 'COUNTRY__ER' }),
  },
  es: {
    key: 'COUNTRY__ES',
    code: 'es',
    country: Country.SPAIN,
    flag: '🇪🇸',
    callingCode: '34',
    value: t({ id: 'COUNTRY__ES' }),
  },
  et: {
    key: 'COUNTRY__ET',
    code: 'et',
    country: Country.ETHIOPIA,
    flag: '🇪🇹',
    callingCode: '251',
    value: t({ id: 'COUNTRY__ET' }),
  },
  fi: {
    key: 'COUNTRY__FI',
    code: 'fi',
    country: Country.FINLAND,
    flag: '🇫🇮',
    callingCode: '358',
    value: t({ id: 'COUNTRY__FI' }),
  },
  fj: {
    key: 'COUNTRY__FJ',
    code: 'fj',
    country: Country.FIJI,
    flag: '🇫🇯',
    callingCode: '679',
    value: t({ id: 'COUNTRY__FJ' }),
  },
  fk: {
    key: 'COUNTRY__FK',
    code: 'fk',
    country: Country.FALKLAND_ISLANDS_MALVINAS,
    flag: '🇫🇰',
    callingCode: '500',
    value: t({ id: 'COUNTRY__FK' }),
  },
  fm: {
    key: 'COUNTRY__FM',
    code: 'fm',
    country: Country.MICRONESIA_FEDERATED_STATES_OF,
    flag: '🇫🇲',
    callingCode: '691',
    value: t({ id: 'COUNTRY__FM' }),
  },
  fo: {
    key: 'COUNTRY__FO',
    code: 'fo',
    country: Country.FAROE_ISLANDS,
    flag: '🇫🇴',
    callingCode: '298',
    value: t({ id: 'COUNTRY__FO' }),
  },
  fr: {
    key: 'COUNTRY__FR',
    code: 'fr',
    country: Country.FRANCE,
    flag: '🇫🇷',
    callingCode: '33',
    value: t({ id: 'COUNTRY__FR' }),
  },
  ga: {
    key: 'COUNTRY__GA',
    code: 'ga',
    country: Country.GABON,
    flag: '🇬🇦',
    callingCode: '241',
    value: t({ id: 'COUNTRY__GA' }),
  },
  gb: {
    key: 'COUNTRY__GB',
    code: 'gb',
    country: Country.UNITED_KINGDOM,
    flag: '🇬🇧',
    callingCode: '44',
    value: t({ id: 'COUNTRY__GB' }),
  },
  gd: {
    key: 'COUNTRY__GD',
    code: 'gd',
    country: Country.GRENADA,
    flag: '🇬🇩',
    callingCode: '1',
    value: t({ id: 'COUNTRY__GD' }),
  },
  ge: {
    key: 'COUNTRY__GE',
    code: 'ge',
    country: Country.GEORGIA,
    flag: '🇬🇪',
    callingCode: '995',
    value: t({ id: 'COUNTRY__GE' }),
  },
  gf: {
    key: 'COUNTRY__GF',
    code: 'gf',
    country: Country.FRENCH_GUIANA,
    flag: '🇬🇫',
    callingCode: '594',
    value: t({ id: 'COUNTRY__GF' }),
  },
  gg: {
    key: 'COUNTRY__GG',
    code: 'gg',
    country: Country.GUERNSEY,
    flag: '🇬🇬',
    callingCode: '44',
    value: t({ id: 'COUNTRY__GG' }),
  },
  gh: {
    key: 'COUNTRY__GH',
    code: 'gh',
    country: Country.GHANA,
    flag: '🇬🇭',
    callingCode: '233',
    value: t({ id: 'COUNTRY__GH' }),
  },
  gi: {
    key: 'COUNTRY__GI',
    code: 'gi',
    country: Country.GIBRALTAR,
    flag: '🇬🇮',
    callingCode: '350',
    value: t({ id: 'COUNTRY__GI' }),
  },
  gl: {
    key: 'COUNTRY__GL',
    code: 'gl',
    country: Country.GREENLAND,
    flag: '🇬🇱',
    callingCode: '299',
    value: t({ id: 'COUNTRY__GL' }),
  },
  gm: {
    key: 'COUNTRY__GM',
    code: 'gm',
    country: Country.GAMBIA,
    flag: '🇬🇲',
    callingCode: '220',
    value: t({ id: 'COUNTRY__GM' }),
  },
  gn: {
    key: 'COUNTRY__GN',
    code: 'gn',
    country: Country.GUINEA,
    flag: '🇬🇳',
    callingCode: '224',
    value: t({ id: 'COUNTRY__GN' }),
  },
  gp: {
    key: 'COUNTRY__GP',
    code: 'gp',
    country: Country.GUADELOUPE,
    flag: '🇬🇵',
    callingCode: '590',
    value: t({ id: 'COUNTRY__GP' }),
  },
  gq: {
    key: 'COUNTRY__GQ',
    code: 'gq',
    country: Country.EQUATORIAL_GUINEA,
    flag: '🇬🇶',
    callingCode: '240',
    value: t({ id: 'COUNTRY__GQ' }),
  },
  gr: {
    key: 'COUNTRY__GR',
    code: 'gr',
    country: Country.GREECE,
    flag: '🇬🇷',
    callingCode: '30',
    value: t({ id: 'COUNTRY__GR' }),
  },
  gs: {
    key: 'COUNTRY__GS',
    code: 'gs',
    country: Country.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS,
    flag: '🇬🇸',
    value: t({ id: 'COUNTRY__GS' }),
  },
  gt: {
    key: 'COUNTRY__GT',
    code: 'gt',
    country: Country.GUATEMALA,
    flag: '🇬🇹',
    callingCode: '502',
    value: t({ id: 'COUNTRY__GT' }),
  },
  gu: {
    key: 'COUNTRY__GU',
    code: 'gu',
    country: Country.GUAM,
    flag: '🇬🇺',
    callingCode: '1',
    value: t({ id: 'COUNTRY__GU' }),
  },
  gw: {
    key: 'COUNTRY__GW',
    code: 'gw',
    country: Country.GUINEA_BISSAU,
    flag: '🇬🇼',
    callingCode: '245',
    value: t({ id: 'COUNTRY__GW' }),
  },
  gy: {
    key: 'COUNTRY__GY',
    code: 'gy',
    country: Country.GUYANA,
    flag: '🇬🇾',
    callingCode: '592',
    value: t({ id: 'COUNTRY__GY' }),
  },
  hk: {
    key: 'COUNTRY__HK',
    code: 'hk',
    country: Country.HONG_KONG,
    flag: '🇭🇰',
    callingCode: '852',
    value: t({ id: 'COUNTRY__HK' }),
  },
  hm: {
    key: 'COUNTRY__HM',
    code: 'hm',
    country: Country.HEARD_ISLAND_AND_MCDONALD_ISLANDS,
    flag: '🇭🇲',
    value: t({ id: 'COUNTRY__HM' }),
  },
  hn: {
    key: 'COUNTRY__HN',
    code: 'hn',
    country: Country.HONDURAS,
    flag: '🇭🇳',
    callingCode: '504',
    value: t({ id: 'COUNTRY__HN' }),
  },
  hr: {
    key: 'COUNTRY__HR',
    code: 'hr',
    country: Country.CROATIA,
    flag: '🇭🇷',
    callingCode: '385',
    value: t({ id: 'COUNTRY__HR' }),
  },
  ht: {
    key: 'COUNTRY__HT',
    code: 'ht',
    country: Country.HAITI,
    flag: '🇭🇹',
    callingCode: '509',
    value: t({ id: 'COUNTRY__HT' }),
  },
  hu: {
    key: 'COUNTRY__HU',
    code: 'hu',
    country: Country.HUNGARY,
    flag: '🇭🇺',
    callingCode: '36',
    value: t({ id: 'COUNTRY__HU' }),
  },
  id: {
    key: 'COUNTRY__ID',
    code: 'id',
    country: Country.INDONESIA,
    flag: '🇮🇩',
    callingCode: '62',
    value: t({ id: 'COUNTRY__ID' }),
  },
  ie: {
    key: 'COUNTRY__IE',
    code: 'ie',
    country: Country.IRELAND,
    flag: '🇮🇪',
    callingCode: '353',
    value: t({ id: 'COUNTRY__IE' }),
  },
  il: {
    key: 'COUNTRY__IL',
    code: 'il',
    country: Country.ISRAEL,
    flag: '🇮🇱',
    callingCode: '972',
    value: t({ id: 'COUNTRY__IL' }),
  },
  im: {
    key: 'COUNTRY__IM',
    code: 'im',
    country: Country.ISLE_OF_MAN,
    flag: '🇮🇲',
    callingCode: '44',
    value: t({ id: 'COUNTRY__IM' }),
  },
  in: {
    key: 'COUNTRY__IN',
    code: 'in',
    country: Country.INDIA,
    flag: '🇮🇳',
    callingCode: '91',
    value: t({ id: 'COUNTRY__IN' }),
  },
  io: {
    key: 'COUNTRY__IO',
    code: 'io',
    country: Country.BRITISH_INDIAN_OCEAN_TERRITORY,
    flag: '🇮🇴',
    callingCode: '246',
    value: t({ id: 'COUNTRY__IO' }),
  },
  iq: {
    key: 'COUNTRY__IQ',
    code: 'iq',
    country: Country.IRAQ,
    flag: '🇮🇶',
    callingCode: '964',
    value: t({ id: 'COUNTRY__IQ' }),
  },
  ir: {
    key: 'COUNTRY__IR',
    code: 'ir',
    country: Country.IRAN_ISLAMIC_REPUBLIC_OF,
    flag: '🇮🇷',
    callingCode: '98',
    value: t({ id: 'COUNTRY__IR' }),
  },
  is: {
    key: 'COUNTRY__IS',
    code: 'is',
    country: Country.ICELAND,
    flag: '🇮🇸',
    callingCode: '354',
    value: t({ id: 'COUNTRY__IS' }),
  },
  it: {
    key: 'COUNTRY__IT',
    code: 'it',
    country: Country.ITALY,
    flag: '🇮🇹',
    callingCode: '39',
    value: t({ id: 'COUNTRY__IT' }),
  },
  je: {
    key: 'COUNTRY__JE',
    code: 'je',
    country: Country.JERSEY,
    flag: '🇯🇪',
    callingCode: '44',
    value: t({ id: 'COUNTRY__JE' }),
  },
  jm: {
    key: 'COUNTRY__JM',
    code: 'jm',
    country: Country.JAMAICA,
    flag: '🇯🇲',
    callingCode: '1',
    value: t({ id: 'COUNTRY__JM' }),
  },
  jo: {
    key: 'COUNTRY__JO',
    code: 'jo',
    country: Country.JORDAN,
    flag: '🇯🇴',
    callingCode: '962',
    value: t({ id: 'COUNTRY__JO' }),
  },
  jp: {
    key: 'COUNTRY__JP',
    code: 'jp',
    country: Country.JAPAN,
    flag: '🇯🇵',
    callingCode: '81',
    value: t({ id: 'COUNTRY__JP' }),
  },
  ke: {
    key: 'COUNTRY__KE',
    code: 'ke',
    country: Country.KENYA,
    flag: '🇰🇪',
    callingCode: '254',
    value: t({ id: 'COUNTRY__KE' }),
  },
  kg: {
    key: 'COUNTRY__KG',
    code: 'kg',
    country: Country.KYRGYZSTAN,
    flag: '🇰🇬',
    callingCode: '996',
    value: t({ id: 'COUNTRY__KG' }),
  },
  kh: {
    key: 'COUNTRY__KH',
    code: 'kh',
    country: Country.CAMBODIA,
    flag: '🇰🇭',
    callingCode: '855',
    value: t({ id: 'COUNTRY__KH' }),
  },
  ki: {
    key: 'COUNTRY__KI',
    code: 'ki',
    country: Country.KIRIBATI,
    flag: '🇰🇮',
    callingCode: '686',
    value: t({ id: 'COUNTRY__KI' }),
  },
  km: {
    key: 'COUNTRY__KM',
    code: 'km',
    country: Country.COMOROS,
    flag: '🇰🇲',
    callingCode: '269',
    value: t({ id: 'COUNTRY__KM' }),
  },
  kn: {
    key: 'COUNTRY__KN',
    code: 'kn',
    country: Country.SAINT_KITTS_AND_NEVIS,
    flag: '🇰🇳',
    callingCode: '1',
    value: t({ id: 'COUNTRY__KN' }),
  },
  kp: {
    key: 'COUNTRY__KP',
    code: 'kp',
    country: Country.KOREA_DEMOCRATIC_PEOPLES_REPUBLIC_OF,
    flag: '🇰🇵',
    callingCode: '850',
    value: t({ id: 'COUNTRY__KP' }),
  },
  kr: {
    key: 'COUNTRY__KR',
    code: 'kr',
    country: Country.KOREA_REPUBLIC_OF,
    flag: '🇰🇷',
    callingCode: '82',
    value: t({ id: 'COUNTRY__KR' }),
  },
  kw: {
    key: 'COUNTRY__KW',
    code: 'kw',
    country: Country.KUWAIT,
    flag: '🇰🇼',
    callingCode: '965',
    value: t({ id: 'COUNTRY__KW' }),
  },
  ky: {
    key: 'COUNTRY__KY',
    code: 'ky',
    country: Country.CAYMAN_ISLANDS,
    flag: '🇰🇾',
    callingCode: '1',
    value: t({ id: 'COUNTRY__KY' }),
  },
  kz: {
    key: 'COUNTRY__KZ',
    code: 'kz',
    country: Country.KAZAKHSTAN,
    flag: '🇰🇿',
    callingCode: '7',
    value: t({ id: 'COUNTRY__KZ' }),
  },
  la: {
    key: 'COUNTRY__LA',
    code: 'la',
    country: Country.LAO_PEOPLES_DEMOCRATIC_REPUBLIC,
    flag: '🇱🇦',
    callingCode: '856',
    value: t({ id: 'COUNTRY__LA' }),
  },
  lb: {
    key: 'COUNTRY__LB',
    code: 'lb',
    country: Country.LEBANON,
    flag: '🇱🇧',
    callingCode: '961',
    value: t({ id: 'COUNTRY__LB' }),
  },
  lc: {
    key: 'COUNTRY__LC',
    code: 'lc',
    country: Country.SAINT_LUCIA,
    flag: '🇱🇨',
    callingCode: '1',
    value: t({ id: 'COUNTRY__LC' }),
  },
  li: {
    key: 'COUNTRY__LI',
    code: 'li',
    country: Country.LIECHTENSTEIN,
    flag: '🇱🇮',
    callingCode: '423',
    value: t({ id: 'COUNTRY__LI' }),
  },
  lk: {
    key: 'COUNTRY__LK',
    code: 'lk',
    country: Country.SRI_LANKA,
    flag: '🇱🇰',
    callingCode: '94',
    value: t({ id: 'COUNTRY__LK' }),
  },
  lr: {
    key: 'COUNTRY__LR',
    code: 'lr',
    country: Country.LIBERIA,
    flag: '🇱🇷',
    callingCode: '231',
    value: t({ id: 'COUNTRY__LR' }),
  },
  ls: {
    key: 'COUNTRY__LS',
    code: 'ls',
    country: Country.LESOTHO,
    flag: '🇱🇸',
    callingCode: '266',
    value: t({ id: 'COUNTRY__LS' }),
  },
  lt: {
    key: 'COUNTRY__LT',
    code: 'lt',
    country: Country.LITHUANIA,
    flag: '🇱🇹',
    callingCode: '370',
    value: t({ id: 'COUNTRY__LT' }),
  },
  lu: {
    key: 'COUNTRY__LU',
    code: 'lu',
    country: Country.LUXEMBOURG,
    flag: '🇱🇺',
    callingCode: '352',
    value: t({ id: 'COUNTRY__LU' }),
  },
  lv: {
    key: 'COUNTRY__LV',
    code: 'lv',
    country: Country.LATVIA,
    flag: '🇱🇻',
    callingCode: '371',
    value: t({ id: 'COUNTRY__LV' }),
  },
  ly: {
    key: 'COUNTRY__LY',
    code: 'ly',
    country: Country.LIBYAN_ARAB_JAMAHIRIYA,
    flag: '🇱🇾',
    callingCode: '218',
    value: t({ id: 'COUNTRY__LY' }),
  },
  ma: {
    key: 'COUNTRY__MA',
    code: 'ma',
    country: Country.MOROCCO,
    flag: '🇲🇦',
    callingCode: '212',
    value: t({ id: 'COUNTRY__MA' }),
  },
  mc: {
    key: 'COUNTRY__MC',
    code: 'mc',
    country: Country.MONACO,
    flag: '🇲🇨',
    callingCode: '377',
    value: t({ id: 'COUNTRY__MC' }),
  },
  md: {
    key: 'COUNTRY__MD',
    code: 'md',
    country: Country.MOLDOVA_REPUBLIC_OF,
    flag: '🇲🇩',
    callingCode: '373',
    value: t({ id: 'COUNTRY__MD' }),
  },
  me: {
    key: 'COUNTRY__ME',
    code: 'me',
    country: Country.MONTENEGRO,
    flag: '🇲🇪',
    callingCode: '382',
    value: t({ id: 'COUNTRY__ME' }),
  },
  mf: {
    key: 'COUNTRY__MF',
    code: 'mf',
    country: Country.SAINT_MARTIN,
    flag: '🇲🇫',
    callingCode: '590',
    value: t({ id: 'COUNTRY__MF' }),
  },
  mg: {
    key: 'COUNTRY__MG',
    code: 'mg',
    country: Country.MADAGASCAR,
    flag: '🇲🇬',
    callingCode: '261',
    value: t({ id: 'COUNTRY__MG' }),
  },
  mh: {
    key: 'COUNTRY__MH',
    code: 'mh',
    country: Country.MARSHALL_ISLANDS,
    flag: '🇲🇭',
    callingCode: '692',
    value: t({ id: 'COUNTRY__MH' }),
  },
  mk: {
    key: 'COUNTRY__MK',
    code: 'mk',
    country: Country.MACEDONIA_THE_FORMER_YUGOSLAV_REPUBLIC_OF,
    flag: '🇲🇰',
    callingCode: '389',
    value: t({ id: 'COUNTRY__MK' }),
  },
  ml: {
    key: 'COUNTRY__ML',
    code: 'ml',
    country: Country.MALI,
    flag: '🇲🇱',
    callingCode: '223',
    value: t({ id: 'COUNTRY__ML' }),
  },
  mm: {
    key: 'COUNTRY__MM',
    code: 'mm',
    country: Country.MYANMAR,
    flag: '🇲🇲',
    callingCode: '95',
    value: t({ id: 'COUNTRY__MM' }),
  },
  mn: {
    key: 'COUNTRY__MN',
    code: 'mn',
    country: Country.MONGOLIA,
    flag: '🇲🇳',
    callingCode: '976',
    value: t({ id: 'COUNTRY__MN' }),
  },
  mo: {
    key: 'COUNTRY__MO',
    code: 'mo',
    country: Country.MACAO,
    flag: '🇲🇴',
    callingCode: '853',
    value: t({ id: 'COUNTRY__MO' }),
  },
  mp: {
    key: 'COUNTRY__MP',
    code: 'mp',
    country: Country.NORTHERN_MARIANA_ISLANDS,
    flag: '🇲🇵',
    callingCode: '1',
    value: t({ id: 'COUNTRY__MP' }),
  },
  mq: {
    key: 'COUNTRY__MQ',
    code: 'mq',
    country: Country.MARTINIQUE,
    flag: '🇲🇶',
    callingCode: '596',
    value: t({ id: 'COUNTRY__MQ' }),
  },
  mr: {
    key: 'COUNTRY__MR',
    code: 'mr',
    country: Country.MAURITANIA,
    flag: '🇲🇷',
    callingCode: '222',
    value: t({ id: 'COUNTRY__MR' }),
  },
  ms: {
    key: 'COUNTRY__MS',
    code: 'ms',
    country: Country.MONTSERRAT,
    flag: '🇲🇸',
    callingCode: '1',
    value: t({ id: 'COUNTRY__MS' }),
  },
  mt: {
    key: 'COUNTRY__MT',
    code: 'mt',
    country: Country.MALTA,
    flag: '🇲🇹',
    callingCode: '356',
    value: t({ id: 'COUNTRY__MT' }),
  },
  mu: {
    key: 'COUNTRY__MU',
    code: 'mu',
    country: Country.MAURITIUS,
    flag: '🇲🇺',
    callingCode: '230',
    value: t({ id: 'COUNTRY__MU' }),
  },
  mv: {
    key: 'COUNTRY__MV',
    code: 'mv',
    country: Country.MALDIVES,
    flag: '🇲🇻',
    callingCode: '960',
    value: t({ id: 'COUNTRY__MV' }),
  },
  mw: {
    key: 'COUNTRY__MW',
    code: 'mw',
    country: Country.MALAWI,
    flag: '🇲🇼',
    callingCode: '265',
    value: t({ id: 'COUNTRY__MW' }),
  },
  mx: {
    key: 'COUNTRY__MX',
    code: 'mx',
    country: Country.MEXICO,
    flag: '🇲🇽',
    callingCode: '52',
    value: t({ id: 'COUNTRY__MX' }),
  },
  my: {
    key: 'COUNTRY__MY',
    code: 'my',
    country: Country.MALAYSIA,
    flag: '🇲🇾',
    callingCode: '60',
    value: t({ id: 'COUNTRY__MY' }),
  },
  mz: {
    key: 'COUNTRY__MZ',
    code: 'mz',
    country: Country.MOZAMBIQUE,
    flag: '🇲🇿',
    callingCode: '258',
    value: t({ id: 'COUNTRY__MZ' }),
  },
  na: {
    key: 'COUNTRY__NA',
    code: 'na',
    country: Country.NAMIBIA,
    flag: '🇳🇦',
    callingCode: '264',
    value: t({ id: 'COUNTRY__NA' }),
  },
  nc: {
    key: 'COUNTRY__NC',
    code: 'nc',
    country: Country.NEW_CALEDONIA,
    flag: '🇳🇨',
    callingCode: '687',
    value: t({ id: 'COUNTRY__NC' }),
  },
  ne: {
    key: 'COUNTRY__NE',
    code: 'ne',
    country: Country.NIGER,
    flag: '🇳🇪',
    callingCode: '227',
    value: t({ id: 'COUNTRY__NE' }),
  },
  nf: {
    key: 'COUNTRY__NF',
    code: 'nf',
    country: Country.NORFOLK_ISLAND,
    flag: '🇳🇫',
    callingCode: '672',
    value: t({ id: 'COUNTRY__NF' }),
  },
  ng: {
    key: 'COUNTRY__NG',
    code: 'ng',
    country: Country.NIGERIA,
    flag: '🇳🇬',
    callingCode: '234',
    value: t({ id: 'COUNTRY__NG' }),
  },
  ni: {
    key: 'COUNTRY__NI',
    code: 'ni',
    country: Country.NICARAGUA,
    flag: '🇳🇮',
    callingCode: '505',
    value: t({ id: 'COUNTRY__NI' }),
  },
  nl: {
    key: 'COUNTRY__NL',
    code: 'nl',
    country: Country.NETHERLANDS,
    flag: '🇳🇱',
    callingCode: '31',
    value: t({ id: 'COUNTRY__NL' }),
  },
  no: {
    key: 'COUNTRY__NO',
    code: 'no',
    country: Country.NORWAY,
    flag: '🇳🇴',
    callingCode: '47',
    value: t({ id: 'COUNTRY__NO' }),
  },
  np: {
    key: 'COUNTRY__NP',
    code: 'np',
    country: Country.NEPAL,
    flag: '🇳🇵',
    callingCode: '977',
    value: t({ id: 'COUNTRY__NP' }),
  },
  nr: {
    key: 'COUNTRY__NR',
    code: 'nr',
    country: Country.NAURU,
    flag: '🇳🇷',
    callingCode: '674',
    value: t({ id: 'COUNTRY__NR' }),
  },
  nu: {
    key: 'COUNTRY__NU',
    code: 'nu',
    country: Country.NIUE,
    flag: '🇳🇺',
    callingCode: '683',
    value: t({ id: 'COUNTRY__NU' }),
  },
  nz: {
    key: 'COUNTRY__NZ',
    code: 'nz',
    country: Country.NEW_ZEALAND,
    flag: '🇳🇿',
    callingCode: '64',
    value: t({ id: 'COUNTRY__NZ' }),
  },
  om: {
    key: 'COUNTRY__OM',
    code: 'om',
    country: Country.OMAN,
    flag: '🇴🇲',
    callingCode: '968',
    value: t({ id: 'COUNTRY__OM' }),
  },
  pa: {
    key: 'COUNTRY__PA',
    code: 'pa',
    country: Country.PANAMA,
    flag: '🇵🇦',
    callingCode: '507',
    value: t({ id: 'COUNTRY__PA' }),
  },
  pe: {
    key: 'COUNTRY__PE',
    code: 'pe',
    country: Country.PERU,
    flag: '🇵🇪',
    callingCode: '51',
    value: t({ id: 'COUNTRY__PE' }),
  },
  pf: {
    key: 'COUNTRY__PF',
    code: 'pf',
    country: Country.FRENCH_POLYNESIA,
    flag: '🇵🇫',
    callingCode: '689',
    value: t({ id: 'COUNTRY__PF' }),
  },
  pg: {
    key: 'COUNTRY__PG',
    code: 'pg',
    country: Country.PAPUA_NEW_GUINEA,
    flag: '🇵🇬',
    callingCode: '675',
    value: t({ id: 'COUNTRY__PG' }),
  },
  ph: {
    key: 'COUNTRY__PH',
    code: 'ph',
    country: Country.PHILIPPINES,
    flag: '🇵🇭',
    callingCode: '63',
    value: t({ id: 'COUNTRY__PH' }),
  },
  pk: {
    key: 'COUNTRY__PK',
    code: 'pk',
    country: Country.PAKISTAN,
    flag: '🇵🇰',
    callingCode: '92',
    value: t({ id: 'COUNTRY__PK' }),
  },
  pl: {
    key: 'COUNTRY__PL',
    code: 'pl',
    country: Country.POLAND,
    flag: '🇵🇱',
    callingCode: '48',
    value: t({ id: 'COUNTRY__PL' }),
  },
  pm: {
    key: 'COUNTRY__PM',
    code: 'pm',
    country: Country.SAINT_PIERRE_AND_MIQUELON,
    flag: '🇵🇲',
    callingCode: '508',
    value: t({ id: 'COUNTRY__PM' }),
  },
  pn: {
    key: 'COUNTRY__PN',
    code: 'pn',
    country: Country.PITCAIRN,
    flag: '🇵🇳',
    value: t({ id: 'COUNTRY__PN' }),
  },
  pr: {
    key: 'COUNTRY__PR',
    code: 'pr',
    country: Country.PUERTO_RICO,
    flag: '🇵🇷',
    callingCode: '1',
    value: t({ id: 'COUNTRY__PR' }),
  },
  ps: {
    key: 'COUNTRY__PS',
    code: 'ps',
    country: Country.PALESTINIAN_TERRITORY_OCCUPIED,
    flag: '🇵🇸',
    callingCode: '970',
    value: t({ id: 'COUNTRY__PS' }),
  },
  pt: {
    key: 'COUNTRY__PT',
    code: 'pt',
    country: Country.PORTUGAL,
    flag: '🇵🇹',
    callingCode: '351',
    value: t({ id: 'COUNTRY__PT' }),
  },
  pw: {
    key: 'COUNTRY__PW',
    code: 'pw',
    country: Country.PALAU,
    flag: '🇵🇼',
    callingCode: '680',
    value: t({ id: 'COUNTRY__PW' }),
  },
  py: {
    key: 'COUNTRY__PY',
    code: 'py',
    country: Country.PARAGUAY,
    flag: '🇵🇾',
    callingCode: '595',
    value: t({ id: 'COUNTRY__PY' }),
  },
  qa: {
    key: 'COUNTRY__QA',
    code: 'qa',
    country: Country.QATAR,
    flag: '🇶🇦',
    callingCode: '974',
    value: t({ id: 'COUNTRY__QA' }),
  },
  re: {
    key: 'COUNTRY__RE',
    code: 're',
    country: Country.REUNION,
    flag: '🇷🇪',
    callingCode: '262',
    value: t({ id: 'COUNTRY__RE' }),
  },
  ro: {
    key: 'COUNTRY__RO',
    code: 'ro',
    country: Country.ROMANIA,
    flag: '🇷🇴',
    callingCode: '40',
    value: t({ id: 'COUNTRY__RO' }),
  },
  rs: {
    key: 'COUNTRY__RS',
    code: 'rs',
    country: Country.SERBIA,
    flag: '🇷🇸',
    callingCode: '381',
    value: t({ id: 'COUNTRY__RS' }),
  },
  ru: {
    key: 'COUNTRY__RU',
    code: 'ru',
    country: Country.RUSSIA,
    flag: '🇷🇺',
    callingCode: '7',
    value: t({ id: 'COUNTRY__RU' }),
  },
  rw: {
    key: 'COUNTRY__RW',
    code: 'rw',
    country: Country.RWANDA,
    flag: '🇷🇼',
    callingCode: '250',
    value: t({ id: 'COUNTRY__RW' }),
  },
  sa: {
    key: 'COUNTRY__SA',
    code: 'sa',
    country: Country.SAUDI_ARABIA,
    flag: '🇸🇦',
    callingCode: '966',
    value: t({ id: 'COUNTRY__SA' }),
  },
  sb: {
    key: 'COUNTRY__SB',
    code: 'sb',
    country: Country.SOLOMON_ISLANDS,
    flag: '🇸🇧',
    callingCode: '677',
    value: t({ id: 'COUNTRY__SB' }),
  },
  sc: {
    key: 'COUNTRY__SC',
    code: 'sc',
    country: Country.SEYCHELLES,
    flag: '🇸🇨',
    callingCode: '248',
    value: t({ id: 'COUNTRY__SC' }),
  },
  sd: {
    key: 'COUNTRY__SD',
    code: 'sd',
    country: Country.SUDAN,
    flag: '🇸🇩',
    callingCode: '249',
    value: t({ id: 'COUNTRY__SD' }),
  },
  se: {
    key: 'COUNTRY__SE',
    code: 'se',
    country: Country.SWEDEN,
    flag: '🇸🇪',
    callingCode: '46',
    value: t({ id: 'COUNTRY__SE' }),
  },
  sg: {
    key: 'COUNTRY__SG',
    code: 'sg',
    country: Country.SINGAPORE,
    flag: '🇸🇬',
    callingCode: '65',
    value: t({ id: 'COUNTRY__SG' }),
  },
  sh: {
    key: 'COUNTRY__SH',
    code: 'sh',
    country: Country.SAINT_HELENA,
    flag: '🇸🇭',
    callingCode: '290',
    value: t({ id: 'COUNTRY__SH' }),
  },
  si: {
    key: 'COUNTRY__SI',
    code: 'si',
    country: Country.SLOVENIA,
    flag: '🇸🇮',
    callingCode: '386',
    value: t({ id: 'COUNTRY__SI' }),
  },
  sj: {
    key: 'COUNTRY__SJ',
    code: 'sj',
    country: Country.SVALBARD_AND_JAN_MAYEN,
    flag: '🇸🇯',
    callingCode: '47',
    value: t({ id: 'COUNTRY__SJ' }),
  },
  sk: {
    key: 'COUNTRY__SK',
    code: 'sk',
    country: Country.SLOVAKIA,
    flag: '🇸🇰',
    callingCode: '421',
    value: t({ id: 'COUNTRY__SK' }),
  },
  sl: {
    key: 'COUNTRY__SL',
    code: 'sl',
    country: Country.SIERRA_LEONE,
    flag: '🇸🇱',
    callingCode: '232',
    value: t({ id: 'COUNTRY__SL' }),
  },
  sm: {
    key: 'COUNTRY__SM',
    code: 'sm',
    country: Country.SAN_MARINO,
    flag: '🇸🇲',
    callingCode: '378',
    value: t({ id: 'COUNTRY__SM' }),
  },
  sn: {
    key: 'COUNTRY__SN',
    code: 'sn',
    country: Country.SENEGAL,
    flag: '🇸🇳',
    callingCode: '221',
    value: t({ id: 'COUNTRY__SN' }),
  },
  so: {
    key: 'COUNTRY__SO',
    code: 'so',
    country: Country.SOMALIA,
    flag: '🇸🇴',
    callingCode: '252',
    value: t({ id: 'COUNTRY__SO' }),
  },
  sr: {
    key: 'COUNTRY__SR',
    code: 'sr',
    country: Country.SURINAME,
    flag: '🇸🇷',
    callingCode: '597',
    value: t({ id: 'COUNTRY__SR' }),
  },
  st: {
    key: 'COUNTRY__ST',
    code: 'st',
    country: Country.SAO_TOME_AND_PRINCIPE,
    flag: '🇸🇹',
    callingCode: '239',
    value: t({ id: 'COUNTRY__ST' }),
  },
  sv: {
    key: 'COUNTRY__SV',
    code: 'sv',
    country: Country.EL_SALVADOR,
    flag: '🇸🇻',
    callingCode: '503',
    value: t({ id: 'COUNTRY__SV' }),
  },
  sy: {
    key: 'COUNTRY__SY',
    code: 'sy',
    country: Country.SYRIAN_ARAB_REPUBLIC,
    flag: '🇸🇾',
    callingCode: '963',
    value: t({ id: 'COUNTRY__SY' }),
  },
  sz: {
    key: 'COUNTRY__SZ',
    code: 'sz',
    country: Country.SWAZILAND,
    flag: '🇸🇿',
    callingCode: '268',
    value: t({ id: 'COUNTRY__SZ' }),
  },
  tc: {
    key: 'COUNTRY__TC',
    code: 'tc',
    country: Country.TURKS_AND_CAICOS_ISLANDS,
    flag: '🇹🇨',
    callingCode: '1',
    value: t({ id: 'COUNTRY__TC' }),
  },
  td: {
    key: 'COUNTRY__TD',
    code: 'td',
    country: Country.CHAD,
    flag: '🇹🇩',
    callingCode: '235',
    value: t({ id: 'COUNTRY__TD' }),
  },
  tf: {
    key: 'COUNTRY__TF',
    code: 'tf',
    country: Country.FRENCH_SOUTHERN_TERRITORIES,
    flag: '🇹🇫',
    value: t({ id: 'COUNTRY__TF' }),
  },
  tg: {
    key: 'COUNTRY__TG',
    code: 'tg',
    country: Country.TOGO,
    flag: '🇹🇬',
    callingCode: '228',
    value: t({ id: 'COUNTRY__TG' }),
  },
  th: {
    key: 'COUNTRY__TH',
    code: 'th',
    country: Country.THAILAND,
    flag: '🇹🇭',
    callingCode: '66',
    value: t({ id: 'COUNTRY__TH' }),
  },
  tj: {
    key: 'COUNTRY__TJ',
    code: 'tj',
    country: Country.TAJIKISTAN,
    flag: '🇹🇯',
    callingCode: '992',
    value: t({ id: 'COUNTRY__TJ' }),
  },
  tk: {
    key: 'COUNTRY__TK',
    code: 'tk',
    country: Country.TOKELAU,
    flag: '🇹🇰',
    callingCode: '690',
    value: t({ id: 'COUNTRY__TK' }),
  },
  tl: {
    key: 'COUNTRY__TL',
    code: 'tl',
    country: Country.TIMOR_LESTE,
    flag: '🇹🇱',
    callingCode: '670',
    value: t({ id: 'COUNTRY__TL' }),
  },
  tm: {
    key: 'COUNTRY__TM',
    code: 'tm',
    country: Country.TURKMENISTAN,
    flag: '🇹🇲',
    callingCode: '993',
    value: t({ id: 'COUNTRY__TM' }),
  },
  tn: {
    key: 'COUNTRY__TN',
    code: 'tn',
    country: Country.TUNISIA,
    flag: '🇹🇳',
    callingCode: '216',
    value: t({ id: 'COUNTRY__TN' }),
  },
  to: {
    key: 'COUNTRY__TO',
    code: 'to',
    country: Country.TONGA,
    flag: '🇹🇴',
    callingCode: '676',
    value: t({ id: 'COUNTRY__TO' }),
  },
  tr: {
    key: 'COUNTRY__TR',
    code: 'tr',
    country: Country.TURKEY,
    flag: '🇹🇷',
    callingCode: '90',
    value: t({ id: 'COUNTRY__TR' }),
  },
  tt: {
    key: 'COUNTRY__TT',
    code: 'tt',
    country: Country.TRINIDAD_AND_TOBAGO,
    flag: '🇹🇹',
    callingCode: '1',
    value: t({ id: 'COUNTRY__TT' }),
  },
  tv: {
    key: 'COUNTRY__TV',
    code: 'tv',
    country: Country.TUVALU,
    flag: '🇹🇻',
    callingCode: '688',
    value: t({ id: 'COUNTRY__TV' }),
  },
  tw: {
    key: 'COUNTRY__TW',
    code: 'tw',
    country: Country.TAIWAN,
    flag: '🇹🇼',
    callingCode: '886',
    value: t({ id: 'COUNTRY__TW' }),
  },
  tz: {
    key: 'COUNTRY__TZ',
    code: 'tz',
    country: Country.TANZANIA_UNITED_REPUBLIC_OF,
    flag: '🇹🇿',
    callingCode: '255',
    value: t({ id: 'COUNTRY__TZ' }),
  },
  ua: {
    key: 'COUNTRY__UA',
    code: 'ua',
    country: Country.UKRAINE,
    flag: '🇺🇦',
    callingCode: '380',
    value: t({ id: 'COUNTRY__UA' }),
  },
  ug: {
    key: 'COUNTRY__UG',
    code: 'ug',
    country: Country.UGANDA,
    flag: '🇺🇬',
    callingCode: '256',
    value: t({ id: 'COUNTRY__UG' }),
  },
  um: {
    key: 'COUNTRY__UM',
    code: 'um',
    country: Country.UNITED_STATES_MINOR_OUTLYING_ISLANDS,
    flag: '🇺🇲',
    value: t({ id: 'COUNTRY__UM' }),
  },
  us: {
    key: 'COUNTRY__US',
    code: 'us',
    country: Country.USA,
    flag: '🇺🇸',
    callingCode: '1',
    value: t({ id: 'COUNTRY__US' }),
  },
  uy: {
    key: 'COUNTRY__UY',
    code: 'uy',
    country: Country.URUGUAY,
    flag: '🇺🇾',
    callingCode: '598',
    value: t({ id: 'COUNTRY__UY' }),
  },
  uz: {
    key: 'COUNTRY__UZ',
    code: 'uz',
    country: Country.UZBEKISTAN,
    flag: '🇺🇿',
    callingCode: '998',
    value: t({ id: 'COUNTRY__UZ' }),
  },
  va: {
    key: 'COUNTRY__VA',
    code: 'va',
    country: Country.HOLY_SEE_VATICAN_CITY_STATE,
    flag: '🇻🇦',
    callingCode: '39',
    value: t({ id: 'COUNTRY__VA' }),
  },
  vc: {
    key: 'COUNTRY__VC',
    code: 'vc',
    country: Country.SAINT_VINCENT_AND_THE_GRENADINES,
    flag: '🇻🇨',
    callingCode: '1',
    value: t({ id: 'COUNTRY__VC' }),
  },
  ve: {
    key: 'COUNTRY__VE',
    code: 've',
    country: Country.VENEZUELA,
    flag: '🇻🇪',
    callingCode: '58',
    value: t({ id: 'COUNTRY__VE' }),
  },
  vg: {
    key: 'COUNTRY__VG',
    code: 'vg',
    country: Country.VIRGIN_ISLANDS_BRITISH,
    flag: '🇻🇬',
    callingCode: '1',
    value: t({ id: 'COUNTRY__VG' }),
  },
  vi: {
    key: 'COUNTRY__VI',
    code: 'vi',
    country: Country.VIRGIN_ISLANDS_US,
    flag: '🇻🇮',
    callingCode: '1',
    value: t({ id: 'COUNTRY__VI' }),
  },
  vn: {
    key: 'COUNTRY__VN',
    code: 'vn',
    country: Country.VIET_NAM,
    flag: '🇻🇳',
    callingCode: '84',
    value: t({ id: 'COUNTRY__VN' }),
  },
  vu: {
    key: 'COUNTRY__VU',
    code: 'vu',
    country: Country.VANUATU,
    flag: '🇻🇺',
    callingCode: '678',
    value: t({ id: 'COUNTRY__VU' }),
  },
  wf: {
    key: 'COUNTRY__WF',
    code: 'wf',
    country: Country.WALLIS_AND_FUTUNA,
    flag: '🇼🇫',
    callingCode: '681',
    value: t({ id: 'COUNTRY__WF' }),
  },
  ws: {
    key: 'COUNTRY__WS',
    code: 'ws',
    country: Country.SAMOA,
    flag: '🇼🇸',
    callingCode: '685',
    value: t({ id: 'COUNTRY__WS' }),
  },
  ye: {
    key: 'COUNTRY__YE',
    code: 'ye',
    country: Country.YEMEN,
    flag: '🇾🇪',
    callingCode: '967',
    value: t({ id: 'COUNTRY__YE' }),
  },
  yt: {
    key: 'COUNTRY__YT',
    code: 'yt',
    country: Country.MAYOTTE,
    flag: '🇾🇹',
    callingCode: '262',
    value: t({ id: 'COUNTRY__YT' }),
  },
  za: {
    key: 'COUNTRY__ZA',
    code: 'za',
    country: Country.SOUTH_AFRICA,
    flag: '🇿🇦',
    callingCode: '27',
    value: t({ id: 'COUNTRY__ZA' }),
  },
  zm: {
    key: 'COUNTRY__ZM',
    code: 'zm',
    country: Country.ZAMBIA,
    flag: '🇿🇲',
    callingCode: '260',
    value: t({ id: 'COUNTRY__ZM' }),
  },
  zw: {
    key: 'COUNTRY__ZW',
    code: 'zw',
    country: Country.ZIMBABWE,
    flag: '🇿🇼',
    callingCode: '263',
    value: t({ id: 'COUNTRY__ZW' }),
  },
}

export const countryCodeByCountry: Map<Country, CountryCode> = new Map([
  [5, 'ad'],
  [229, 'ae'],
  [1, 'af'],
  [9, 'ag'],
  [7, 'ai'],
  [2, 'al'],
  [11, 'am'],
  [6, 'ao'],
  [8, 'aq'],
  [10, 'ar'],
  [4, 'as'],
  [14, 'at'],
  [13, 'au'],
  [12, 'aw'],
  [15, 'az'],
  [27, 'ba'],
  [19, 'bb'],
  [18, 'bd'],
  [21, 'be'],
  [34, 'bf'],
  [33, 'bg'],
  [17, 'bh'],
  [35, 'bi'],
  [23, 'bj'],
  [182, 'bl'],
  [24, 'bm'],
  [32, 'bn'],
  [26, 'bo'],
  [30, 'br'],
  [16, 'bs'],
  [25, 'bt'],
  [29, 'bv'],
  [28, 'bw'],
  [20, 'by'],
  [22, 'bz'],
  [38, 'ca'],
  [46, 'cc'],
  [50, 'cd'],
  [41, 'cf'],
  [49, 'cg'],
  [211, 'ch'],
  [53, 'ci'],
  [51, 'ck'],
  [43, 'cl'],
  [37, 'cm'],
  [44, 'cn'],
  [47, 'co'],
  [52, 'cr'],
  [55, 'cu'],
  [39, 'cv'],
  [45, 'cx'],
  [56, 'cy'],
  [57, 'cz'],
  [80, 'de'],
  [59, 'dj'],
  [58, 'dk'],
  [60, 'dm'],
  [61, 'do'],
  [3, 'dz'],
  [62, 'ec'],
  [67, 'ee'],
  [63, 'eg'],
  [241, 'eh'],
  [66, 'er'],
  [204, 'es'],
  [68, 'et'],
  [72, 'fi'],
  [71, 'fj'],
  [69, 'fk'],
  [142, 'fm'],
  [70, 'fo'],
  [73, 'fr'],
  [77, 'ga'],
  [230, 'gb'],
  [85, 'gd'],
  [79, 'ge'],
  [74, 'gf'],
  [89, 'gg'],
  [81, 'gh'],
  [82, 'gi'],
  [84, 'gl'],
  [78, 'gm'],
  [90, 'gn'],
  [86, 'gp'],
  [65, 'gq'],
  [83, 'gr'],
  [203, 'gs'],
  [88, 'gt'],
  [87, 'gu'],
  [91, 'gw'],
  [92, 'gy'],
  [97, 'hk'],
  [94, 'hm'],
  [96, 'hn'],
  [54, 'hr'],
  [93, 'ht'],
  [98, 'hu'],
  [101, 'id'],
  [104, 'ie'],
  [106, 'il'],
  [105, 'im'],
  [100, 'in'],
  [31, 'io'],
  [103, 'iq'],
  [102, 'ir'],
  [99, 'is'],
  [107, 'it'],
  [110, 'je'],
  [108, 'jm'],
  [111, 'jo'],
  [109, 'jp'],
  [113, 'ke'],
  [118, 'kg'],
  [36, 'kh'],
  [114, 'ki'],
  [48, 'km'],
  [184, 'kn'],
  [115, 'kp'],
  [116, 'kr'],
  [117, 'kw'],
  [40, 'ky'],
  [112, 'kz'],
  [119, 'la'],
  [121, 'lb'],
  [185, 'lc'],
  [125, 'li'],
  [205, 'lk'],
  [123, 'lr'],
  [122, 'ls'],
  [126, 'lt'],
  [127, 'lu'],
  [120, 'lv'],
  [124, 'ly'],
  [148, 'ma'],
  [144, 'mc'],
  [143, 'md'],
  [146, 'me'],
  [186, 'mf'],
  [130, 'mg'],
  [136, 'mh'],
  [129, 'mk'],
  [134, 'ml'],
  [150, 'mm'],
  [145, 'mn'],
  [128, 'mo'],
  [162, 'mp'],
  [137, 'mq'],
  [138, 'mr'],
  [147, 'ms'],
  [135, 'mt'],
  [139, 'mu'],
  [133, 'mv'],
  [131, 'mw'],
  [141, 'mx'],
  [132, 'my'],
  [149, 'mz'],
  [151, 'na'],
  [155, 'nc'],
  [158, 'ne'],
  [161, 'nf'],
  [159, 'ng'],
  [157, 'ni'],
  [154, 'nl'],
  [163, 'no'],
  [153, 'np'],
  [152, 'nr'],
  [160, 'nu'],
  [156, 'nz'],
  [164, 'om'],
  [168, 'pa'],
  [171, 'pe'],
  [75, 'pf'],
  [169, 'pg'],
  [172, 'ph'],
  [165, 'pk'],
  [174, 'pl'],
  [187, 'pm'],
  [173, 'pn'],
  [176, 'pr'],
  [167, 'ps'],
  [175, 'pt'],
  [166, 'pw'],
  [170, 'py'],
  [177, 'qa'],
  [178, 're'],
  [179, 'ro'],
  [194, 'rs'],
  [180, 'ru'],
  [181, 'rw'],
  [192, 'sa'],
  [200, 'sb'],
  [195, 'sc'],
  [206, 'sd'],
  [210, 'se'],
  [197, 'sg'],
  [183, 'sh'],
  [199, 'si'],
  [208, 'sj'],
  [198, 'sk'],
  [196, 'sl'],
  [190, 'sm'],
  [193, 'sn'],
  [201, 'so'],
  [207, 'sr'],
  [191, 'st'],
  [64, 'sv'],
  [212, 'sy'],
  [209, 'sz'],
  [225, 'tc'],
  [42, 'td'],
  [76, 'tf'],
  [218, 'tg'],
  [216, 'th'],
  [214, 'tj'],
  [219, 'tk'],
  [217, 'tl'],
  [224, 'tm'],
  [222, 'tn'],
  [220, 'to'],
  [223, 'tr'],
  [221, 'tt'],
  [226, 'tv'],
  [213, 'tw'],
  [215, 'tz'],
  [228, 'ua'],
  [227, 'ug'],
  [232, 'um'],
  [231, 'us'],
  [233, 'uy'],
  [234, 'uz'],
  [95, 'va'],
  [188, 'vc'],
  [236, 've'],
  [238, 'vg'],
  [239, 'vi'],
  [237, 'vn'],
  [235, 'vu'],
  [240, 'wf'],
  [189, 'ws'],
  [242, 'ye'],
  [140, 'yt'],
  [202, 'za'],
  [243, 'zm'],
  [244, 'zw'],
])
