import { AuthTransportProvider } from '@jotta/grpc-connect-client/provider'
import { Upgrade } from './Upgrade'
import { usePaymentInfo } from '../payment/usePaymentInformation'
import { exhaustiveGuard } from '@jotta/utils/exhaustive'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { Redirect } from '@jotta/ui/Redirect'

export const UpgradeRoute: React.FC = () => {
  const { data } = usePaymentInfo()

  const changeSubscriptionOption = data?.changeSubscriptionOption
  if (!changeSubscriptionOption || !changeSubscriptionOption.case) {
    return <LoadingOverlay open />
  }

  switch (changeSubscriptionOption.case) {
    case 'contactBusinessAdmin':
    case 'manageInAppStore':
    case 'manageInPlayStore':
    case 'contactReseller':
    case 'contactSupport':
    case 'saSubscription':
    case 'convertFromReseller':
    case 'manageSubscriptionExternally':
      return <Redirect to="/web/billing" replace />
    case 'normalUpgrade':
    case 'manageInBusinessPages':
    case 'contactFamilyOwner':
      return (
        <AuthTransportProvider>
          <Upgrade />
        </AuthTransportProvider>
      )
    default:
      return exhaustiveGuard(changeSubscriptionOption)
  }
}
