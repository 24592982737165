import type { HTMLAttributes } from 'react'
import {
  type ChangeEventHandler,
  type ClipboardEventHandler,
  type KeyboardEventHandler,
  useCallback,
  useRef,
} from 'react'
import { Input } from '../Input/Input'

export interface CodeInputProps extends HTMLAttributes<HTMLElement> {
  codeLength: number
  code: string
  onCodeChange: (code: string) => void
  onCodeComplete?: () => void
}

export function CodeInput({
  codeLength,
  code,
  autoFocus,
  onCodeChange,
  onCodeComplete,
  className,
  ...rest
}: CodeInputProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const chars = [...Array(codeLength)].map(
    (_, index) => code.charAt(index) || '',
  )
  const setFocus = useCallback(
    (i: number) => {
      if (!containerRef.current) {
        return
      }

      if (i < 0 || i >= codeLength) {
        if (document.activeElement?.parentElement === containerRef.current) {
          const el = document.activeElement as HTMLElement
          el.blur()
        }
        return
      }

      const el = containerRef.current.children.item(i) as HTMLInputElement
      el.focus()
      el.select()
    },
    [codeLength],
  )

  const setElement = useCallback(
    (value: string, index = 0) => {
      const prefix = code.substring(0, index)
      const postfix = code.substring(index + 1, code.length)
      const newCode = `${prefix}${value}${postfix}`.substring(0, codeLength)
      onCodeChange(newCode)
      const number = prefix.length + value.length
      if (onCodeComplete && number === codeLength) {
        setTimeout(onCodeComplete, 1)
      } else {
        setFocus(number)
      }
    },
    [onCodeChange, chars, setFocus, onCodeComplete],
  )

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    e => {
      const i = Number.parseInt(e.target.dataset.index || '', 10)
      setElement(e.target.value.slice(0, 1), i)
    },
    [setElement, setFocus],
  )

  const handleKeyDown = useCallback<KeyboardEventHandler>(
    e => {
      const target = e.currentTarget as HTMLInputElement
      const i = Number.parseInt(target.dataset.index || '', 10)

      switch (e.key) {
        case 'ArrowLeft':
          e.preventDefault()
          setFocus(i - 1)
          break

        case 'Backspace':
          e.preventDefault()
          if (chars[i]) {
            setElement('', i)
          } else if (i > 0) {
            setElement('', i - 1)
          }
          break

        case 'ArrowRight':
          setFocus(i + 1)
          e.preventDefault()
          break

        case ' ':
          e.preventDefault()
          break

        default:
          target.select()
          break
      }
    },
    [setElement, chars, setFocus],
  )

  const handlePaste = useCallback<ClipboardEventHandler>(
    e => {
      e.preventDefault()
      const target = e.currentTarget as HTMLInputElement
      const i = Number.parseInt(target.dataset.index || '', 10)
      const value = e.clipboardData
        .getData('Text')
        .replaceAll(' ', '')
        .substring(0, codeLength - i)
      setElement(value, i)
    },
    [setElement, setFocus, codeLength],
  )

  return (
    <div className={`flex gap-2 ${className}`} ref={containerRef} {...rest}>
      {chars.map((code, i) => (
        <input
          type="text"
          className="input w-8 p-2 text-center"
          autoFocus={i === 0 && autoFocus}
          autoCorrect="off"
          autoCapitalize="none"
          maxLength={1}
          key={i}
          value={code}
          data-index={i}
          onInput={handleChange}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
        />
      ))}
    </div>
  )
}
