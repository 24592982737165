import {
  toPlainMessage,
  type PartialMessage,
  type PlainMessage,
} from '@bufbuild/protobuf'
import { callUnaryMethod } from '@connectrpc/connect-query'
import { authTransport } from '@jotta/grpc-connect-client/transport'
import type {
  BusinessUser,
  CreateBusinessUserRequest,
  PatchBusinessRequest,
  PatchBusinessUserRequest,
  RemoveBusinessUserRequest,
  TerminateBusinessRequest,
} from '@jotta/grpc-connect-openapi/business'
import {
  createBusinessUser,
  listUsers,
  patchBusiness,
  patchBusinessUser,
  removeBusinessUser,
  terminateBusiness,
} from '@jotta/grpc-connect-openapi/businessQuery'
import { checkEmail } from '@jotta/grpc-connect-openapi/signupQuery'
import { AccountType } from '@jotta/grpc-connect-openapi/customer'
import { getCustomer } from '@jotta/grpc-connect-openapi/customerQuery'
import {
  getOrder,
  getPaymentInfo,
  listOrders,
} from '@jotta/grpc-connect-openapi/esm/openapi/subscription/payment.v2-SubscriptionService_connectquery'
import type { GetOrderRequest } from '@jotta/grpc-connect-openapi/esm/openapi/subscription/payment.v2_pb'
import { queryClient } from '@jotta/query'
import Debug from 'debug'
import { router } from 'react-query-kit'
import { BizMockTransport } from './__mock__/BizMockTransport'
import type { CheckEmailRequest } from '@jotta/grpc-connect-openapi/esm/openapi/signup/v1/signup.v1_pb'
import { getConfig } from '@jotta/grpc-connect-openapi/esm/openapi/config/v2/config.v2-ConfigService_connectquery'
const debug = Debug('jotta:biz:api')
export const USE_MOCK_TRANSPORT = false
export const bizTransport = USE_MOCK_TRANSPORT
  ? BizMockTransport
  : authTransport
export interface ExtendedBusinessUser extends PlainMessage<BusinessUser> {
  isCurrentUser: boolean
}
export const bizApi = router('biz', {
  subscription: {
    getPaymentInfo: router.query({
      fetcher: async () => {
        const paymentInfo = await callUnaryMethod(
          getPaymentInfo,
          {},
          {
            transport: bizTransport,
          },
        )
        return paymentInfo
      },
    }),
    getBillingHistory: router.query({
      fetcher: async () => {
        const data = await callUnaryMethod(
          listOrders,
          {},
          {
            transport: bizTransport,
          },
        )
        return data.orders
      },
    }),
    getOrder: router.query({
      fetcher: async (args: PartialMessage<GetOrderRequest>) => {
        const { order } = await callUnaryMethod(getOrder, args, {
          transport: bizTransport,
        })
        if (!order) {
          throw new Error('Order undefined')
        }
        return order
      },
    }),
  },
  config: {
    get: router.query({
      fetcher: async () => {
        const config = await callUnaryMethod(
          getConfig,
          {},
          {
            transport: bizTransport,
          },
        )
        return config
      },
    }),
  },
  company: {
    get: router.query({
      fetcher: async () => {
        const { customer } = await callUnaryMethod(
          getCustomer,
          {},
          {
            transport: bizTransport,
          },
        )
        if (!customer) {
          throw new Error('Customer not found')
        }
        if (customer.accountType !== AccountType.BUSINESS_ADMIN) {
          throw new Error('Access denied')
        }
        const company = customer.business
        if (!company) {
          throw new Error('customer.business undefined')
        }
        return {
          username: customer.username,
          company,
          customer,
        }
      },
    }),
    update: router.mutation({
      mutationFn: async (args: PartialMessage<PatchBusinessRequest>) => {
        await callUnaryMethod(patchBusiness, args, {
          transport: bizTransport,
        })
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: bizApi.company.get.getKey(),
        })
      },
    }),
    terminate: router.mutation({
      mutationFn: async (args: PartialMessage<TerminateBusinessRequest>) => {
        const response = await callUnaryMethod(terminateBusiness, args, {
          transport: bizTransport,
        })
        return response
      },
      onSuccess: async (data, variables) => {
        debug('Removed user', data, variables)
        await queryClient.invalidateQueries({
          queryKey: bizApi.company.getKey(),
        })
      },
    }),
  },

  user: {
    byId: router.query({
      fetcher: async ({
        username,
      }: {
        username: string
      }): Promise<ExtendedBusinessUser> => {
        const { users } = await callUnaryMethod(
          listUsers,
          {},
          { transport: bizTransport },
        )
        const user = users.find(u => u.username === username)
        if (!user) {
          throw new Error('User not found')
        }
        return {
          ...toPlainMessage(user),
          isCurrentUser: true,
        }
      },
    }),
    list: router.query({
      fetcher: async ({ username }: { username?: string } = {}, context) => {
        const { users } = await callUnaryMethod(
          listUsers,
          {},
          { transport: bizTransport },
        )
        return users.map<ExtendedBusinessUser>(user => ({
          ...toPlainMessage(user),
          isCurrentUser: user.username === username,
        }))
      },
    }),
    checkEmal: router.mutation({
      mutationFn: async (args: PartialMessage<CheckEmailRequest>) => {
        const response = await callUnaryMethod(checkEmail, args, {
          transport: bizTransport,
        })
        return response
      },
    }),
    patch: router.mutation({
      mutationFn: async (args: PartialMessage<PatchBusinessUserRequest>) => {
        await callUnaryMethod(patchBusinessUser, args, {
          transport: bizTransport,
        })
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: bizApi.company.get.getKey(),
        })
      },
    }),
    create: router.mutation({
      mutationFn: async (args: PartialMessage<CreateBusinessUserRequest>) => {
        await callUnaryMethod(createBusinessUser, args, {
          transport: bizTransport,
        })
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: bizApi.user.getKey(),
        })
      },
    }),
    remove: router.mutation({
      mutationFn: async (args: PartialMessage<RemoveBusinessUserRequest>) => {
        const response = await callUnaryMethod(removeBusinessUser, args, {
          transport: bizTransport,
        })
        return response
      },
      onSuccess: async (data, variables) => {
        debug('Removed user', data, variables)
        await queryClient.invalidateQueries({
          queryKey: bizApi.user.getKey(),
        })
      },
    }),
  },
})

export function useBizUsers() {
  const { data } = bizApi.company.get.useSuspenseQuery()
  const { data: users } = bizApi.user.list.useSuspenseQuery({
    variables: { username: data.username },
  })
  const maxUserCount = Number(data.company.maxUserCount)
  return {
    users,
    maxUserCount,
    canCreateUser: maxUserCount < 0 || users.length < maxUserCount,
  }
}
