import { ErrorPage } from '@jotta/ui/ErrorPage'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { ErrorBoundary } from '@sentry/react'
import { Suspense } from 'react'
import { AppLayoutSignup } from '@jotta/ui/AppLayoutSignup'
import { LoginPage } from './LoginPage'
import { useAndConsumeSearchParam } from '@jotta/router'
import { useSearchParams } from 'react-router-dom'
import { Redirect } from '@jotta/ui/Redirect'

export function LoginRoute() {
  const token = useAndConsumeSearchParam('federation_token')
  const [searchParams] = useSearchParams()
  const loginId = searchParams.get('loginId')

  // TODO should ErrorBoundary+Suspense be included in AppLayoutSignup?
  return (
    <AppLayoutSignup>
      <ErrorBoundary
        fallback={({ error, resetError }) => (
          <ErrorPage error={error} resetError={resetError} />
        )}
      >
        <Suspense fallback={<LoadingOverlay open />}>
          {loginId ? (
            <LoginPage token={token} loginId={loginId} />
          ) : (
            <Redirect to="/api/login?redirect_uri=/" reload replace />
          )}
        </Suspense>
      </ErrorBoundary>
    </AppLayoutSignup>
  )
}
