import { t, Trans } from '@lingui/macro'
import { BizWidget } from '../widgets/BizWidget'
import {
  SubscriptionInterval,
  type GetPaymentInfoResponse,
  type PaymentInfo,
} from '@jotta/grpc-connect-openapi/esm/openapi/subscription/payment.v2_pb'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { formatPriceConnect } from '@jotta/settings/formatPrice'
import { useLocale } from '@jotta/i18n'
import SvgCreditCard from '@jotta/design-system/icons/test/svg/CreditCard.svg'
import SvgList from '@jotta/design-system/icons/test/svg/ListDashes.svg'
import type { PlainMessage } from '@bufbuild/protobuf'
export function BizPaymentInformation({
  subscription,
}: {
  subscription: GetPaymentInfoResponse
}) {
  const locale = useLocale()
  return (
    <BizWidget title={t`Billing`}>
      <dl className="dl">
        {!!subscription.paymentInfo && (
          <>
            <dt>
              <Trans>Payment type</Trans>
            </dt>
            <dd>
              <DisplayPaymentMethod
                paymentMethod={subscription.paymentInfo?.paymentMethod.case}
              />
            </dd>
          </>
        )}
        {!!subscription.credit && (
          <>
            <dt>
              <Trans>Credit</Trans>
            </dt>
            <dd>{formatPriceConnect(subscription.credit, locale)}</dd>
          </>
        )}
        {!!subscription.discountPercentage && (
          <>
            <dt>
              <Trans>Discount</Trans>
            </dt>
            <dd>{subscription.discountPercentage}%</dd>
          </>
        )}
        {!!subscription.price && (
          <>
            <dt>
              <Trans>Payment Amount</Trans>
            </dt>
            <dd>
              <DisplaySubscriptionPrice
                subscriptionInterval={subscription.subscriptionInterval}
                price={subscription.price}
              />
            </dd>
          </>
        )}
        {!!subscription.renewalDateMillis && (
          <>
            <dt>
              <Trans>Next payment</Trans>
            </dt>
            <dd>{dayjs(subscription.renewalDateMillis.value).format('L')}</dd>
          </>
        )}
      </dl>
      <div className="mt-6 flex flex-wrap gap-x-4 gap-y-2">
        <Link to="billingHistory" className="btn btn-outline">
          <SvgCreditCard />
          <Trans>Change credit card</Trans>
        </Link>
        <Link to="billingHistory" className="btn btn-outline">
          <SvgList />
          <Trans>Billing history</Trans>
        </Link>
      </div>
    </BizWidget>
  )
}
export function DisplayPaymentMethod({
  paymentMethod,
}: {
  paymentMethod: PaymentInfo['paymentMethod']['case']
}) {
  switch (paymentMethod) {
    case 'noPayment':
      return t`Free subscription - no payment`
    case 'card':
      return t`Card`
    case 'vippsBilling':
      return 'Vipps'
    default:
      return t`Unknown payment method`
  }
}
export function DisplaySubscriptionPrice({
  price,
  subscriptionInterval,
}: Pick<
  PlainMessage<GetPaymentInfoResponse>,
  'price' | 'subscriptionInterval'
>) {
  const locale = useLocale()
  if (!price) {
    return null
  }
  const priceString = formatPriceConnect(price, locale)
  let intervalString = ''
  switch (subscriptionInterval) {
    case SubscriptionInterval.NOT_SET:
      return priceString
    case SubscriptionInterval.MONTHLY:
      intervalString = t`per.MONTHLY`
      break
    case SubscriptionInterval.YEARLY:
      intervalString = t`per.YEARLY`
      break
    case SubscriptionInterval.FIXED_1MND:
      intervalString = t`per.MONTHLY`
      break
    case SubscriptionInterval.FIXED_2MND:
      intervalString = t`per.FIXED_2MND`
      break
    case SubscriptionInterval.FIXED_3MND:
      intervalString = t`per.FIXED_3MND`
      break
    case SubscriptionInterval.FIXED_6MND:
      intervalString = t`per.FIXED_6MND`
      break
    case SubscriptionInterval.FIXED_12MND:
      intervalString = t`per.FIXED_12MND`
      break
    case SubscriptionInterval.FIXED_24MND:
      intervalString = t`per.FIXED_24MND`
      break
    case SubscriptionInterval.FIXED_36MND:
      intervalString = t`per.FIXED_36MND`
      break
    case SubscriptionInterval.FIXED_60MND:
      intervalString = t`per.FIXED_60MND`
      break
  }
  return `${priceString} ${intervalString}`
}
