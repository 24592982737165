import { BizTerminateAccountForm } from '../components/BizTerminateAccountForm'
import { BizPaymentInformation } from '../components/BizPaymentInformation'
import { BizCompanyInformationForm } from '../components/BizCompanyInformationForm'
import { BizSubscriptionForm } from '../components/BizSubscriptionForm'
import { useBizContext } from '../BizLayout'
import { useBizUsers } from '../BizApi'

export function BizSettingsRoute() {
  const { subscription } = useBizContext()
  const { maxUserCount, users } = useBizUsers()
  const userCount = users.length
  return (
    <>
      <BizCompanyInformationForm />
      <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
        <BizSubscriptionForm
          subscription={subscription}
          userCount={userCount}
          maxUserCount={maxUserCount}
        />
        <BizPaymentInformation subscription={subscription} />
        <BizTerminateAccountForm />
      </div>
    </>
  )
}
