/** External */
import Debug from 'debug'

import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { Headline2 } from '@jotta/uinext/Headline'
import { Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useFetchPhotoIds } from '../../api/photosApi'
import { usePhoto } from '../../api/usePhoto'
import { MediaCarouselContainer } from '../../components/MediaCarousel/MediaCarouselContainer'
import { usePhotoStore, useSelectionStore } from '../../store/PhotoContext'
import { PhotoSearchResultListItem } from './PhotoSearchResultListItem'
import { PhotoSearchSelectionManager } from './PhotoSearchSelectionManager'
import type { PhotoSearchStore } from './PhotoSearchStore'
import { usePhotoSearchResult } from './usePhotoSearchResult'

export const debug = Debug('jotta:photos:search:result')
export const PhotoSearchResult = observer<{ search: PhotoSearchStore }>(
  function PhotoSearchResult({ search }) {
    const photoStore = usePhotoStore()
    const [{ matches, photoIds, similarPhotoId }, query] =
      usePhotoSearchResult()
    useFetchPhotoIds(photoIds, photoStore.mediaObjects)
    const [searchParams, setSearchParams] = useSearchParams()
    const {
      mediaObjects: { getPhotosByIds },
    } = usePhotoStore()
    const similarPhotoQuery = usePhoto(similarPhotoId || undefined, {
      enabled: Boolean(similarPhotoId),
    })
    const similarPhoto = similarPhotoQuery.data
    const photos = getPhotosByIds(photoIds)
    const onNavigate = useCallback(
      (id: string) => {
        searchParams.set('photo', id)
        setSearchParams(searchParams)
      },
      [searchParams, setSearchParams],
    )
    const selectionStore = useSelectionStore('SMART_PHOTO_SEARCH')
    return (
      <div className="p-6">
        <LoadingOverlay open={query.isLoading} position="absolute" />
        {similarPhoto ? (
          <div>
            <div className="h-48">
              <img
                className="h-full"
                src={`${similarPhoto.thumbnail_url}.s`}
                alt=""
              />
            </div>
            {!!matches.length && (
              <Headline2 level="h4" className="pb-3 pt-6">
                <Trans>Similar photos</Trans>
              </Headline2>
            )}
          </div>
        ) : null}
        {!!matches.length && (
          <>
            <div className="relative z-0 grid w-full grid-cols-3 gap-1 pb-24 sm:grid-cols-5 md:flex md:flex-wrap md:justify-start">
              {matches.map(match => (
                <PhotoSearchResultListItem
                  key={match.photoId}
                  match={match}
                  onNavigate={onNavigate}
                  showSimilarSearch
                  store={selectionStore}
                />
              ))}
            </div>
            <PhotoSearchSelectionManager
              store={selectionStore}
              username={photoStore.username}
              actionContext={'SMART_PHOTO_SEARCH'}
              getDownloadInfo={photoStore.timeline.getDownloadInfo}
              restoreOnUnmount
            />
            <MediaCarouselContainer
              mediaId={searchParams.get('photo') || undefined}
              items={photos}
              actionContext="SMART_SEARCH_VIEW_PHOTO"
              showSimilarSearch
              navOnQueryParam="photo"
              onClose={open => {
                if (!open) {
                  searchParams.delete('photo')
                  setSearchParams(searchParams)
                }
              }}
            />
          </>
        )}
      </div>
    )
  },
)
