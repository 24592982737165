import { TextInput } from '@jotta/ui/TextInput'
import { useForm } from '@jotta/hooks/useForm'
import { CTAButton } from './CTAButton'
import { TermsAndConditionsCheckbox } from './TermsAndConditionsCheckbox'
import {
  useAcceptedTermsField,
  useEmailField,
  newsletterField,
  usePasswordField,
  useNameField,
  voucherCodeField,
  useActivationCodeTerminology,
} from './signupForm'
import { Form } from '@jotta/ui/Form'
import { FormField } from '@jotta/ui/FormField'
import type { SignupRequestCallback } from './CreateUser'
import { Trans, t } from '@lingui/macro'
import { PasswordField } from './PasswordField'

export function CreatePrivateUser({
  customerGroupCode,
  className,
  isPending,
  signup,
  supportsVoucher,
  prefill = {},
  ctaLabelText,
}: {
  customerGroupCode: string
  className: string
  isPending: boolean
  signup: SignupRequestCallback
  supportsVoucher: boolean
  prefill?: { name?: string; email?: string }
  ctaLabelText?: string
}) {
  const activationCodeTerminology = useActivationCodeTerminology()

  const form = useForm({
    fields: {
      name: useNameField(),
      email: useEmailField(customerGroupCode),
      password: usePasswordField(),
      voucherCode: voucherCodeField,
      acceptedTerms: useAcceptedTermsField(),
      newsletter: newsletterField,
    },
    suppressIndicatorsForRequiredFields: true,
    defaultValues: () => prefill,
    submit: ({ name, email, password, voucherCode, newsletter }) =>
      signup({
        request: {
          case: 'formSignup',
          value: {
            fullname: name,
            email: email,
            password: password,
          },
        },
        voucherCode,
        wantsNewsletter: Boolean(newsletter),
      }),
  })

  const {
    name,
    password,
    email,
    voucherCode,
    acceptedTerms,
    newsletter,
    onSubmit,
    isSubmitting,
  } = form

  return (
    <Form form={form} className={className} onSubmit={onSubmit}>
      <FormField label={t`Name`} field={name}>
        <TextInput {...name.inputProps} />
      </FormField>

      <FormField label={t`Email`} field={email}>
        <TextInput {...email.inputProps} />
      </FormField>

      <PasswordField field={password} />

      {supportsVoucher && (
        <FormField
          label={
            activationCodeTerminology ? t`Activation code` : t`Voucher code`
          }
          field={voucherCode}
        >
          <TextInput {...voucherCode.inputProps} />
        </FormField>
      )}

      <TermsAndConditionsCheckbox
        acceptedTerms={acceptedTerms}
        newsletter={newsletter}
      />

      <CTAButton
        className="self-start"
        type="submit"
        loading={isPending || isSubmitting}
      >
        {ctaLabelText || <Trans>Create account</Trans>}
      </CTAButton>
    </Form>
  )
}
