import { BrandIcon, PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { Button } from '@jotta/ui/Button'
import type { LocalizedCountry } from '@jotta/i18n'
import { useLocalizedCountryList } from '@jotta/i18n'
import type { ChangeEventHandler, KeyboardEvent } from 'react'
import { type FocusEventHandler, forwardRef, useEffect, useState } from 'react'
import { cn } from '@jotta/utils/css'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { getBrandZIndex } from '../../themes/zIndex'
import type { Country } from '@jotta/grpc-connect-openapi/country'
import {
  formatPhoneNumber,
  parsePhoneNumber,
} from '@jotta/settings/formatPhoneNumber'
import { FakeChangeEvent, FakeFocusEvent } from '../../utils/event'
import { t } from '@lingui/macro'

export function validatePhoneNumber(phone: string) {
  return !phone || parsePhoneNumber(phone) ? undefined : t`Invalid phone number`
}

function ChevronDown({ className }: { className?: string }) {
  return (
    <PlainBrandIcon className={className} icon="SvgCaretDown" height={16} />
  )
}

const zIndexModal = getBrandZIndex('modal')

function CountryCallingCodeSelector({
  countries,
  value,
  onChange,
}: {
  countries: LocalizedCountry[]
  value?: LocalizedCountry
  onChange: (c: string) => void
}) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        type="button"
        className="btn btn-ghost h-10 bg-grey3"
      >
        {value && (
          <>
            <span className="text-2xl">{value.flag}</span>
            <span>{value.callingCode}</span>
          </>
        )}
        <ChevronDown />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="end"
          sideOffset={-40}
          style={{
            zIndex: zIndexModal,
          }}
          className="flex max-h-80 w-auto flex-col overflow-y-auto bg-white font-medium shadow-[0_0_12px_0_#00000026] focus-visible:shadow-[0_0_12px_0_#00000026]"
        >
          <div className="relative flex h-10 flex-auto flex-shrink-0 flex-grow-0 flex-nowrap items-center gap-1 border-b border-gray-300 pl-3 pr-2">
            {value && (
              <>
                <div className="flex flex-auto items-center gap-2">
                  <span className="text-2xl">{value.flag}</span>
                  <span>{value.name}</span>
                </div>
                <span>+{value.callingCode}</span>
                <ChevronDown className="rotate-180" />
              </>
            )}
          </div>

          <div className="overflow-y-auto">
            {countries.map(c => {
              const { callingCode, flag, name } = c
              if (!callingCode) {
                return
              }

              return (
                <DropdownMenu.Item
                  key={c.code}
                  className="flex h-10 cursor-pointer flex-nowrap items-center gap-2 px-3 data-[highlighted]:bg-[#EBEBEB] focus-visible:shadow-none"
                  onSelect={() => onChange(callingCode)}
                  textValue={name}
                >
                  <span className="text-2xl">{flag}</span>
                  <span>{name}</span>
                  <span className="flex-auto text-right">+{callingCode}</span>
                </DropdownMenu.Item>
              )
            })}
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export const PhoneInput = forwardRef(function PhoneInput(
  {
    className,
    country,
    placeholder,
    disabled,
    value,
    onBlur,
    onChange,
    phoneNumberVerified = false,
    name,
    autoComplete,
    autoFocus,
    ...props
  }: {
    className?: string
    country?: Country
    disabled?: boolean
    placeholder?: string
    phoneNumberVerified?: boolean

    name?: string
    value?: string
    onChange?: ChangeEventHandler<HTMLInputElement>
    onBlur?: FocusEventHandler<HTMLInputElement>
    autoComplete?: string
    autoFocus?: boolean
    'aria-errormessage'?: string
    'aria-invalid'?: boolean
  },
  ref,
) {
  const { countries, countryByCallingCode, countryByCountry } =
    useLocalizedCountryList()

  const parsedValue = parsePhoneNumber(value)

  const [countryCallingCode, setCountryCallingCode] = useState(
    parsedValue?.countryCallingCode ||
      countryByCountry(country)?.callingCode ||
      '47',
  )
  const [nationalNumber, setNationalNumber] = useState(
    formatPhoneNumber(value, 'NATIONAL') || '',
  )

  const [hiddenInput, setHiddenInput] = useState<HTMLInputElement | null>()

  useEffect(() => {
    const formattedValue = nationalNumber
      ? `00${countryCallingCode}${nationalNumber}`
      : ''
    const parsedValue = parsePhoneNumber(formattedValue)
    const onChangeValue = parsedValue
      ? `00${parsedValue.countryCallingCode}${parsedValue.phone}`
      : formattedValue
    if (hiddenInput && onChange && value !== onChangeValue) {
      hiddenInput.value = onChangeValue
      onChange(new FakeChangeEvent(hiddenInput))
    }
  }, [onChange, value, hiddenInput, countryCallingCode, nationalNumber])

  return (
    <div className={cn('group flex gap-2', className)}>
      <input
        type="hidden"
        name={name}
        ref={input => {
          setHiddenInput(input)
          if (typeof ref === 'function') {
            ref(input)
          }
        }}
      />
      <CountryCallingCodeSelector
        countries={countries}
        value={countryByCallingCode[countryCallingCode]}
        onChange={setCountryCallingCode}
      />
      <div className="relative min-w-0 flex-auto">
        <input
          className="input h-10 w-full min-w-0 font-medium"
          disabled={disabled}
          value={nationalNumber}
          placeholder={placeholder}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          aria-errormessage={props['aria-errormessage']}
          aria-invalid={props['aria-invalid']}
          onChange={event => setNationalNumber(event.target.value)}
          onBlur={() => {
            const formattedValue = `00${countryCallingCode}${nationalNumber}`
            if (parsePhoneNumber(formattedValue)) {
              setNationalNumber(
                formatPhoneNumber(formattedValue, 'NATIONAL') || '',
              )
            }
            if (onBlur && hiddenInput) {
              onBlur(new FakeFocusEvent('blur', hiddenInput))
            }
          }}
        />
        {phoneNumberVerified && (
          <BrandIcon
            icon="SvgVerified"
            className={cn('absolute right-2 top-1/2 -translate-y-1/2', {
              'transition group-hover:opacity-0': !disabled,
            })}
          />
        )}
        <Button
          icon="SvgEraseSmall"
          type="button"
          tabIndex={-1}
          className={cn(
            'absolute right-2 top-1/2 hidden -translate-y-1/2 rounded',
            {
              'group-hover:block': !disabled && nationalNumber,
            },
          )}
          onClick={() => setNationalNumber('')}
        />
      </div>
    </div>
  )
})
