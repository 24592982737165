import { Trans } from '@lingui/macro'
import { useBizUsers } from '../BizApi'
import { BizUserTable } from '../components/BizUserTable'
import { BizActionButtonCreateUser } from '../components/BizActionButtonCreateUser'

export function BizUsersRoute() {
  const { users } = useBizUsers()

  return (
    <div className="flex flex-col gap-4">
      <div className="card card-secondary" data-testid="BizUsersRoute">
        <h2 className="title-sm">
          <Trans>Business users</Trans>
        </h2>
        <div className="card-body">
          <BizActionButtonCreateUser />
          <BizUserTable sortBy="name" users={users} view="full" />
        </div>
      </div>
    </div>
  )
}
