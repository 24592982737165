import { Trans } from '@lingui/macro'
import { skipToken } from '@tanstack/react-query'
import Debug from 'debug'
import { useParams } from 'react-router-dom'
import { bizApi } from '../BizApi'
import { BizUserForm } from '../components/BizUserForm'

export const debug = Debug('jotta:biz:BizModifyUserRoute')

export function BizUserEditRoute() {
  const { username = '' } = useParams()
  const query = bizApi.user.byId.useSuspenseQuery({
    variables: username ? { username } : skipToken,
  })
  return (
    <div className="card">
      <h2 className="title-sm pb-4">
        <Trans>Modify user</Trans>
      </h2>
      <div className="card-body">
        <BizUserForm mode="edit" user={query.data} />
      </div>
    </div>
  )
}
