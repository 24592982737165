import { useQuery } from '@tanstack/react-query'
import { fetchPhotoById } from './photosApi'

export function usePhoto(
  id?: string,
  {
    enabled = true,
    throwOnError = true,
  }: { enabled?: boolean; throwOnError?: boolean } = {},
) {
  return useQuery({
    queryKey: ['photo', id] as const,
    queryFn: async () => fetchPhotoById(id || ''),
    enabled: Boolean(enabled && id),
    throwOnError: throwOnError,
  })
}
