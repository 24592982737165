import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import clsx from 'clsx'

export type Importance = 'critical' | 'warning' | 'info'

export function BannerMessage({
  link,
  linkText,
  importance,
  title,
  message,
  fullWidth,
}: {
  importance: Importance
  title: string
  message: string
  link?: string
  linkText?: string
  fullWidth?: boolean
}) {
  const Tag = link && !linkText ? 'a' : 'div'
  return (
    <Tag
      href={link}
      className={clsx({
        'bg-danger-fg text-sx-danger-text visited:text-sx-danger-text':
          importance === 'critical',
        'bg-warning-fg text-sx-warning-text visited:text-sx-warning-text':
          importance === 'warning',
        'bg-sx-message text-sx-message-text visited:text-sx-message-text':
          importance === 'info',
      })}
    >
      <div
        className={clsx('flex justify-center', {
          'w-contentWidthSafe': fullWidth,
        })}
      >
        <div className="flex items-center p-2 sm:gap-4 sm:p-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            strokeLinejoin="round"
            strokeLinecap="round"
            viewBox="0 0 24 24"
            className="size-6 flex-none"
          >
            <path
              d="M9.3,4l-7.7,13.1c-1.6,2.7,0.3,4.2,2,4.2h16.8c1.7,0,3.6,-1.5,2,-4.2l-7.7,-13.1m0,0c-1,-1.4,-1.7,-1.7,-2.7,-1.7c-1,0,-1.8,0.3,-2.7,1.7"
              className={clsx('stroke-current', {
                'fill-current': importance === 'critical',
                'fill-transparent': importance !== 'critical',
              })}
            />
            <path
              d="M12 7v7m0 4v0"
              strokeWidth="2"
              className={clsx({
                'stroke-current': importance !== 'critical',
                'stroke-danger': importance === 'critical',
              })}
            />
          </svg>
          <div className="block flex-initial pl-4 sm:pl-0">
            <h2>{title}</h2>
            <p className="text-sm">{message}</p>
          </div>
          {!!(link && linkText) && (
            <a
              className="whitespace-nowrap p-2 sm:rounded-lg sm:border sm:border-current sm:text-center sm:text-sm sm:font-bold sm:leading-none"
              href={link}
            >
              <span className="hidden sm:block">{linkText}</span>
              <PlainBrandIcon
                icon="SvgCaretRight"
                className="block size-6 sm:hidden"
              />
            </a>
          )}
        </div>
      </div>
    </Tag>
  )
}
