import {
  confirmTFA,
  deleteSession,
  disableTFA,
  generateLoginToken,
  initTFA,
  linkProvider,
  unLinkProvider,
} from '@jotta/grpc-js-client/authService'
import type {
  Event,
  LinkedIdentity,
  ListEventsResponse,
  ListLinkedIdentitiesResponse,
  ListSessionsResponse,
} from '@jotta/grpc-web/no/jotta/openapi/auth/v2/auth.v2_pb'
import {
  ConfirmTFAResponse,
  DisableTFARequest,
  GenerateLoginTokenRequest,
  Session,
} from '@jotta/grpc-web/no/jotta/openapi/auth/v2/auth.v2_pb'
import { Customer } from '@jotta/grpc-web/no/jotta/openapi/customer/customer.v2_pb'
// import * as Sentry from '@sentry/react'
import Debug from 'debug'
import { action, makeAutoObservable, runInAction } from 'mobx'
import type { ChallengeableRequestParams } from '../../utils/ChallengeModel'
import { challengeCheck } from '../../utils/ChallengeUtils'
import { settingsStore } from './index'
import { getCustomerQuery } from '@jotta/grpc-connect-client/customer'

const debug = Debug('jotta:settings:AuthStore')

const ConfirmTFAStatus = ConfirmTFAResponse.ConfirmTFAStatus

export class AuthStore {
  linkedIdentities: LinkedIdentity.AsObject[] = []
  loginEvents: Event.AsObject[] = []
  sessionsList: Session.AsObject[] = []
  loginToken = ''

  constructor() {
    makeAutoObservable(this)
  }

  get deviceSessions() {
    return this.sessionsList.filter(e => e.type === Session.SessionType.DEVICE)
  }

  get browserSessions() {
    return this.sessionsList.filter(e => e.type === Session.SessionType.WEB)
  }

  updateLinkedIdentities = action(
    'updateLinkedIdentities',
    (data: ListLinkedIdentitiesResponse.AsObject) => {
      this.linkedIdentities = data.linkedIdentitiesList
    },
  )
  updateLoginEvents = action(
    'updateLoginEvents',
    (data: ListEventsResponse.AsObject) => {
      this.loginEvents = data.eventsList
    },
  )
  updateSessionsList = action(
    'updateSessionsList',
    (data: ListSessionsResponse.AsObject) => {
      this.sessionsList = data.sessionsList.sort((a, b) =>
        a.lastAccessedMillis > b.lastAccessedMillis ? -1 : 1,
      )
    },
  )

  updateIdentityProviderLinkState = (providerId: string, linked: boolean) => {
    const itemIndex = this.linkedIdentities.findIndex(
      e => e.providerId === providerId,
    )
    if (itemIndex > -1) {
      this.linkedIdentities[itemIndex].linked = linked
    }
  }

  linkProvider = (providerId: string, redirectUrl?: string) =>
    linkProvider(providerId, redirectUrl)

  unLinkProvider = (providerId: string) =>
    unLinkProvider(providerId).then(response => {
      runInAction(() => this.updateIdentityProviderLinkState(providerId, false))
      return response
    })

  generateLoginToken = async ({
    request = new GenerateLoginTokenRequest(),
    onResolve,
    onFail,
    additionalProps,
  }: ChallengeableRequestParams) => {
    try {
      const response = await generateLoginToken(request)
      const isChallenged = challengeCheck(
        { request, onResolve, onFail, additionalProps },
        response.challenges,
      )
      if (!isChallenged) {
        this.loginToken = response.token
      }
    } catch (e) {
      if (onFail) {
        onFail(e)
      }
    }
  }

  deleteSession = async (sessionId: string) => {
    try {
      await deleteSession(sessionId)
      runInAction(() => {
        this.sessionsList = this.sessionsList.filter(e => e.id !== sessionId)
      })
    } catch (e) {
      debug(e)
      // Sentry.captureException(e)
    }
  }

  initTFA = (
    type: Customer.TFAType.TOTP | Customer.TFAType.SMS,
    phoneNumber?: string,
  ) => initTFA(type, phoneNumber)

  confirmTFA = (
    type: Customer.TFAType.TOTP | Customer.TFAType.SMS,
    verificationCode: string,
    verificationId?: string,
  ) =>
    confirmTFA(type, verificationCode, verificationId).then(response => {
      if (response.status === ConfirmTFAStatus.OK) {
        runInAction(() => {
          if (settingsStore.customer) {
            settingsStore.customer.tfaType = type
            getCustomerQuery.invalidate()
          }
        })
      }

      return response
    })

  disableTFA = async ({
    request = new DisableTFARequest(),
    onResolve,
    onFail,
    additionalProps,
  }: ChallengeableRequestParams) => {
    try {
      const response = await disableTFA(request)
      const isChallenged = challengeCheck(
        { request, onResolve, onFail, additionalProps },
        response.challenges,
      )
      if (!isChallenged && settingsStore.customer) {
        settingsStore.customer.tfaType = Customer.TFAType.NONE
        getCustomerQuery.invalidate()
      }
    } catch (e) {
      if (onFail) {
        onFail(e)
      }
    }
  }
}
