import { tt } from '@jotta/i18n'
import { cn } from '@jotta/utils/css'
import type { LabelProps } from 'react-aria-components'
import { Label } from 'react-aria-components'

interface ReactAriaLabelProps extends Omit<LabelProps, 'className'> {
  className?: string
  isRequired?: boolean
}

export function ReactAriaLabel({
  children,
  className,
  isRequired = false,
  ...props
}: ReactAriaLabelProps) {
  return (
    <Label
      className={cn('form-control-label grid grid-cols-fr1-max', className)}
      {...props}
    >
      <span>{children}</span>
      {isRequired && (
        <span className="form-control-required">{tt`Required`}</span>
      )}
    </Label>
  )
}
