import { Trans } from '@lingui/macro'
import { BizUserForm } from '../components/BizUserForm'
import type { ExtendedBusinessUser } from '../BizApi'
import { useBizUsers } from '../BizApi'
import { toPlainMessage } from '@bufbuild/protobuf'
import { BusinessUser } from '@jotta/grpc-connect-openapi/esm/openapi/business/v1/business_pb'
import { Link } from 'react-router-dom'

const placeholderData: ExtendedBusinessUser = {
  isCurrentUser: false,
  ...toPlainMessage(new BusinessUser({})),
}
export function BizUserCreateRoute() {
  const { canCreateUser } = useBizUsers()

  if (!canCreateUser) {
    return (
      <Link to=".." className="link">
        <Trans>Back to user list</Trans>
      </Link>
    )
  }
  return (
    <div className="card">
      <h2 className="title-sm pb-4">
        <Trans>Create new user</Trans>
      </h2>
      <div className="card-body">
        <BizUserForm mode="create" user={placeholderData} />
      </div>
    </div>
  )
}
