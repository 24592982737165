import type { GetFamilyResponse } from '@jotta/grpc-js-client/customerService'
import {
  CreateInvite,
  getFamily,
  inviteFamilyMember,
  revokeFamilyInvite,
} from '@jotta/grpc-js-client/customerService'
import { queryClient } from '@jotta/query'
import { handleUnknownError } from '@jotta/types/AppError'
import type { Settings } from '@jotta/types/Settings'
import axios from 'axios'
import Debug from 'debug'
const debug = Debug('jotta:settings:SettingsAPI')

export const settingsAPI: Settings.API = {
  fetchFamily: {
    queryKey: ['family'],
    queryFn: async () => {
      try {
        const family = (await getFamily()).toObject()
        return family
      } catch (error) {
        throw handleUnknownError(error)
      }
    },
  },

  inviteMemberEmail: async data => {
    const req = new CreateInvite()
    req.setFirstName(data.firstName)
    req.setLastName(data.lastName)
    const inv = new CreateInvite.EmailInvite()
    inv.setEmail(data.email)
    req.setEmailInvite(inv)
    const res = await inviteFamilyMember(req)
    debug('created invite: ', res.toObject())
    queryClient.invalidateQueries({ queryKey: ['family'] })
  },

  inviteMemberSMS: async data => {
    const req = new CreateInvite()
    req.setFirstName(data.firstName)
    req.setLastName(data.lastName)
    const inv = new CreateInvite.SmsInvite()
    inv.setPhonenumber(data.phonenumber)
    req.setSmsInvite(inv)
    const res = await inviteFamilyMember(req)
    debug('created invite: ', res.toObject())
    queryClient.invalidateQueries({ queryKey: ['family'] })
  },

  revokeInvite: async data => {
    const res = await revokeFamilyInvite(data.inviteCode)
    debug(`revoked invite=${data.inviteCode}: `, res.toObject())
    queryClient.invalidateQueries({ queryKey: ['family'] })
  },
}

export const mockSettingsApi: Settings.API = {
  ...settingsAPI,
  fetchFamily: {
    ...settingsAPI.fetchFamily,
    queryFn: async () => {
      const { data } =
        await axios.get<GetFamilyResponse.AsObject>('/mockapi/family')
      return data
    },
  },
  inviteMemberEmail: async () => {
    queryClient.invalidateQueries({ queryKey: ['family'] })
  },
  inviteMemberSMS: async () => {
    queryClient.invalidateQueries({ queryKey: ['family'] })
  },
  revokeInvite: async () => {
    queryClient.invalidateQueries({ queryKey: ['family'] })
  },
}
