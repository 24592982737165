import { cn } from '@jotta/utils/css'
import type { FieldErrorProps } from 'react-aria-components'
import { FieldError } from 'react-aria-components'
import type { ReactAriaValidationError } from '../ReactAriaTypes'
import Debug from 'debug'
const debug = Debug('jotta:ui:ReactAriaFieldError')

interface ReactAriaFieldErrorProps
  extends Omit<FieldErrorProps, 'className' | 'children'> {
  className?: string
  error?: ReactAriaValidationError
}

export function ReactAriaFieldError({
  className,
  error,
  ...props
}: ReactAriaFieldErrorProps) {
  const errors = Array.isArray(error) ? error : error ? [error] : []
  return (
    <FieldError className={cn('form-control-error', className)} {...props}>
      {errors.map((err, i) => (
        <div key={err?.toString()}>{err}</div>
      ))}
    </FieldError>
  )
}
