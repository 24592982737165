import { cn } from '@jotta/utils/css'
import Debug from 'debug'
import type {
  SelectProps,
  ListBoxProps,
  ListBoxItemProps,
} from 'react-aria-components'
import {
  Button,
  ListBox,
  ListBoxItem,
  Popover,
  Select,
  SelectValue,
  Text,
} from 'react-aria-components'
import { PlainBrandIcon } from '../../../Icons/BrandIcon/BrandIcon'
import type { ReactNode } from 'react'
import type { ReactAriaValidationError } from '../ReactAriaTypes'
import { ReactAriaFieldError } from '../ReactAriaFieldError/ReactAriaFieldError'
import { ReactAriaLabel } from '../ReactAriaLabel/ReactAriaLabel'
const debug = Debug('jotta:ui:ReactAriaSelect')

export interface ReactAriaSelectProps<T extends object>
  extends Omit<SelectProps<T>, 'className' | 'children'> {
  label: ReactNode
  description?: ReactNode
  className?: string
  children: ListBoxProps<T>['children']
  items?: Iterable<T>
  errorMessage?: ReactAriaValidationError
}
interface ReactAriaSelectOptionProps<T extends object>
  extends Omit<ListBoxItemProps<T>, 'className'> {
  className?: string
}

export function ReactAriaSelect<T extends object>({
  className = '',
  label,
  description,
  errorMessage,
  children,
  items,
  ...props
}: ReactAriaSelectProps<T>) {
  return (
    <Select className={cn('form-control select', className)} {...props}>
      <ReactAriaLabel isRequired={props.isRequired}>{label}</ReactAriaLabel>
      <Button className="select-trigger">
        <SelectValue className="select-trigger-value" />
        <div aria-hidden="true" className="select-trigger-icon">
          <PlainBrandIcon icon="SvgBoldCaretUpDown" />
        </div>
      </Button>
      {description && (
        <Text slot="description" className="form-control-description">
          {description}
        </Text>
      )}
      <ReactAriaFieldError error={errorMessage} />

      <Popover
        onOpenChange={isOpen => {
          if (isOpen) {
            debug('open')
          }
        }}
        className="select-popover w-[--trigger-width]"
        placement="bottom"
      >
        <ListBox
          onFocus={e => {
            const selectedItem = e.currentTarget.querySelector(
              '[aria-selected="true"]',
            )
            if (selectedItem) {
              debug('scrollIntoView', selectedItem)
              requestAnimationFrame(() => {
                selectedItem.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              })
            }
          }}
          items={items}
          className="select-options"
        >
          {children}
        </ListBox>
      </Popover>
    </Select>
  )
}
export function ReactAriaSelectOption<T extends object>({
  className = '',
  ...props
}: ReactAriaSelectOptionProps<T>) {
  return <ListBoxItem className={cn('select', className)} {...props} />
}
