import { ErrorPage } from '@jotta/ui/ErrorPage'
import type { FallbackRender } from '@sentry/react'
import { ErrorBoundary } from '@sentry/react'
import type { ReactNode } from 'react'
import { Suspense } from 'react'

export function BizBoundary({
  children,
  errorFallback = ({ error, resetError }) => {
    return <ErrorPage error={error} resetError={resetError} />
  },
  loadingFallback = <div className="loading loading-absolute">Loading</div>,
}: {
  children: React.ReactNode
  loadingFallback?: ReactNode
  errorFallback?: FallbackRender
}) {
  return (
    <ErrorBoundary fallback={errorFallback}>
      <Suspense fallback={loadingFallback}>{children}</Suspense>
    </ErrorBoundary>
  )
}
