import { TextInput } from '@jotta/ui/TextInput'
import { CTAButton } from './CTAButton'
import { t, Trans } from '@lingui/macro'
import type { SignupRequestCallback } from './CreateUser'
import { useForm } from '@jotta/hooks'
import { TermsAndConditionsCheckbox } from './TermsAndConditionsCheckbox'
import {
  useAcceptedTermsField,
  useEmailField,
  newsletterField,
  usePasswordField,
  useNameField,
  useActivationCodeTerminology,
} from './signupForm'
import { FormField } from '@jotta/ui/FormField'
import { Form } from '@jotta/ui/Form'
import { PasswordField } from './PasswordField'
import { useDescribeVoucher } from '@jotta/grpc-connect-client/signup/useDescribeVoucher'
import { exhaustiveGuard } from '@jotta/utils/exhaustive'

export function CreateVoucherUser({
  customerGroupCode,
  className,
  isPending,
  signup,
  prefill = {},
}: {
  customerGroupCode: string
  className: string
  isPending: boolean
  signup: SignupRequestCallback
  prefill?: { name?: string; email?: string; voucherCode?: string }
}) {
  const { mutateAsync: describeVoucher } = useDescribeVoucher()
  const activationCodeTerminology = useActivationCodeTerminology()

  const form = useForm({
    fields: {
      voucherCode: {
        type: 'text',
        validate: async (voucherCode: string) => {
          if (!voucherCode) {
            return
          }
          const describeVoucherResponse = await describeVoucher({
            voucherCode,
            customerGroupCode,
          })
          const status = describeVoucherResponse.status
          switch (status.case) {
            case undefined:
              throw new Error(describeVoucherResponse.toJsonString())
            case 'invalid':
              return activationCodeTerminology
                ? t`The activation code is invalid!`
                : t`The voucher code is invalid!`
            case 'used':
              return activationCodeTerminology
                ? t`Your activation code is already in use! Please verify that the code is correct`
                : t`Your voucher code is already in use! Please verify that the code is correct.`
            case 'valid':
              form.fields.customerGroupCode.set(status.value.customerGroupCode)
              return undefined
            default:
              exhaustiveGuard(status)
          }
        },
      },
      customerGroupCode: {
        type: 'text',
      },
      name: useNameField(),
      email: useEmailField(customerGroupCode),
      password: usePasswordField(),
      acceptedTerms: useAcceptedTermsField(),
      newsletter: newsletterField,
    },
    defaultValues: () => prefill,
    submit: ({
      voucherCode,
      customerGroupCode,
      name,
      email,
      password,
      newsletter,
    }) =>
      signup({
        request: {
          case: 'formSignup',
          value: {
            fullname: name,
            email: email,
            password: password,
          },
        },
        voucherCode,
        customerGroupCode,
        wantsNewsletter: Boolean(newsletter),
      }),
  })

  const {
    voucherCode,
    name,
    password,
    email,
    acceptedTerms,
    newsletter,
    isSubmitting,
  } = form

  return (
    <Form form={form} className={className}>
      <FormField
        label={activationCodeTerminology ? t`Activation code` : t`Voucher code`}
        field={voucherCode}
      >
        <TextInput {...voucherCode.inputProps} />
      </FormField>

      {voucherCode.value && (
        <>
          <FormField label={t`Name`} field={name}>
            <TextInput {...name.inputProps} />
          </FormField>

          <FormField label={t`Email`} field={email}>
            <TextInput {...email.inputProps} />
          </FormField>

          <PasswordField field={password} />

          <TermsAndConditionsCheckbox
            acceptedTerms={acceptedTerms}
            newsletter={newsletter}
          />

          <CTAButton loading={isPending || isSubmitting} type="submit">
            <Trans>Create account</Trans>
          </CTAButton>
        </>
      )}
    </Form>
  )
}
