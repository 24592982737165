import { t } from '@lingui/macro'
import { skipToken } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { bizApi } from '../BizApi'
import { BizOrderReceipt } from '../components/BizOrderReceipt'
import { BizWidget } from '../widgets/BizWidget'

export function BizOrderReceiptRoute() {
  const { orderNumber } = useParams()
  const { data: order } = bizApi.subscription.getOrder.useSuspenseQuery({
    variables: orderNumber ? { orderNumber } : skipToken,
  })
  return (
    <BizWidget title={t`Receipt`}>
      <BizOrderReceipt order={order} />
    </BizWidget>
  )
}
