import type {
  ChangeEvent,
  SyntheticEvent,
  FocusEvent as FocusEventReact,
} from 'react'

class FakeEvent<T extends EventTarget, E> implements SyntheticEvent<T, E> {
  private _propagationStopped: boolean = true

  constructor(type: string, target: T, nativeEvent: E) {
    this.defaultPrevented = false
    this.timeStamp = Date.now()
    this.target = target
    this.currentTarget = target
    this.nativeEvent = nativeEvent
    this.type = type
    this.bubbles = false
    this.cancelable = false
    this.isTrusted = false
    this.eventPhase = Event.AT_TARGET
    this.cancelBubble = false
    this.composed = false
    this.returnValue = false
    this.srcElement = target
    this.composedPath = []
  }

  nativeEvent: E
  currentTarget: T
  target: T
  bubbles: boolean
  cancelBubble: boolean
  cancelable: boolean
  defaultPrevented: boolean
  eventPhase: number
  isTrusted: boolean
  timeStamp: number
  type: string
  composed: boolean
  returnValue: boolean
  srcElement: EventTarget
  composedPath: EventTarget[]
  preventDefault(): void {
    this.defaultPrevented = true
  }
  isDefaultPrevented(): boolean {
    return this.defaultPrevented
  }
  stopPropagation(): void {
    this._propagationStopped = true
  }
  isPropagationStopped(): boolean {
    return this._propagationStopped
  }
  persist(): void {}
}

export class FakeFocusEvent
  extends FakeEvent<HTMLInputElement, FocusEvent>
  implements FocusEventReact<HTMLInputElement, HTMLInputElement>
{
  constructor(type: string, target: HTMLInputElement) {
    super(type, target, new FocusEvent(type))
    this.relatedTarget = target
  }
  relatedTarget: (EventTarget & HTMLInputElement) | null
}

export class FakeChangeEvent
  extends FakeEvent<HTMLInputElement, Event>
  implements ChangeEvent<HTMLInputElement>
{
  constructor(target: HTMLInputElement) {
    super('change', target, new Event('change'))
  }
}
