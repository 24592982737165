import type { PlainMessage } from '@bufbuild/protobuf'
import type { BusinessUser } from '@jotta/grpc-connect-openapi/esm/openapi/business/v1/business_pb'
import dayjs from 'dayjs'
import filesizeParser from 'filesize-parser'

const quotaBytes = BigInt(filesizeParser('4tb', { base: 10 }))

export const BizMockUsers: PlainMessage<BusinessUser>[] = [
  {
    name: 'Murvyn Plose',
    email: 'mplose0@ucoz.ru',
    username: 'x3ec5qoqvehyeo64digob9zu',
    quotaBytes,
    storageBytes: BigInt(filesizeParser('91439875471b', { base: 10 })),
    admin: true,
    lastWriteMillis: BigInt(dayjs().subtract(1, 'minute').valueOf()),
  },
  {
    name: 'Lilyan Lillicrap',
    email: 'llillicrap2m@bing.com',
    username: 'ca8d2fb6-5f7e-4bb9-906b-29564195409e',
    quotaBytes,
    storageBytes: BigInt(filesizeParser('216878916532b', { base: 10 })),
    admin: false,
    lastWriteMillis: BigInt(dayjs().subtract(2, 'hours').valueOf()),
  },
  {
    name: 'Estrella Draysey',
    email: 'edraysey1@geocities.com',
    username: '20b256c6-c26d-43df-9151-b2d7db1892e9',
    quotaBytes,
    storageBytes: BigInt(filesizeParser('156422177434b', { base: 10 })),
    admin: false,
    lastWriteMillis: BigInt(dayjs().subtract(23, 'hours').valueOf()),
  },
  {
    name: 'Estele Abdee',
    email: 'eabdee2@vkontakte.ru',
    username: '237cf86d-f4f6-413f-b90c-274749b412d9',
    quotaBytes,
    storageBytes: BigInt(filesizeParser('146903065234b', { base: 10 })),
    admin: false,
    lastWriteMillis: BigInt(dayjs().subtract(30, 'hours').valueOf()),
  },
  {
    name: 'Fayre Tacker',
    email: 'ftacker3@wix.com',
    username: '3a4c4ebf-9eed-4aa1-a34e-ce4359b43c96',
    quotaBytes,
    storageBytes: BigInt(filesizeParser('47856221551b', { base: 10 })),
    admin: false,
    lastWriteMillis: BigInt(dayjs().subtract(1, 'month').valueOf()),
  },
  {
    name: 'Shellie Fransseni',
    email: 'sfransseni4@sciencedirect.com',
    username: 'f63d91c7-9ce9-48e0-9cef-de2c944b31fa',
    quotaBytes,
    storageBytes: BigInt(filesizeParser('247809716648b', { base: 10 })),
    admin: false,
    lastWriteMillis: BigInt(dayjs().subtract(13, 'months').valueOf()),
  },
  {
    name: 'Mercy McGlynn',
    email: 'mmcglynn5@lulu.com',
    username: 'b6f0a6a9-7469-4fe5-8f2c-dc99a5b1137b',
    quotaBytes,
    storageBytes: BigInt(filesizeParser('257323290007b', { base: 10 })),
    admin: false,
    lastWriteMillis: BigInt(dayjs().subtract(1, 'year').valueOf()),
  },
  {
    name: 'Laurianne Schermick',
    email: 'lschermick6@comsenz.com',
    username: 'be4ed755-b7b4-46bc-a3b7-5f78440cab4c',
    quotaBytes,
    storageBytes: BigInt(filesizeParser('15807424093b', { base: 10 })),
    admin: false,
    lastWriteMillis: BigInt(dayjs().subtract(3, 'years').valueOf()),
  },
]
