import Debug from 'debug'
import { Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import type { BizPieChartData } from '../BizTypes'
const debug = Debug('jotta:biz:dashboard')

type PieLabelProps = {
  index: number
  name: string
  value: number
  innerRadius: number
  outerRadius: number
  midAngle: number
  percent: number
  cx: number
  cy: number
  x: number
  y: number
  tooltipPosition: {
    x: number
    y: number
  }
}

const RADIAN = Math.PI / 180
const legendStyle = {
  top: '50%',
  right: 0,
  transform: 'translate(0, -50%)',
  lineHeight: '24px',
  background: 'rgb(255 255 255 / 0.8)',
}
export function BizPieChart({ data }: { data: BizPieChartData }) {
  return (
    <ResponsiveContainer>
      <PieChart className="aspect-[2/1]">
        <Pie
          nameKey="name"
          data={data}
          cx="50%"
          cy="50%"
          startAngle={90}
          endAngle={-360}
          outerRadius="90%"
          label={renderLabel}
          labelLine={false}
          dataKey="value"
        />
        <Legend
          iconSize={10}
          layout="vertical"
          verticalAlign="top"
          align="right"
          wrapperStyle={legendStyle}
        />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  )
}
function renderLabel(props: PieLabelProps) {
  const { cx, cy, x, innerRadius, outerRadius, midAngle, percent } = props
  // debug('label', props)
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const innerX = cx + radius * Math.cos(-midAngle * RADIAN)
  const innerY = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <>
      <text
        x={innerX}
        y={innerY}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="middle"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </>
  )
}
