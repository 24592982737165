import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { LoadingOverlay } from '../../Layout/LoadingOverlay/LoadingOverlay'

export function Redirect({
  to,
  replace = false,
  reload = false,
}: {
  to: string
  replace?: boolean
  reload?: boolean
}) {
  const navigate = useNavigate()

  useEffect(() => {
    if (reload) {
      if (replace) {
        window.location.replace(to)
      } else {
        window.location.href = to
      }
    } else {
      navigate(to, { replace })
    }
  }, [to, replace, navigate])

  return <LoadingOverlay open />
}
