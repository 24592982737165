import { ErrorType } from '@jotta/grpc-connect-openapi/esm/openapi/error_pb'
import { handleUnknownError } from '@jotta/types/AppError'
import { ReactAriaCheckboxField } from '@jotta/ui/ReactAriaCheckbox'
import { ReactAriaTextField } from '@jotta/ui/ReactAriaTextField'
import { ReactAriaRangeSlider } from '@jotta/ui/ReactAriaRangeSlider'
import { ReactAriaCheckbox } from '@jotta/ui/ReactAriaCheckbox'
import { t, Trans } from '@lingui/macro'
import { useForm } from '@tanstack/react-form'
import { zodValidator } from '@tanstack/zod-form-adapter'
import dayjs from 'dayjs'
import Debug from 'debug'
import fileSize from 'filesize'
import { useNavigate } from 'react-router-dom'
import { isNumber, pickBy } from 'remeda'
import { z } from 'zod'
import type { ExtendedBusinessUser } from '../BizApi'
import { bizApi } from '../BizApi'
import { useBizContext } from '../BizLayout'
import { DisplayFieldErrors } from './DisplayFieldErrors'
import { SliderOutput } from 'react-aria-components'
const debug = Debug('jotta:biz:BizModifyUserFormTF')

export function BizUserForm({
  user,
  mode,
}: {
  user: ExtendedBusinessUser
  mode: 'edit' | 'create'
}) {
  const { customer, subscription } = useBizContext()
  const { data: config } = bizApi.config.get.useSuspenseQuery()
  const customerGroupCode = config.brandingInfo?.customerGroupCode || ''
  const isCurrentUser = user.username === customer.username
  const navigate = useNavigate()
  const patchMutation = bizApi.user.patch.useMutation({
    throwOnError: false,
  })
  const createMutation = bizApi.user.create.useMutation({
    throwOnError: false,
  })
  const checkEmailMutation = bizApi.user.checkEmal.useMutation()
  const { Field, handleSubmit, Subscribe } = useForm<ExtendedBusinessUser>({
    defaultValues: {
      ...user,
    },
    validators: {
      onSubmitAsync: async ({ value: { isCurrentUser, ...user }, formApi }) => {
        try {
          if (user.username) {
            const { fieldMeta } = formApi.state
            const filteredUser = pickBy(
              user,
              (value, key) =>
                key === 'username' ||
                (key in fieldMeta ? fieldMeta[key].isDirty : false),
            )
            if (
              filteredUser.quotaBytes &&
              filteredUser.quotaBytes >= subscription.subscriptionQuotaBytes
            ) {
              filteredUser.quotaBytes = 0n
            }
            debug(
              'Patch user',
              filteredUser.quotaBytes,
              subscription.subscriptionQuotaBytes,
              filteredUser.quotaBytes
                ? filteredUser.quotaBytes >= subscription.subscriptionQuotaBytes
                : false,
            )

            await patchMutation.mutateAsync(filteredUser)
          } else {
            debug('Create user', user)
            await createMutation.mutateAsync(user)
          }
          navigate('/biz/users')
        } catch (err) {
          const error = handleUnknownError(err)
          debug(error, Object.keys(error))
          for (const { code } of error.errors) {
            debug('Error code', code)
            switch (code) {
              case ErrorType.EMAIL_IN_USE: {
                return {
                  fields: {
                    email: t`Email is already taken, pick another`,
                  },
                }
              }
              case ErrorType.NEW_QUOTA_IS_GREATER_THEN_BIZ_ACCOUNT:
              case ErrorType.NEW_QUOTA_IS_LESS_THEN_USED_USER_CAPACITY:
              case ErrorType.NEW_QUOTA_IS_GRATER_THEN_BIZ_AVAILABLE: {
                return {
                  fields: {
                    quotaBytes: t`Illegal allowed storage for user, it can't be less than the current usage or more than the business total allowed usage.`,
                  },
                }
              }
              default:
                break
            }
          }
          throw err
        }
        return null
      },
    },
  })

  return (
    <form
      className="form relative"
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <Subscribe selector={state => state.isSubmitting}>
        {isSubmitting =>
          isSubmitting && <div className="loading loading-absolute z-50"></div>
        }
      </Subscribe>
      <Field
        name="name"
        validatorAdapter={zodValidator()}
        validators={{
          onChange: z
            .string()
            .min(3, 'Name is required and must be at least 3 characters long.'),
        }}
      >
        {({ name, state, handleChange, handleBlur }) => (
          <ReactAriaTextField
            name={name}
            value={state.value}
            label={t`Full name`}
            placeholder={t`Full name`}
            onChange={handleChange}
            onBlur={handleBlur}
            isRequired
            isInvalid={state.meta.errors.length > 0}
            errorMessage={state.meta.errors}
          />
        )}
      </Field>
      <Field
        name="email"
        validatorAdapter={zodValidator()}
        validators={{
          onChangeAsync: async ({ value: email }) => {
            if (mode === 'create' || email !== user.email) {
              const { result } = await checkEmailMutation.mutateAsync({
                email,
                customerGroupCode,
              })
              switch (result.case) {
                case 'emailUsed':
                  return t`Email is already taken, pick another`
                default:
                  return undefined
              }
            }
          },
          onChange: z
            .string()
            .email('Email is required and must be a valid email address.'),
        }}
      >
        {({ name, state, handleChange, handleBlur }) => (
          <ReactAriaTextField
            name={name}
            value={state.value}
            label={t`Email`}
            placeholder={t`Email`}
            type="email"
            isRequired
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={state.meta.errors.length > 0}
            errorMessage={state.meta.errors}
          />
        )}
      </Field>

      <Field name="quotaBytes">
        {({ name, state, handleChange, handleBlur }) => {
          const isUnlimited =
            state.value === undefined ||
            state.value === 0n ||
            state.value >= subscription.subscriptionQuotaBytes
          return (
            <>
              <ReactAriaRangeSlider
                label={t`Storage`}
                description={t`Unlimited means this user can upload as much as he/she likes, and is only limited by the total storage capacity of the company account.`}
                minValue={Math.max(1, Number(user.storageBytes))}
                maxValue={Number(subscription.subscriptionQuotaBytes)}
                value={Number(
                  state.value || subscription.subscriptionQuotaBytes,
                )}
                hideSliderOutput
                errorMessage={<DisplayFieldErrors meta={state.meta} />}
                onChange={e =>
                  isNumber(e) &&
                  handleChange(
                    BigInt(
                      Math.min(e, Number(subscription.subscriptionQuotaBytes)),
                    ),
                  )
                }
              >
                <ReactAriaCheckbox
                  name={name}
                  onChange={unlimited => handleChange(unlimited ? 0n : 1n)}
                  onBlur={handleBlur}
                  isSelected={isUnlimited}
                  aria-describedby="unlimitedhint"
                >
                  <Trans>Unlimited storage</Trans>
                </ReactAriaCheckbox>
                <SliderOutput className="text-body-sm-sh">
                  {() =>
                    fileSize(
                      Number(
                        isUnlimited
                          ? subscription.subscriptionQuotaBytes
                          : state.value || 0,
                      ),
                    )
                  }
                </SliderOutput>
              </ReactAriaRangeSlider>
            </>
          )
        }}
      </Field>

      <Field name="admin">
        {({ name, state, handleChange, handleBlur }) => (
          <ReactAriaCheckboxField
            name={name}
            label={t`Administrator`}
            onChange={handleChange}
            onBlur={handleBlur}
            isSelected={state.value}
            isDisabled={isCurrentUser}
          >
            {isCurrentUser ? (
              <Trans>You can't disable yourself as administrator</Trans>
            ) : (
              <Trans>Make this user an administrator</Trans>
            )}
          </ReactAriaCheckboxField>
        )}
      </Field>
      {mode === 'edit' && (
        <div className="form-control">
          <div className="label">
            <Trans>Last upload</Trans>
          </div>
          <div className="text-base">
            {dayjs(Number(user.lastWriteMillis)).fromNow()}
          </div>
        </div>
      )}
      <div className="form-buttons">
        <Subscribe
          selector={state =>
            [state.isSubmitting, state.isDirty, state.isValid] as const
          }
        >
          {([isSubmitting, isDirty, isValid]) => (
            <button
              disabled={isSubmitting || !isDirty || !isValid}
              type="submit"
              className="btn btn-primary"
            >
              {mode === 'edit' ? (
                <Trans>Modify user</Trans>
              ) : (
                <Trans>Create user</Trans>
              )}
            </button>
          )}
        </Subscribe>
      </div>
    </form>
  )
}
