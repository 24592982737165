import { cn } from '@jotta/utils/css'
import type { ReactNode } from 'react'
import type { CheckboxProps } from 'react-aria-components'
import { Checkbox, Group, Text } from 'react-aria-components'
import type { ReactAriaValidationError } from '../ReactAriaTypes'
import { ReactAriaFieldError } from '../ReactAriaFieldError/ReactAriaFieldError'
import { ReactAriaLabel } from '../ReactAriaLabel/ReactAriaLabel'

interface ReactAriaCheckboxProps
  extends Omit<CheckboxProps, 'children' | 'className'> {
  children?: React.ReactNode
  className?: string
}
interface ReactAriaCheckboxFieldProps
  extends Omit<CheckboxProps, 'children' | 'className'> {
  children?: React.ReactNode
  className?: string
  label?: string
  placeholder?: string
  description?: ReactNode
  errorMessage?: ReactAriaValidationError
}

export function ReactAriaCheckbox({
  children,
  className,

  ...props
}: ReactAriaCheckboxProps) {
  return (
    <Checkbox className={cn('checkbox', className)} {...props}>
      {children}
    </Checkbox>
  )
}
export function ReactAriaCheckboxField({
  children,
  className,
  label,
  description,
  errorMessage,
  ...props
}: ReactAriaCheckboxFieldProps) {
  return (
    <Group className={cn('form-control', className)}>
      <ReactAriaLabel isRequired={props.isRequired}>{label}</ReactAriaLabel>
      <Checkbox className="checkbox" {...props}>
        {children}
      </Checkbox>
      {description && <Text slot="description">{description}</Text>}
      <ReactAriaFieldError error={errorMessage} />
    </Group>
  )
}
