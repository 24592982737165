import type {
  ChangeEventHandler,
  Dispatch,
  FocusEventHandler,
  FormEvent,
  ReactNode,
  RefObject,
  SetStateAction,
} from 'react'
import { createRef, useEffect, useMemo, useState } from 'react'
import Debug from 'debug'
import { t } from '@lingui/macro'
import { objectKeys } from '@jotta/types/TypeUtils'

const debug = Debug('form')

type ValidationError = { message: string; view: ReactNode } | string | undefined
export type ValidationResult = Promise<ValidationError> | ValidationError

type FieldTypeMap = {
  text: string
  number: number
  boolean: boolean
}
type FieldTypeKey = keyof FieldTypeMap

type FieldSpec<FIELD extends FieldSpec<FIELD>> = {
  type: FieldTypeKey
  placeholder?: string
  required?: boolean | string
  onChange?: (v: FieldTypeMap[FIELD['type']]) => void
  validate?: (v?: FieldTypeMap[FIELD['type']]) => ValidationResult
  autoComplete?: string
  focusOnMount?: boolean
}

type FieldsSpec<FIELDS extends FieldsSpec<FIELDS>> = Record<
  keyof FIELDS,
  FieldSpec<any>
>

export type FieldError = {
  message: string
  view: ReactNode
  displayInSummary: boolean
}

type FieldState = {
  ref: RefObject<HTMLInputElement>
  value?: any
  error?: FieldError
}

type FormData<FIELDS extends FieldsSpec<FIELDS>> = Partial<{
  [FIELD in keyof FIELDS]: FieldTypeMap[FIELDS[FIELD]['type']]
}>

type FormState<FIELDS extends FieldsSpec<FIELDS>> = Partial<{
  [FIELD in keyof FIELDS]: FieldState
}>

export type InputProps = {
  ref: RefObject<HTMLInputElement>
  name: string
  value: string
  checked: boolean
  placeholder: string | undefined
  onChange: ChangeEventHandler<HTMLInputElement>
  onBlur: FocusEventHandler<HTMLInputElement>
  autoComplete: string | undefined
  'aria-errormessage': string | undefined
  'aria-invalid': boolean
}

export type Field = {
  inputProps: InputProps
  spec: FieldSpec<any>
  formSpec: FormSpec<any>
  value: string
  set: (value: string) => void
  validate: () => void
  error?: FieldError
}

type Fields<FIELDS extends FieldsSpec<FIELDS>> = {
  [FIELD in keyof FIELDS]: Field
}

type ReactState<T extends FieldsSpec<T>> = [
  FormState<T>,
  Dispatch<SetStateAction<FormState<T>>>,
]

const valueConverters: Record<FieldTypeKey, (value: string) => any> = {
  boolean: Boolean,
  number: Number,
  text: value => value,
}

function format(validationError?: ValidationError) {
  if (!validationError) {
    return
  } else if (typeof validationError === 'string') {
    return validationError
  } else {
    return validationError?.message
  }
}

class FieldImpl<FIELDS extends FieldsSpec<FIELDS>, F extends keyof FIELDS> {
  public readonly valueSpec: FieldSpec<any>
  private readonly formState: ReactState<FIELDS>
  private readonly converter: (value: string) => any

  public readonly required: boolean | string
  public readonly name: F
  public readonly onChange: ChangeEventHandler<HTMLInputElement>
  public readonly onBlur: FocusEventHandler<HTMLInputElement>

  constructor(
    formState: ReactState<FIELDS>,
    fieldName: F,
    valueSpec: FieldSpec<any>,
  ) {
    this.formState = formState
    this.name = fieldName
    this.valueSpec = valueSpec
    this.required = valueSpec.required || false
    this.converter = valueConverters[valueSpec.type]

    this.onBlur = () => this.validate()
    this.onChange = ({ target: { type, value, checked } }) => {
      if (type === 'checkbox') {
        this.setValue(checked ? 'true' : undefined)
      } else {
        this.setValue(value)
      }
    }
  }

  public get state() {
    return this.formState[0][this.name] as FieldState
  }

  public get ref() {
    return this.state?.ref
  }

  public get value() {
    return this.state?.value
  }

  public get error(): FieldError | undefined {
    return this.state?.error
  }

  public setValue(value?: string) {
    const convertedValue = value ? this.converter(value) : undefined
    this.setState({
      error: undefined,
      value: convertedValue,
    })
    const specOnChange = this.valueSpec.onChange
    if (specOnChange) {
      setTimeout(() => specOnChange(convertedValue))
    }
  }

  public setState(state: Partial<FieldState>) {
    debug(
      `set ${String(this.name)}: ${state?.value} ${format(state?.error?.message)}`,
    )
    this.formState[1](prevState => ({
      ...prevState,
      [this.name]: {
        ...prevState[this.name],
        ...state,
      },
    }))
  }

  public async validate() {
    let validationError: FieldError | undefined = undefined

    const { validate, required } = this.valueSpec
    const value = this.value

    if (required && (value === undefined || value === '')) {
      const isString = typeof required === 'string'
      const message = isString ? required : t`This field is required`
      validationError = {
        message,
        view: message,
        displayInSummary: isString,
      }
    }

    const validationResult = validate && validate(value)
    const validationResultError =
      validationResult instanceof Promise
        ? await validationResult
        : validationResult
    if (validationResultError) {
      const intermediateError =
        typeof validationResultError === 'string'
          ? {
              message: validationResultError,
              view: validationResultError,
            }
          : validationResultError

      validationError = {
        ...intermediateError,
        displayInSummary: true,
      }
    }

    if (validationError) {
      this.setState({
        error: validationError,
      })
    }

    return validationError
  }
}

type FormStatus = 'idle' | 'validating' | 'invalid' | 'submitting'
export type Form<T extends FieldsSpec<T>> = Fields<T> & {
  onReset: () => void
  onSubmit: (e: FormEvent) => void
  isSubmitting: boolean
  status: FormStatus
  error?: Error
  fields: Fields<T>
}
type FormSpec<FIELDS extends FieldsSpec<FIELDS>> = {
  fields: FIELDS
  suppressIndicatorsForRequiredFields?: boolean
  submit: (data: FormData<FIELDS>) => Promise<any> | void
  defaultValues?: () => FormData<FIELDS & {}>
}
export function useForm<FIELDS extends FieldsSpec<FIELDS>>(
  formSpec: FormSpec<FIELDS>,
): Form<FIELDS> {
  const { submit, defaultValues, fields: fieldsSpec } = formSpec
  const [status, setStatus] = useState<FormStatus>('idle')
  const [error, setError] = useState<Error>()

  const state = useState<FormState<FIELDS>>(() => {
    const defaultState: FormState<FIELDS> = {}
    const defaults = defaultValues ? defaultValues() : defaultState
    return objectKeys(fieldsSpec).reduce((acc, fieldName) => {
      return {
        ...acc,
        [fieldName]: {
          ref: createRef(),
          value: defaults[fieldName],
        },
      }
    }, defaultState)
  })

  const fields = useMemo(() => {
    return Object.entries(fieldsSpec as FieldsSpec<any>).map(
      ([fieldName, valueSpec]) =>
        new FieldImpl<FIELDS, any>(state, fieldName, valueSpec),
    )
  }, [fieldsSpec])

  useEffect(() => {
    for (const field of fields) {
      if (field.valueSpec.focusOnMount && field.ref.current) {
        field.ref.current.focus()
      }
    }
  }, [fields])

  const inputs = useMemo(() => {
    return fields.reduce((acc, field): Fields<FIELDS> => {
      const { value, state, onBlur, onChange, valueSpec, error } = field
      const errorMessage = error?.message
      return {
        ...acc,
        [field.name]: {
          value,
          error,
          formSpec,
          spec: valueSpec,
          set: field.setValue.bind(field),
          validate: field.validate.bind(field),
          inputProps: {
            name: field.name,
            value: value ? String(value) : '',
            ref: state.ref,
            checked: Boolean(value),
            placeholder: valueSpec.placeholder,
            onChange,
            onBlur,
            autoComplete: valueSpec.autoComplete || 'off',
            'aria-errormessage': errorMessage,
            'aria-invalid': Boolean(errorMessage),
          },
        } satisfies Field,
      }
    }, {} as Fields<FIELDS>)
  }, [fields])

  return {
    ...inputs,
    status,
    error,
    fields: inputs,
    isSubmitting: status !== 'idle' && status !== 'invalid',
    onReset: () => fields.forEach(field => field.setValue(undefined)),
    onSubmit: async (e: FormEvent) => {
      let valid = true
      try {
        e.preventDefault()
        debug('onSubmit')

        setStatus('validating')
        for (const field of fields) {
          const errorMessage = await field.validate()
          if (valid && errorMessage) {
            field.ref?.current?.focus()
            valid = false
          }
        }
        debug('onSubmit valid:', valid)
        if (valid) {
          setStatus('submitting')
          const formData = fields.reduce((acc, field) => {
            return {
              ...acc,
              [field.name]: field.value,
            }
          }, {} as FormData<FIELDS>)
          await submit(formData)
        }
      } catch (error) {
        setError(error instanceof Error ? error : new Error(error?.toString()))
        throw error
      } finally {
        setStatus(valid ? 'idle' : 'invalid')
      }
    },
  }
}
