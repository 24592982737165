import type { TerminateBusinessResponse } from '@jotta/grpc-connect-openapi/esm/openapi/business/v1/business_pb'
import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { RadixModal } from '@jotta/ui/RadixModal'
import { t } from '@lingui/macro'
import { DialogTrigger } from '@radix-ui/react-dialog'
import { useState } from 'react'
import { bizApi } from '../BizApi'
import { useBizContext } from '../BizLayout'
import { BizTerminateAccountDialog } from './BizTerminateAccountDialog'

export function BizActionButtonTerminateAccount() {
  const { company } = useBizContext()
  const [open, setOpen] = useState(false)
  const [terminateResponse, setTerminateResponse] =
    useState<TerminateBusinessResponse>()
  const companyName = company.name

  const mutation = bizApi.company.terminate.useMutation({
    onSuccess: data => {
      setTerminateResponse(data)
    },
  })
  const title = `${t`Permanently delete`} ${companyName}?`
  return (
    <RadixModal
      open={open}
      onClose={setOpen}
      triggerElement={
        <DialogTrigger title={title} className="btn btn-destructive px-2">
          <PlainBrandIcon icon="SvgTrash" /> Terminate account
        </DialogTrigger>
      }
    >
      <BizTerminateAccountDialog
        title={title}
        companyName={company.name}
        terminate={mutation.mutate}
        setOpen={setOpen}
        terminateResponse={terminateResponse}
        isPending={mutation.isPending}
      />
    </RadixModal>
  )
}
