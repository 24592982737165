import { Trans } from '@lingui/macro'
import { useNavigate } from 'react-router-dom'
import { useBizUsers } from '../BizApi'

export function BizActionButtonCreateUser() {
  const { canCreateUser } = useBizUsers()
  const navigate = useNavigate()
  return (
    <button
      data-testid="BizCreateUser"
      onClick={() => navigate('./create')}
      disabled={!canCreateUser}
      className={'btn btn-lg btn-primary'}
    >
      <Trans>Create user</Trans>
    </button>
  )
}
