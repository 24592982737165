import { useLingui } from '@lingui/react'
import { useMemo } from 'react'
import { map, mapToObj, pipe, sort } from 'remeda'
import type { CountryInfo } from './generated/CountryList'
import {
  countries as countryList,
  countryCodeByCountry,
  countryCodes,
} from './generated/CountryList'
import type { Country } from '@jotta/grpc-connect-openapi/esm/openapi/country_pb'
import { getDetectedLocale } from './locale'

export type LocalizedCountry = CountryInfo & {
  name: string
}

/**
 * Get localized Country names
 * @returns Names indexed by country code
 */
export function useLocalizedCountryList() {
  const { i18n } = useLingui()
  const detectedCountry = getDetectedLocale()
  const { locale } = i18n
  const memo = useMemo(() => {
    const countries = pipe(
      Object.values(countryList),
      map<readonly CountryInfo[], LocalizedCountry>(o => ({
        ...o,
        name: i18n._(o.key),
      })),
      sort((a, b) => a.name.localeCompare(b.name, locale)),
    )
    const countryInfoByCode = pipe(
      countries,
      mapToObj(o => [o.code, o]),
    )
    const countryByCallingCode = pipe(
      countries.filter(o => o.callingCode),
      mapToObj(o => [o.callingCode!, o]),
    )
    countryByCallingCode['47'] = countryInfoByCode['no']
    countryByCallingCode['1'] = countryInfoByCode['us']
    return {
      countries: countries,
      countryInfoByCode,
      countryByCallingCode,
      countryByCountry: (c?: Country): CountryInfo | undefined => {
        const cc = c ? countryCodeByCountry.get(c) : undefined
        return cc && countryInfoByCode[cc]
      },
    }
  }, [locale, i18n])
  return {
    ...memo,
    detectedCountry: detectedCountry === 'nb' ? 'no' : detectedCountry,
    countryCodes,
    locale,
  }
}
