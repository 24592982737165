import { Trans } from '@lingui/macro'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import dayjs from 'dayjs'
import Debug from 'debug'
import filesize from 'filesize'
import { Link } from 'react-router-dom'

import { cn } from '@jotta/utils/css'
import { type ExtendedBusinessUser } from '../BizApi'
import { BizActionButtonDeleteUser } from './BizActionButtonDeleteUser'
import { BizActionButtonEditUser } from './BizActionButtonEditUser'

const debug = Debug('jotta:biz:UserTable')

const columnHelper = createColumnHelper<ExtendedBusinessUser>()
const accessors = {
  name: columnHelper.accessor('name', {
    header: () => <Trans>Full name</Trans>,
  }),
  nameLink: columnHelper.accessor('name', {
    sortingFn: 'alphanumeric',
    header: () => <Trans>Full name</Trans>,
    cell: props => (
      <Link
        to={`/biz/users/${props.row.original.username}`}
        className="link"
        aria-label="Modify user"
      >
        {props.row.original.name || props.row.original.email}
        {props.row.original.isCurrentUser && (
          <span className="ml-1 text-gray-400">
            (<Trans>You</Trans>)
          </span>
        )}
      </Link>
    ),
  }),
  email: columnHelper.accessor('email', {
    header: () => <Trans>Email</Trans>,
  }),
  admin: columnHelper.accessor('admin', {
    header: () => <Trans>Role</Trans>,
    cell: props => (props.getValue() ? 'Admin' : 'User'),
  }),
  storageBytes: columnHelper.accessor('storageBytes', {
    sortingFn: (a, b) =>
      Number(a.original.storageBytes) - Number(b.original.storageBytes),
    header: () => (
      <div className="whitespace-nowrap text-right">
        <Trans>Storage used</Trans>
      </div>
    ),
    cell: props => (
      <div className="whitespace-nowrap text-right">
        {filesize(Number(props.getValue()))}
      </div>
    ),
  }),
  quotaBytes: columnHelper.accessor('quotaBytes', {
    header: () => (
      <div className="whitespace-nowrap text-right">
        <Trans>Total</Trans>
      </div>
    ),
    cell: props => {
      const quotaBytes = props.getValue()
      return (
        <div className="whitespace-nowrap text-right">
          {quotaBytes === undefined ? '∞' : filesize(Number(quotaBytes))}
        </div>
      )
    },
  }),
  lastWriteMillis: columnHelper.accessor('lastWriteMillis', {
    sortingFn: (a, b) =>
      Number(a.original.lastWriteMillis) - Number(b.original.lastWriteMillis),
    header: () => <Trans>Last upload</Trans>,
    cell: props => (
      <span className="whitespace-nowrap">
        {dayjs(Number(props.getValue())).fromNow()}
      </span>
    ),
  }),
  actions: columnHelper.display({
    header: () => <Trans>Actions</Trans>,
    id: 'actions',
    cell: props => {
      const user = props.row.original
      return (
        <div className="flex gap-1">
          <BizActionButtonEditUser user={user} />
          <BizActionButtonDeleteUser user={user} />
        </div>
      )
    },
  }),
}
const columns = {
  full: [
    accessors.nameLink,
    accessors.email,
    accessors.admin,
    accessors.storageBytes,
    accessors.quotaBytes,
    accessors.lastWriteMillis,
    accessors.actions,
  ],
  storage: [accessors.nameLink, accessors.storageBytes, accessors.quotaBytes],
  upload: [accessors.nameLink, accessors.lastWriteMillis],
}

export function BizUserTable({
  users,
  view = 'full',
  compact,
  sortBy = 'name',
  sortDescending = false,
}: {
  users: ExtendedBusinessUser[]
  view?: keyof typeof columns
  compact?: boolean
  sortBy?: 'name' | 'storageBytes' | 'lastWriteMillis'
  sortDescending?: boolean
}) {
  const table = useReactTable({
    state: {
      sorting:
        sortBy === 'storageBytes'
          ? [
              {
                id: 'quotaBytes',
                desc: !sortDescending,
              },
              {
                id: sortBy,
                desc: sortDescending,
              },
            ]
          : [
              {
                id: sortBy,
                desc: sortDescending,
              },
            ],
    },
    columns: columns[view],
    data: users,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <table
      className={cn('table', {
        'table-compact': compact,
      })}
    >
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr
            key={headerGroup.id}
            className="sticky top-[60px] z-10 lg:top-[120px]"
          >
            {headerGroup.headers.map(header => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr key={row.id} className="relative">
            {row.getVisibleCells().map(cell => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
