import { createConnectQueryKey, useMutation } from '@connectrpc/connect-query'
import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import {
  getMergeCandidates,
  getPeople,
  getPerson,
  getPhotosWithPerson,
  mergePeople,
} from '@jotta/grpc-connect-openapi/peopleQuery'
import { queryClient } from '@jotta/query'
import { handleUnknownError } from '@jotta/types/AppError'
import { Button } from '@jotta/uinext/Button'
import { Trans } from '@lingui/macro'
import Debug from 'debug'
import type { Dispatch, SetStateAction } from 'react'
import type { Selection } from 'react-aria-components'
import { Dialog, Heading, Modal, ModalOverlay } from 'react-aria-components'
import { PersonImage } from '../PersonList/PersonImage'
import type { Person } from '@jotta/grpc-connect-openapi/esm/openapi/people/v1/people.v1_pb'
import { useAlertListStore } from '@jotta/ui/AlertStore'

const debug = Debug('jotta:photos:ConfirmMergePeopleDialog')

export function ConfirmMergePeopleDialog({
  name,
  person,
  people,
  selection,
  setSelection,
  open,
  onOpenChange,
}: {
  name?: string
  selection: Selection
  open: boolean
  person: Person
  people: Person[]
  setSelection: Dispatch<SetStateAction<Selection>>
  onOpenChange: Dispatch<SetStateAction<boolean>>
}) {
  const { alert } = useAlertListStore()
  const id = person.id

  const selected =
    selection === 'all' ? people : people.filter(p => selection.has(p.id))

  const mergePeopleMutation = useMutation(mergePeople, {
    onSuccess: () => {
      debug('success')
      setSelection(new Set())
      onOpenChange(false)
      alert.showAlert({
        severity: 'success',
        message: (
          <Trans>
            {selected.length} images successfully marked as the same person
          </Trans>
        ),
      })
    },

    onSettled: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(getPerson, {
            id,
          }),
        }),
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(getPhotosWithPerson, {
            personId: id,
          }),
        }),
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(getMergeCandidates, {
            personId: id,
          }),
        }),
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(getPeople),
        }),
      ]),
  })
  const onConfirmMerge = () => {
    const ids = selected.map(p => p.id)
    debug('Merging ids %s', ids.join())
    mergePeopleMutation.mutate({
      targetPersonId: id,
      personId: ids,
    })
  }
  return (
    <ModalOverlay
      isOpen={open}
      onOpenChange={onOpenChange}
      className="fixed inset-0 z-50 bg-white/80"
    >
      <Modal className="flex h-dvh items-center justify-center p-8">
        <Dialog
          role="alertdialog"
          className="relative flex max-h-full flex-col rounded-lg bg-white shadow-dialog"
        >
          {({ close }) => (
            <>
              <div className="relative flex flex-col gap-4 p-8 pb-0">
                <Heading slot="title" className="text-xl">
                  {name ? (
                    <Trans>
                      Are these faces of{' '}
                      <span className="font-bold">{name}</span>?
                    </Trans>
                  ) : (
                    <Trans>Are these faces of the same person?</Trans>
                  )}
                </Heading>
                <p>
                  <Trans>This action can't be undone.</Trans>
                </p>
                {mergePeopleMutation.isError && (
                  <div className="text-danger">
                    {handleUnknownError(mergePeopleMutation.error).message}
                  </div>
                )}
                <div className="grid grid-cols-2 gap-4 self-start">
                  <Button
                    variant={'secondary'}
                    loading={mergePeopleMutation.isPending}
                    onClick={close}
                    icon={<PlainBrandIcon icon="SvgClose" />}
                  >
                    <Trans>Cancel</Trans>
                  </Button>
                  <Button
                    loading={mergePeopleMutation.isPending}
                    variant={'primary'}
                    onClick={onConfirmMerge}
                    icon={<PlainBrandIcon icon="SvgCheckmark" />}
                  >
                    <Trans>Yes</Trans>
                  </Button>
                </div>
              </div>
              <div className="grid w-full snap-start grid-cols-[repeat(auto-fill,_minmax(120px,_1fr))] gap-4 overflow-y-auto p-8">
                <PersonImage
                  person={person}
                  className="aspect-square size-full rounded-lg object-cover object-center"
                />
                {selected.map(p => (
                  <div
                    key={p.id}
                    className="group relative size-full snap-start rounded-md"
                  >
                    <PersonImage
                      person={p}
                      className="aspect-square size-full rounded-lg object-cover object-center"
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  )
}
