import { useAuthenticated } from '@jotta/auth-client/useAuthStatus'
import { getConfig as getConfigDescriptor } from '@jotta/grpc-connect-openapi/esm/openapi/config/v2/config.v2-ConfigService_connectquery'
import { FeatureToggle } from '@jotta/types/FeatureToggle'
import { env } from '@jotta/utils/env'
import { useMemo } from 'react'
import { emptyConfigResponse } from './emptyConfigResponse'
import { getCurrentLanguage } from '@jotta/i18n'
import { createConnectQuery } from '../createConnectQuery'
import { authTransport, publicTransport } from '../transport'
import type { GetConfigRequest } from '@jotta/grpc-connect-openapi/config'

const useConfigQuery = createConnectQuery(getConfigDescriptor)

function makeRequest(
  customerGroupOverride?: string,
): Partial<GetConfigRequest> {
  return {
    hostname: env.isLocal ? 'jottacloud.com' : window.location.hostname,
    featureToggleIds: Object.values(FeatureToggle),
    preferredLanguage: getCurrentLanguage(),
    customerGroupCode: customerGroupOverride,
  }
}
export async function getConfig() {
  return (
    (await useConfigQuery.fetch(makeRequest(), {
      transport: authTransport,
    })) || emptyConfigResponse
  )
}

export function useGetConfig(customerGroupOverride?: string) {
  const authenticated = useAuthenticated()
  const request = makeRequest(customerGroupOverride)

  const q = useConfigQuery({
    variables: request,
    refetchOnMount: false,
    enabled: authenticated !== 'UNKNOWN',
    transport: authenticated === 'TRUE' ? authTransport : publicTransport,
    queryKeyHashFn: queryKey => JSON.stringify([...queryKey, authenticated]),
  })

  return useMemo(
    () => ({
      ...q,
      data: q.data || emptyConfigResponse,
    }),
    [q],
  )
}
